import { createTypes, createReducer } from '../duckCreator';

export const Types = createTypes(
  ['CLEAR', 'SET_MUNICIPIO', 'SET_UNIDADE', 'SET_PACIENTE'],
  'comments'
);

const initialState = { idEstado: 42 };

const actionReducers = {
  [Types.CLEAR_LOCALIZAR]: () => {
    return initialState;
  },
  [Types.SET_ESTADO]: (state, idEstado) => {
    return { idEstado };
  },
  [Types.SET_MUNICIPIO]: (state, idMunicipio) => {
    return {
      ...state,
      idMunicipio,
      idUnidade: undefined,
      idPaciente: undefined,
    };
  },
  [Types.SET_UNIDADE]: (state, idUnidade) => {
    return { ...state, idUnidade, idPaciente: undefined };
  },
  [Types.SET_PACIENTE]: (state, idPaciente) => {
    return { ...state, idPaciente };
  },
};

// Action Creators
export function setEstado(idEstado) {
  return { type: Types.SET_ESTADO, payload: idEstado };
}

export function setMunicipio(idMunicipio) {
  return { type: Types.SET_MUNICIPIO, payload: idMunicipio };
}

export function setUnidade(idUnidade) {
  return { type: Types.SET_UNIDADE, payload: idUnidade };
}

export function setPaciente(idPaciente) {
  return { type: Types.SET_PACIENTE, payload: idPaciente };
}

export function clearLocalizar() {
  return { type: Types.CLEAR_LOCALIZAR };
}

export default createReducer(initialState, actionReducers);
