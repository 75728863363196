import React from 'react';
import { StyledContent } from 'components/Layout';
import Pacientes from 'pages/Pacientes';
import TratamentosEtapa from 'pages/TratamentosEtapa';
import Tratamentos from 'pages/Tratamentos';
import CadastroUnidadeSaude from 'pages/CadastroUnidadeSaude';
import CadastroMicroArea from 'pages/CadastroMicroArea';
import Usuarios from 'pages/Usuarios';
import Dashboard from 'pages/Dashboard';

const pageComponent = {
  dashboard: Dashboard,
  pacientes: Pacientes,
  'etapas-tratamento': TratamentosEtapa,
  tratamentos: Tratamentos,
  'unidade-saude': CadastroUnidadeSaude,
  microarea: CadastroMicroArea,
  usuarios: Usuarios,
};

function Page({ currentPage }) {
  const Component = pageComponent[currentPage];
  if (!Component) return null;
  return (
    <StyledContent>
      <Component />
    </StyledContent>
  );
}

export default Page;
