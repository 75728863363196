import React, { useCallback } from 'react';
import { Item } from 'components/Dashboard/List';
import { useDispatch } from 'react-redux';
import { setUnidade } from 'store/ducks/localizar';

export function HospitalItem({ hospital = {} }) {
  const dispatch = useDispatch();
  const handleLocalizarHospital = useCallback(() => {
    dispatch(setUnidade(hospital.id));
  }, [dispatch, hospital.id]);
  return (
    <>
      <Item
        item={{ ...hospital, total_estagio: hospital.total_pacientes }}
        onLocalizar={handleLocalizarHospital}
      />
      <div className="extra">
        <strong>{hospital.leitos}</strong>
        <span>/{hospital.leitos} leitos disponíveis</span>
        <br />
        <strong>{hospital.respiradores}</strong>
        <span>/{hospital.respiradores} respiradores disponíveis</span>
      </div>
    </>
  );
}
