import { Button, Col, Icon, Input, Row, Typography } from 'antd';
import { BigModal } from 'components/BigModal';
import { ButtonTransparent } from 'components/Button';
import { ItemLabel } from 'components/Form';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'store/ducks/modal';

const { Title } = Typography;

export default function ModalAssinatura({ visible }) {
  const dispatch = useDispatch();

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  return (
    <BigModal
      width={455}
      visible={visible}
      // onCancel={handleCloseModal}
      footer={null}>
      <Title level={4}>Assinar Notificação Digitalmente</Title>
      <Row type="flex" justify="center">
        <Col span={24}>
          <ItemLabel label="CPF">
            <Input size="large" style={{ width: '100%' }} />
          </ItemLabel>
        </Col>
        <Col span={24}>
          <ItemLabel label="Senha">
            <Input size="large" style={{ width: '100%' }} />
          </ItemLabel>
        </Col>
      </Row>
      <Row type="flex" justify="center" gutter={[0, 16]}>
        <Col span={24}>
          <Button
            size="large"
            type="primary"
            style={{ width: '100%', fontSize: 14 }}>
            <strong>Assinar</strong>
            <Icon type="right" />
          </Button>
        </Col>
        <Col span={24} style={{ textAlign: 'center' }}>
          <ButtonTransparent size="small" onClick={handleCloseModal}>
            Cancelar
          </ButtonTransparent>
        </Col>
      </Row>
    </BigModal>
  );
}
