import styled from 'styled-components';

export default styled.div.attrs(props => ({
  className: props.active ? 'active' : '',
}))`
  display: ${({ type }) => type || 'block'};
  width: 100%;
  color: #000;
  cursor: pointer;
  font-size: 12px;
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
  padding: 12px 15px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 3px 3px 10px 0 rgba(33, 89, 178, 0.16);
  transition: all 300ms ease-in-out;

  .ant-list-item-meta-description,
  .ant-list-item-meta-title {
    margin: 0;
    transition: all 300ms ease-in-out;
  }

  .ant-list-item-meta-title {
    > span,
    > strong {
      transition: all 300ms ease-in-out;
    }
  }

  /* Botao de Localizar dentro do card */
  .ant-btn-localizar {
    margin-top: -5px;
    background-color: rgba(255, 255, 255, 0.4);

    path {
      stroke: #0062ff;
      transition: all 200ms;
    }
  }

  &:hover,
  &:active,
  &:focus,
  &.active {
    color: #fff;
    background-color: #0062ff;

    .ant-list-item-meta-title {
      color: #fff;

      > span,
      > strong {
        color: #fff;
      }
    }
    .ant-list-item-meta-description {
      color: rgba(255, 255, 255, 0.65);
    }

    .ant-btn-localizar {
      path {
        stroke: #fff;
      }
    }
  }
`;
