import styled from 'styled-components';
import { Layout } from 'antd';

const { Sider } = Layout;

const StyledSider = styled(Sider)`
  overflow: hidden;
  box-shadow: 30px 20px 60px 0 rgba(0, 0, 0, 0.03);

  &.ant-layout-sider-collapsed {
    .ant-menu-item {
      color: #92929d;
      .anticon {
        margin-right: 30px;
      }
    }
  }
`;

export default StyledSider;
