import styled from 'styled-components';

const Logo = styled.div`
  display: flex;
  width: 110px;
  height: 35px;

  svg {
    width: auto;
    height: 100%;
  }

  .rectangle {
    height: 28px;
    width: 28px;
    border: 4px solid #0073ff;
    border-radius: 10px;
    margin: 0 10px 0 5px;
  }

  .text-logo {
    height: 28px;
    width: 65px;
    color: #44444f;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
  }
`;

export default Logo;
