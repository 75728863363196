import { Button, Col, Form, Row, Spin } from 'antd';
import {
  DatePicker,
  Input,
  MaskInput,
  Select,
  SelectVirtualized,
} from 'components/Form';
import { ScrolledContent } from 'components/Layout/content';
import SelectDoenca from 'components/SelectDoenca';
import { EscolaridadeEnum } from 'enums';
import OcupacaoCBOEnum from 'enums/OcupacaoCBOEnum';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { change, Field, reduxForm } from 'redux-form';
import moment from 'moment';
import { useMatch } from '@reach/router';
import Endereco from './Endereco';
import { FORM } from './FORM';
import Gestante from './Gestante';
import Mapa from './Mapa';
import NomeMae from './NomeMae';
import NomePai from './NomePai';
import Profile from './Profile';

function FormPaciente({ handleSubmit: onSubmit }) {
  const dispatch = useDispatch();

  const optionEscolaridade = useMemo(
    () =>
      Object.keys(EscolaridadeEnum).map(key => (
        <Select.Option key={key} value={+key} title={EscolaridadeEnum[key]}>
          {EscolaridadeEnum[key]}
        </Select.Option>
      )),
    []
  );

  const optionsOcupacaoCBO = useMemo(
    () =>
      Object.keys(OcupacaoCBOEnum).map(key => ({
        id: +key,
        value: OcupacaoCBOEnum[key],
      })),
    []
  );

  return (
    <Form onSubmit={onSubmit}>
      <ScrolledContent>
        <Row gutter={16}>
          <Col span={12}>
            <Field
              name="cpf"
              label="CPF"
              component={MaskInput}
              mask="000.000.000-00"
              className="ant-input ant-input-lg"
            />
          </Col>
          <Col span={12}>
            <Field name="rg" label="RG" component={Input} />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Field
              name="n_cartao_sus"
              label="Número Cartão SUS"
              component={Input}
            />
          </Col>
          <Col span={12}>
            <Field name="sexo" label="Sexo" required component={Select}>
              <Select.Option value="M">Masculino</Select.Option>
              <Select.Option value="F">Feminino</Select.Option>
              <Select.Option value="I">Ignorado</Select.Option>
            </Field>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Field name="nome" label="Nome" required component={Input} />
          </Col>
          <Col span={12}>
            <Field
              name="sobrenome"
              label="Sobrenome"
              required
              component={Input}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Field
              name="data_nascimento"
              label="Data de nascimento"
              style={{ width: '100%' }}
              component={DatePicker}
              onFocus={e => e.preventDefault()}
              onBlur={e => e.preventDefault()}
            />
          </Col>
          <Col span={12}>
            <Field
              name="nacionalidade"
              label="Nacionalidade"
              component={Select}>
              <Select.Option value={1}>Brasileira</Select.Option>
              <Select.Option value={2}>Naturalizado</Select.Option>
              <Select.Option value={3}>Estrangeiro</Select.Option>
            </Field>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Field name="estado_civil" label="Estado Civil" component={Select}>
              <Select.Option value={1}>Solteiro(a)</Select.Option>
              <Select.Option value={2}>Casado(a)</Select.Option>
              <Select.Option value={3}>Divorciado(a)</Select.Option>
              <Select.Option value={4}>Viúvo(a)</Select.Option>
            </Field>
          </Col>
          <Col span={12}>
            <Field name="raca_cor" label="Raça/Cor" component={Select}>
              <Select.Option value={1}>Branca</Select.Option>
              <Select.Option value={2}>Preta</Select.Option>
              <Select.Option value={3}>Amarela</Select.Option>
              <Select.Option value={4}>Parda</Select.Option>
              <Select.Option value={5}>Indígena</Select.Option>
              <Select.Option value={6}>Ignorado</Select.Option>
            </Field>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <NomeMae />
          </Col>
          <Col span={12}>
            <NomePai />
          </Col>
        </Row>
        <Endereco />
        <Row gutter={16}>
          <Col span={24}>
            <Field name="email" label="Email" component={Input} />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Field
              name="celular"
              label="Celular"
              mask="00 00000-0000"
              className="ant-input ant-input-lg"
              component={MaskInput}
            />
          </Col>
          <Col span={12}>
            <Field
              name="telefone"
              label="Telefone"
              mask="00 0000-0000"
              className="ant-input ant-input-lg"
              component={MaskInput}
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Field name="escolaridade" label="Escolaridade" component={Select}>
              {optionEscolaridade}
            </Field>
          </Col>
          <Col span={12}>
            <Field
              name="profissao"
              label="Profissão"
              component={SelectVirtualized}
              onSelect={id => dispatch(change(FORM, 'profissao', id))}
              options={optionsOcupacaoCBO}
            />
          </Col>
        </Row>
        <div className="yellow-bordered-box">
          <Gestante />
        </div>
        <div style={{ marginTop: 10 }}>
          <span>Doenças</span>
          <Field
            name="doencas"
            component={({ input, meta, label, ...rest }) => {
              input.selectedTags = input.value === '' ? [] : input.value;
              return <SelectDoenca {...input} {...rest} />;
            }}
          />
        </div>
        <Col>
          <Button htmlType="submit" type="primary" size="large">
            Salvar e continuar
          </Button>
        </Col>
      </ScrolledContent>
      <Profile />
    </Form>
  );
}

const FormCadastroRedux = reduxForm({ form: FORM })(FormPaciente);

export default function FormCadastro({ paciente, onSave }) {
  const match = useMatch('/pacientes/:pacienteId/editar');

  const handleSubmit = useCallback(
    values => {
      const formData = new FormData();
      const { foto, id, gestante, alergias, doencas, ...fields } = values;

      if (foto) {
        formData.append('foto', foto?.file);
      }

      Object.keys(fields).forEach(key => {
        if (fields[key] !== undefined) {
          formData.append(key, JSON.stringify(fields[key]));
        }
      });

      if (alergias) {
        formData.append('alergias', JSON.stringify(alergias.map(a => a.id)));
      }

      if (doencas) {
        formData.append('doencas', JSON.stringify(doencas.map(a => a.id)));
      }

      onSave(id, formData);
    },
    [onSave]
  );

  const showLoading = useMemo(() => match && !paciente.id, [
    match,
    paciente.id,
  ]);

  return (
    <Row type="flex">
      <Col span={12}>
        {showLoading ? (
          <div style={{ height: '83vh' }}>
            <Spin />
          </div>
        ) : (
          <FormCadastroRedux
            initialValues={{
              ...paciente,
              data_nascimento: moment(paciente.data_nascimento),
            }}
            onSubmit={handleSubmit}
          />
        )}
      </Col>
      <Col span={12}>
        <Mapa />
      </Col>
    </Row>
  );
}
