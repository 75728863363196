import { Col, Form, Modal, notification, Select } from 'antd';
import { CircleDot } from 'components/CircleDot';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addEstagioClinico } from 'store/ducks/etapa';

export function EstagioClinico({ tratamento }) {
  const estagioClinico = tratamento.estagio_clinico;
  const dispatch = useDispatch();
  // const finalizado = useSelector(state => state?.etapa?.tratamento?.finalizado);
  const estagios = useSelector(
    state => state.paciente.doencas[tratamento.id_doenca].estagios
  );

  const [showModalEstagios, setShowModalEstagios] = useState(false);
  const [idEstagioClinico, setIdEstagioClinico] = useState();

  const clearModal = useCallback(() => {
    setShowModalEstagios(false);
    setIdEstagioClinico(undefined);
  }, []);

  const handleSaveEstagioClinico = useCallback(() => {
    dispatch(addEstagioClinico(idEstagioClinico))
      .then(() => {
        clearModal();
      })
      .catch(e => {
        if (e?.response?.data?.possiveis) {
          if (e?.response?.data?.possiveis[0]) {
            notification.warn({
              message: 'Não é possível ir para este Estágio',
              description: (
                <div>
                  O estágio atual deste passiente só permite ir para o(s)
                  estágio(s) a seguir:
                  <br />
                  {e.response.data.possiveis.map(f => (
                    <>
                      &nbsp;{f}
                      <br />
                    </>
                  ))}
                </div>
              ),
            });
          } else {
            notification.warn({
              message: 'Não é possível ir para este Estágio',
              description: (
                <div>
                  O estágio atual deste passiente não permite alterar para
                  nenhum novo estágio
                </div>
              ),
            });
          }
        } else if (e?.response?.data?.faltantes) {
          notification.warn({
            message: 'Estágios anteriores faltantes',
            description: (
              <div>
                Antes de atríbuir este estágio clínico, este paciente deve
                passar pelo(s) estágio(s) a seguir:
                <br />
                {e.response.data.faltantes.map(f => (
                  <>
                    &nbsp;{f}
                    <br />
                  </>
                ))}
              </div>
            ),
          });
        } else {
          notification.warn({
            message: 'Atenção',
            description:
              e.response.data.description ||
              'Não foi possível realizar este cadastro',
          });
        }
      });
  }, [clearModal, dispatch, idEstagioClinico]);

  return (
    <>
      {showModalEstagios && (
        <Modal
          visible
          title="Adicionar Estágio Clínico"
          onOk={handleSaveEstagioClinico}
          onCancel={clearModal}
          okText="Adicionar">
          <Form.Item label="Estágio Clínico">
            <Select
              style={{ width: '100%' }}
              value={idEstagioClinico ?? estagioClinico.id}
              onSelect={setIdEstagioClinico}>
              {estagios.map(e => (
                <Select.Option value={e.id} key={e.id}>
                  {e.nome}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Modal>
      )}

      {estagioClinico.id && (
        <Col>
          <span style={{ display: 'inline-block', paddingTop: 8 }}>
            <CircleDot color={estagioClinico.cor} />
            {estagioClinico.nome}
          </span>
        </Col>
      )}
      {/* <Col>
        <Button
          size="large"
          type="primary"
          ghost
          onClick={() => setShowModalEstagios(true)}
          disabled={finalizado}>
          <Icon type="plus" />
          <strong>Adicionar Estágio Clínico</strong>
        </Button>
      </Col> */}
    </>
  );
}
