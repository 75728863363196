import {
  Icon,
  Typography,
  Menu,
  Dropdown,
  Col,
  Button,
  Row,
  notification,
} from 'antd';
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { TableList, TableActions } from 'components/Table';
import { findAll, update, save, remove } from 'services/etapaModeloService';
import { formatDate } from 'utils/Functions';
import TipoEtapaEnum from 'enums/TipoEtapaEnum';
import moment from 'moment';
import ModalEdit from './ModalEdit';

const { Title } = Typography;
const { Column } = TableList;

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      'selectedRows: ',
      selectedRows
    );
  },
};

function TratamentosEtapa() {
  const [loadingTable, setLoadingTable] = useState(true);
  const [modalData, setModalData] = useState(undefined);
  const [data, setData] = useState([]);
  const [sortedInfo, setSortedInfo] = useState({
    columnKey: 'nome',
    order: 'ascend',
    menuItem: 'Nome A-Z',
  });
  const [filteredInfo, setFilteredInfo] = useState({
    filterKey: 'Todos',
    menuItem: 'Todos tipos de etapas',
  });

  const handleModalCancel = useCallback(() => {
    setModalData(undefined);
  }, []);

  const listEtapa = useCallback(() => {
    setLoadingTable(true);
    setModalData(undefined);
    findAll()
      .then(response => {
        setData(response.data);
        setLoadingTable(false);
      })
      .catch(() => {
        setData([]);
        setLoadingTable(false);
      });
  }, []);

  const handleSave = useCallback(
    values => {
      setLoadingTable(true);
      save(values)
        .then(resp => {
          notification.success({
            message: 'Sucesso!',
            description: 'Nova etapa criada.',
          });
          listEtapa(resp);
        })
        .catch(({ response }) => {
          notification.error({
            message: 'Não foi posível criar esta etapa!',
            description: response.data.message,
          });
          setLoadingTable(false);
        });
    },
    [listEtapa]
  );

  const handleDelete = useCallback(
    id => {
      setLoadingTable(true);
      remove(id)
        .then(resp => {
          notification.success({
            message: 'Sucesso!',
            description: 'Etapa removida.',
          });
          listEtapa(resp);
        })
        .catch(({ response }) => {
          notification.error({
            message: 'Não foi posível remover esta etapa!',
            description: response.data.message,
          });
          setLoadingTable(false);
        });
    },
    [listEtapa]
  );

  const handleUpdate = useCallback(
    (id, values, setLoadingButton) => {
      setLoadingTable(true);
      update(id, values)
        .then(resp => {
          notification.success({
            message: 'Sucesso!',
            description: 'Etapa atualizada.',
          });
          listEtapa(resp);
        })
        .catch(({ response }) => {
          notification.error({
            message: 'Não foi posível alterar esta etapa!',
            description: response.data.message,
          });
          setLoadingButton && setLoadingButton(false);
          setLoadingTable(false);
        });
    },
    [listEtapa]
  );

  const menuRowDropdown = useCallback(
    record => {
      const menu = (
        <Menu
          onClick={({ key }) => {
            if (key === 'editar') {
              setModalData(record);
            } else if (key === 'excluir') {
              handleDelete(record.id);
            } else {
              const body = {
                ...record,
                ativa: key === 'ativar',
              };
              delete body.id;
              delete body.created_at;
              delete body.updated_at;
              body.alergias = body.alergias.map(a => a.id);
              handleUpdate(record.id, body);
            }
          }}>
          <Menu.Item key="editar">Editar</Menu.Item>
          <Menu.Item key="excluir">Excluir</Menu.Item>
          {record.ativa && <Menu.Item key="desativar">Desativar</Menu.Item>}
          {!record.ativa && <Menu.Item key="ativar">Ativar</Menu.Item>}
        </Menu>
      );
      return menu;
    },
    [handleDelete, handleUpdate]
  );

  useEffect(() => {
    listEtapa();
  }, [listEtapa]);

  const menuFilters = useMemo(
    () => (
      <Menu
        onClick={menu =>
          setFilteredInfo({
            filterKey: menu.key,
            menuItem: menu.item.props.children,
          })
        }>
        <Menu.Item key="Todos">Todos tipos de etapas</Menu.Item>
        <Menu.Item key={2}>Exame</Menu.Item>
        <Menu.Item key={1}>Medicamento</Menu.Item>
        <Menu.Item key={4}>Isolamento</Menu.Item>
      </Menu>
    ),
    []
  );

  const menuSorters = useMemo(
    () => (
      <Menu
        onClick={menu => {
          const keys = menu.key.split(',');
          setSortedInfo({
            columnKey: keys[0],
            order: keys[1],
            menuItem: menu.item.props.children,
          });
        }}>
        <Menu.Item key="nome,ascend">Nome A-Z</Menu.Item>
        <Menu.Item key="nome,descend">Nome Z-A</Menu.Item>
        <Menu.Item key="tipo,ascend">Tipo de Etapa A-Z</Menu.Item>
        <Menu.Item key="tipo,descend">Tipo de Etapa Z-A</Menu.Item>
      </Menu>
    ),
    []
  );

  return (
    <>
      {modalData && (
        <ModalEdit
          dataSource={modalData}
          handleSave={handleSave}
          handleUpdate={handleUpdate}
          handleCancel={handleModalCancel}
        />
      )}

      <Row type="flex" justify="space-between">
        <Col span={12}>
          <Title level={3}>Etapas de tratamentos</Title>
        </Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          <Button
            type="primary"
            icon="plus"
            size="large"
            onClick={() => setModalData({})}>
            Nova Etapa
          </Button>
        </Col>
      </Row>

      <TableActions>
        <Dropdown
          className="box-filters"
          overlay={menuFilters}
          placement="bottomRight"
          trigger={['click']}
          ta>
          <div>
            <span>Filtrar Por: </span>
            <strong>{filteredInfo.menuItem}</strong>
            <Icon type="caret-down" />
          </div>
        </Dropdown>

        <Dropdown
          className="box-sorters"
          overlay={menuSorters}
          placement="bottomRight"
          trigger={['click']}>
          <div>
            <strong>Ordenar Por: </strong>
            <span>
              <Icon type="menu" />
              {sortedInfo.menuItem}
            </span>
          </div>
        </Dropdown>
      </TableActions>

      <TableList
        loading={loadingTable}
        rowKey="id"
        rowSelection={rowSelection}
        dataSource={data}>
        <Column
          sorter={(a, b) => a.nome.localeCompare(b.nome)}
          sortOrder={sortedInfo.columnKey === 'nome' && sortedInfo.order}
          title="Nome da Etapa"
          dataIndex="nome"
        />
        <Column
          filteredValue={[filteredInfo.filterKey]}
          onFilter={(value, record) =>
            value.toLowerCase() === 'todos' || record.id_tipo_etapa === +value
          }
          sorter={(a, b) =>
            TipoEtapaEnum[a.id_tipo_etapa].localeCompare(
              TipoEtapaEnum[b.id_tipo_etapa]
            )
          }
          sortOrder={sortedInfo.columnKey === 'tipo' && sortedInfo.order}
          title="Tipo de Etapa"
          dataIndex="id_tipo_etapa"
          render={text => {
            const tipo = TipoEtapaEnum[text];
            let color;
            if (tipo === TipoEtapaEnum[2]) {
              color = '#3DD598';
            } else if (tipo === TipoEtapaEnum[1]) {
              color = '#0062FF';
            } else color = '#ff0000';
            return <span style={{ color, fontSize: 12 }}>{tipo}</span>;
          }}
        />
        <Column
          title="Cadastrado em"
          dataIndex="created_at"
          render={text => <span>{formatDate(moment(text || 0))}</span>}
        />
        <Column
          title="Atualizado em"
          dataIndex="updated_at"
          render={text => <span>{formatDate(moment(text || 0))}</span>}
        />
        <Column
          title="Ativa"
          dataIndex="ativa"
          render={ativa => (ativa ? 'Ativa' : 'Desativada')}
        />
        <Column
          title="Ações"
          width={80}
          align="center"
          render={(_, record) => (
            <span>
              <Dropdown
                overlay={menuRowDropdown(record)}
                placement="bottomRight"
                trigger={['click']}>
                <Icon type="more" style={{ fontSize: 20 }} />
              </Dropdown>
            </span>
          )}
        />
      </TableList>
    </>
  );
}

export default TratamentosEtapa;
