import styled from 'styled-components';

export const HeaderPessoas = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;

  > div {
    /* Added a margin on header user resumo */
    &:last-child {
      margin-top: 15px;
    }
  }

  .btn-top-back {
    color: #44444f;
    border: unset;
    opacity: 0.6;
    transition: opacity 300ms linear;

    &:hover {
      opacity: 1;
    }
  }
`;
