import styled from 'styled-components';

export const StyledEditContent = styled.div`
  h4.ant-typography {
    font-size: 16px;
  }

  .bordered-box {
    background: #ffffff;
    padding: 29px 33px 29px 17px;
    border-radius: 20px;
    max-width: 1270px;
  }

  .etapas-box {
    border: 1px solid #E2E2EA;
    border-radius: 23px;
    padding: 25px;
    margin-bottom: 28px;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .etapas-col-1 {
    padding-left: 0 !important;
  }

  .etapas-col-2 {
    margin-top: 30px;
    padding-right: 0 !important;
  }

  .spin-wrapper {
    text-align: center;
  }
`;

export const DroppableBox = styled.div`
  padding: 20px 35px 25px 30px;
  border: 1px dashed #0062FF;
  border-radius: 10px;
  background-color: ${(props) => (props.isDraggingOver ? 'rgba(0,98,255,0.04)' : 'rgba(0,98,255,0.01)')};

  .legend-box {
    opacity: 0.59;
    color: #696974;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 21px;
  }

  .etapa-drag {
    display: flex;
    align-items: center;
    margin: 10px 0;

    > .anticon {
      font-size: 18px;
      color: #92929D;
      margin-right: 20px;
    }
  }

  .btns {
    height: 24px;
  }
`;
