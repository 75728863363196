import React, { useEffect, useCallback } from 'react';
import { Icon } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { EtapaItem } from 'components/EtapaItem';

import { fetchEtapas } from 'store/ducks/paciente';
import { openModal } from 'store/ducks/modal';
import Modals from 'modals';
import moment from 'moment';
import Etapa from './Step/Etapa';
import Estagio from './Step/Estagio';

export default function Tratamento({ tratamento, last }) {
  let indexEtapas = 0;
  const etapas = useSelector(
    state => state.paciente.etapas[tratamento.id] || []
  );

  const estagios = useSelector(
    state => state.paciente.estagios[tratamento.id] || []
  );

  const list = [...etapas, ...estagios].sort((b, a) => {
    return moment(a.data_realizacao) - moment(b.data_realizacao);
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchEtapas(tratamento.id));
  }, [dispatch, tratamento.id]);

  const handleOpenModal = useCallback(() => {
    dispatch(openModal(Modals.ModalEtapa, { idTratamento: tratamento.id }));
  }, [dispatch, tratamento.id]);

  return (
    <>
      {last && !tratamento.finalizado && (
        <EtapaItem noPadding status="new" onClick={handleOpenModal}>
          <div className="new-step">
            <Icon type="plus" />
          </div>
        </EtapaItem>
      )}

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {list.map(item => {
          if (item.tipo === 'estagio') {
            return <Estagio key={item.id} item={item} />;
          }
          if (item.tipo === 'etapa') {
            return (
              <Etapa
                tratamento={tratamento}
                key={item.id}
                item={item}
                // eslint-disable-next-line no-plusplus
                step={[etapas.length - 1 - indexEtapas++, etapas.length]}
              />
            );
          }
          return null;
        })}
      </div>
    </>
  );
}
