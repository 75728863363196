import { Col, Form, Row, Select, Typography, TimePicker, Button } from 'antd';
import { BigModal, ModalActions } from 'components/BigModal';
import moment from 'moment';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { batch, useDispatch } from 'react-redux';
import { findAll } from 'services/etapaModeloService';
import { save, update } from 'services/etapaService';
import { closeModal } from 'store/ducks/modal';
import { fetchEtapas } from 'store/ducks/paciente';
import { ButtonTransparent } from 'components/Button';
import { ButtonToggleMonth, StyleCalendar, StyledContent } from './styled';

const { Title } = Typography;

const format = 'HH:mm';

function changeMonth(direction, value, onChange) {
  const month = value.month();

  const newValue = value.clone();
  if (direction === 'prev') {
    newValue.month(month - 1);
  } else {
    newValue.month(month + 1);
  }

  onChange(newValue);
}

function ModalEtapa({ form, visible, idTratamento, etapa }) {
  const [etapasModelo, setEtapasModelo] = useState([]);

  useEffect(() => {
    async function fetch() {
      const { data } = await findAll();
      setEtapasModelo(data);
    }

    if (visible) {
      fetch();
    }

    return () => {
      setEtapasModelo([]);
    };
  }, [visible]);

  const dispatch = useDispatch();

  const { getFieldDecorator, validateFields } = form;

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  const handleOkModal = useCallback(() => {
    validateFields((err, values) => {
      if (err) {
        return;
      }

      let dataRealizacao = `${values.date.format(
        'YYYY-MM-DD'
      )} ${values.time.format('HH:mm:ss')}`;

      dataRealizacao = moment(dataRealizacao);
      
      if (etapa) {
        update(etapa.id, {
          id_etapa_modelo: values.id_etapa_modelo,
          data_realizacao: dataRealizacao,
        }).then(() => {
          batch(() => {
            dispatch(fetchEtapas(idTratamento));
            dispatch(closeModal());
          });
        });
      } else {
        save({
          id_etapa_modelo: values.id_etapa_modelo,
          id_tratamento: idTratamento,
          data_realizacao: dataRealizacao,
        }).then(() => {
          batch(() => {
            dispatch(fetchEtapas(idTratamento));
            dispatch(closeModal());
          });
        });
      }
    });
  }, [dispatch, etapa, idTratamento, validateFields]);

  const textAction = useMemo(() => (!etapa ? 'Cadastrar' : 'Editar'), [etapa]);

  return (
    <BigModal
      maskClosable={false}
      closable={false}
      visible={visible}
      onCancel={handleCloseModal}
      footer={null}
      destroyOnClose>
      <StyledContent>
        <Title level={4}>{textAction} informações da etapa</Title>
        {/* SALVAR NOVA ETAPA */}
        {!etapa && (
          <>
            <Form.Item label="Selecione uma etapa">
              {getFieldDecorator('id_etapa_modelo', {
                rules: [
                  {
                    required: true,
                    message: 'Selecione uma etapa.',
                  },
                ],
              })(
                <Select
                  size="large"
                  style={{ width: '75%', marginBottom: 20 }}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  showSearch>
                  {etapasModelo.map(em => (
                    <Select.Option etapa={em} key={em.id} value={em.id}>
                      {em.nome}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </>
        )}

        {etapa?.id_tipo_etapa === 1 && (
          <>
            <p>
              A medicação padrão do tratamento é: <strong>{etapa.nome}</strong>.
            </p>
            <Form.Item label="Pesquise abaixo caso queira substituir a medicação">
              {getFieldDecorator('id_etapa_modelo', {
                initialValue: etapa.id_etapa_modelo,
                rules: [
                  {
                    required: true,
                    message: 'Selecione uma etapa.',
                  },
                ],
              })(
                <Select
                  size="large"
                  style={{ width: '75%', marginBottom: 20 }}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  showSearch>
                  {etapasModelo
                    .filter(e => e.id_tipo_etapa === 1)
                    .map(et => (
                      <Select.Option etapa={et} key={et.id} value={et.id}>
                        {et.nome}
                      </Select.Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </>
        )}

        <Form.Item label="Esta etapa está prevista para ocorrer em">
          {getFieldDecorator('date', {
            initialValue: !etapa?.data_realizacao
              ? moment()
              : moment(etapa.data_realizacao),
            rules: [
              {
                required: true,
                message: 'Selecione uma data',
              },
            ],
          })(
            <StyleCalendar
              fullscreen={false}
              headerRender={({ value, onChange }) => {
                const monthYear = value.format('MMMM YYYY');
                return (
                  <div className="custom-fullcalendar-header">
                    <Row type="flex" justify="space-between">
                      <Col>{monthYear}</Col>
                      <Col className="btns">
                        <ButtonToggleMonth
                          size="small"
                          icon="left"
                          onClick={() => changeMonth('prev', value, onChange)}
                        />
                        <ButtonToggleMonth
                          size="small"
                          icon="right"
                          onClick={() => changeMonth('next', value, onChange)}
                        />
                      </Col>
                    </Row>
                  </div>
                );
              }}
            />
          )}
        </Form.Item>
        <Form.Item label="Selecione o horário" className="time-item">
          {getFieldDecorator('time', {
            initialValue: !etapa?.data_realizacao
              ? undefined
              : moment(etapa.data_realizacao),
            rules: [
              {
                required: true,
                message: 'Selecione o horário',
              },
            ],
          })(<TimePicker size="large" format={format} minuteStep={5} />)}
        </Form.Item>
      </StyledContent>
      <ModalActions justify="middle">
        <ButtonTransparent size="small" onClick={handleCloseModal}>
          Cancelar
        </ButtonTransparent>
        <Button type="primary" size="large" onClick={handleOkModal}>
          <strong>{textAction}</strong>
        </Button>
      </ModalActions>
    </BigModal>
  );
}

export default Form.create({})(ModalEtapa);
