
import api from './api';

export function findAll() {
  return api.get('/tratamentos/modelo');
}

export function findById(id) {
  return api.get(`/tratamentos/modelo/${id}`);
}

export function save(values) {
  return api.post('/tratamentos/modelo', values);
}

export function update(id, values) {
  return api.put(`/tratamentos/modelo/${id}`, values);
}

export function remove(id) {
  return api.delete(`/tratamentos/modelo/${id}`);
}
