import { saveComment, listComments } from 'services/commentService';
import { createTypes, createReducer } from '../duckCreator';

export const Types = createTypes(
  ['CLEAR', 'ADD_NEW_COMMENT', 'SET_COMMENTS'],
  'comments'
);

const mockComments = [];

const initialState = mockComments;

const actionReducers = {
  [Types.CLEAR]: () => {
    return initialState;
  },
  [Types.ADD_NEW_COMMENT]: (state, comment) => {
    return [...state, comment];
  },
  [Types.SET_COMMENTS]: (state, comments) => {
    if (comments?.length > 0) {
      return comments;
    }
    return state;
  },
};

// Action Creators

function addNewComment(comment) {
  return { type: Types.ADD_NEW_COMMENT, payload: comment };
}

function setComments(comments) {
  return { type: Types.SET_COMMENTS, payload: comments };
}

export function clear() {
  return { type: Types.CLEAR };
}

export function addComment(comment) {
  return async dispatch => {
    const { data } = await saveComment(comment);
    dispatch(addNewComment(data));
  };
}

export function fetchComments(idEtapa) {
  return async dispatch => {
    const { data } = await listComments(idEtapa);
    dispatch(setComments(data));
  };
}

export default createReducer(initialState, actionReducers);
