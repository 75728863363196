import React, { useMemo } from 'react';
import constantes from 'utils/constantes';
import Etapa from '../TemplateEtapa';
import SectionDescricao from '../Sections/SectionDescricao';
import { ButtonAplicacao } from './ButtonAplicacao';

export default function EtapaComum({ etapa }) {
  const footer = useMemo(() => {
    if (etapa.status === constantes.ETAPA.STATUS.NAO_EXECUTADO) {
      return (
        <ButtonAplicacao
          etapa={etapa}
          descricao="Não compareceu"
          className="btn-orange"
          status={constantes.ETAPA.STATUS.NAO_COMPARECEU}
        />
      );
    }

    if (etapa.status === constantes.ETAPA.STATUS.NAO_EXECUTADO) {
      return (
        <ButtonAplicacao
          etapa={etapa}
          descricao="Confirmar comparecimento"
          className="btn-green"
          icon="check"
          status={constantes.ETAPA.STATUS.EXECUTADO}
        />
      );
    }

    return null;
  }, [etapa]);

  return (
    <Etapa
      etapa={etapa}
      sections={[<SectionDescricao etapa={etapa} />]}
      footer={[footer]}
    />
  );
}
