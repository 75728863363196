import { useEffect, useState } from 'react';
import { getStats as getMunicipiosStats } from 'services/municipiosService';

import { getStats as getMesorregiaoStats } from 'services/mesorregioesService';
import ClusterChart from './ClusterChart';
import { CONSTANTES } from '../CONSTANTES';

export default function ClusterLayer({ map, delimitacao }) {
  const [data, setData] = useState();

  useEffect(() => {
    Promise.all([getMunicipiosStats(), getMesorregiaoStats()]).then(
      ([r1, r2]) =>
        setData({
          [CONSTANTES.DELITACAO.REGIONAL]: r2.data,
          [CONSTANTES.DELITACAO.MUNICIPAL]: r1.data,
        })
    );
  }, []);

  useEffect(() => {
    if (data && map) {
      const cluster = new ClusterChart(map, data[delimitacao]);
      return () => {
        cluster.destroy();
      };
    }
    return () => { };
  }, [data, map, delimitacao]);

  return null;
}
