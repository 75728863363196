import React from 'react';
import { Col, Row } from 'antd';
import ActionsButtons from './ActionsButtons';
import EvolucaoTratamento from './EvolucaoTratamento';

export default function Header({ tratamento }) {
  return (
    <Row type="flex" justify="space-between">
      <Col>
        <EvolucaoTratamento tratamento={tratamento} />
      </Col>
      <Col>
        <ActionsButtons tratamento={tratamento} />
      </Col>
    </Row>
  );
}
