import React, { useReducer, useEffect, useMemo } from 'react';
import { List, Spin } from 'antd';
import CardHoverable from 'components/CardHoverable';
import { findPacientesByMunicipioDoenca } from 'services/pacienteService';
import { filterWords } from 'utils/Functions';
import { StyledListItem, StyledHeaderSider } from '../../styled';
import { PacienteItem } from '../PacienteItem';

const initialState = {
  data: [],
  loading: true,
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_DATA':
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    case 'CLEAR':
      return { ...initialState };
    default:
      throw Error();
  }
}

export default function ListaUnidades({ idMunicipio, filter }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { data, loading } = state;

  useEffect(() => {
    if (idMunicipio) {
      dispatch({
        type: 'CLEAR',
      });
      findPacientesByMunicipioDoenca(idMunicipio, 2).then(result =>
        dispatch({
          type: 'SET_DATA',
          payload: { ...result },
        })
      );
    }
  }, [idMunicipio]);

  const filteredData = useMemo(() => {
    if (filter !== '') {
      return data?.filter(
        p =>
          filterWords(filter, p.nome || '') ||
          filterWords(filter, p.sobrenome || '')
      );
    }
    return data;
  }, [data, filter]);

  if (loading)
    return (
      <StyledHeaderSider>
        <Spin />
      </StyledHeaderSider>
    );

  return (
    <List
      loading={loading}
      itemLayout="horizontal"
      dataSource={filteredData}
      renderItem={paciente => (
        <li>
          <StyledListItem>
            <CardHoverable height={71} key={paciente.id}>
              <PacienteItem paciente={paciente} />
            </CardHoverable>
          </StyledListItem>
        </li>
      )}
    />
  );
}
