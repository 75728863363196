import {
  Checkbox as ACheckbox,
  DatePicker as ADatePicker,
  Input as AInput,
  Select as ASelect,
  Radio as ARadio,
} from 'antd';

import React from 'react';
import AMaskInput from 'react-maskinput';
import ASelectVirtualized from 'components/SelectVirtualized';
import ItemLabel from './ItemLabel';
import { ProfilePicture } from './ProfilePicture';

const makeField = Component => ({ input, meta, label, ...rest }) => {
  const { required, ...fieldInput } = input;
  if (Component === ACheckbox) {
    fieldInput.checked = input.value;
  }

  return (
    <ItemLabel label={label} forInput={input.name} required={required}>
      <Component {...input} {...rest} size="large" />
    </ItemLabel>
  );
};

const MaskInput = makeField(AMaskInput);
const Input = makeField(AInput);
const Select = makeField(ASelect);
const DatePicker = makeField(ADatePicker);
const Checkbox = makeField(ACheckbox);
const SelectVirtualized = makeField(ASelectVirtualized);
const Radio = makeField(ARadio);
const RadioGroup = makeField(ARadio.Group);

Select.Option = ASelect.Option;
Radio.Group = ARadio;
RadioGroup.Radio = ARadio;

export {
  ItemLabel,
  ProfilePicture,
  MaskInput,
  Input,
  Select,
  DatePicker,
  Checkbox,
  SelectVirtualized,
  Radio,
  RadioGroup,
};
