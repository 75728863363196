import { useState, useCallback } from 'react';

export default initialValue => {
  const [value, setValue] = useState(initialValue);

  const changeValue = useCallback(() => {
    setValue(oldValue => !oldValue);
  }, []);

  return [value, changeValue];
};
