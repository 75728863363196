import React from 'react';
import { hot } from 'react-hot-loader/root';
import { Router } from '@reach/router';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/es/locale/pt_BR';
import 'moment/locale/pt-br';
import { Provider } from 'react-redux';
import store from 'store';

import Main from './Main';
import Login from './Login';
import Modals from '../modals';

const ptbr = {
  ...ptBR,
  DatePicker: {
    ...ptBR.DatePicker,
    dateFormat: 'DD-MM-YYYY',
    dateTimeFormat: 'DD-MM-YYYY HH:mm:ss',
    weekFormat: 'YYYY-wo',
    monthFormat: 'YYYY-MM',
  },
};

function App() {
  return (
    <Provider store={store}>
      <ConfigProvider locale={ptbr}>
        <Router>
          <Main path="/" />
          <Main path="/:currentPage/*" />
          <Login path="/login" />
          <Login path="/login/recuperar-senha" />
          <Login path="/login/mudar-senha/:tokenSenha" />
        </Router>
        <Modals />
      </ConfigProvider>
    </Provider>
  );
}

export default hot(App);
