import { Col, Collapse, Divider, Row, Typography } from 'antd';
import React, { useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { fetchPaciente, fetchTratamentos } from 'store/ducks/paciente';
import styled from 'styled-components';
import Header from './Header';
import Tratamento from './Tratamento';
import EtapaDetalhe from './Tratamento/EtapaDetalhe';

const Box = styled.div`
  border-radius: 20px;
  box-shadow: 0 2px 23px 0 rgba(2, 43, 108, 0.12);
  background-color: #ffffff;
  padding: 18px 48px;
`;

const { Panel } = Collapse;
const { Title } = Typography;

function DetalheTratamento() {
  const tratamento = useSelector(state => state.etapa.tratamento);
  if (!tratamento) return null;
  return (
    <>
      <Title level={4}>Tratamento ({tratamento.id})</Title>
      <Header tratamento={tratamento} />
    </>
  );
}

function DetalheEtapa() {
  const etapa = useSelector(state => state.etapa.etapa);

  if (etapa) {
    return (
      <Box>
        <DetalheTratamento />
        <Divider />
        <EtapaDetalhe etapa={etapa} />
      </Box>
    );
  }
  return null;
}

function Tratamentos({ doencaId }) {
  const tratamentos = useSelector(
    state => state.paciente.tratamentos[doencaId] || []
  );

  if (tratamentos.length <= 0) {
    return null;
  }

  return (
    <Collapse
      bordered={false}
      expandIconPosition="right"
      defaultActiveKey={+tratamentos?.[0]?.id}>
      {tratamentos.map((t, index) => {
        return (
          <Panel
            header={`# ${t.id} ${t.nome}`}
            key={+t.id}
            style={{ backgroundColor: '#fff' }}>
            <Tratamento tratamento={t} last={index === 0} />
          </Panel>
        );
      })}
    </Collapse>
  );
}

export default function Doenca({ doencaId, pacienteId }) {
  const dispatch = useDispatch();

  useEffect(() => {
    batch(() => {
      dispatch(fetchPaciente(pacienteId));
      dispatch(fetchTratamentos(pacienteId, doencaId));
    });
  }, [dispatch, doencaId, pacienteId]);

  return (
    <div style={{ padding: '33px 46px 33px 23px' }}>
      <Row gutter={24}>
        <Col span={8}>
          <Tratamentos doencaId={doencaId} />
        </Col>
        <Col span={16}>
          <DetalheEtapa />
        </Col>
      </Row>
    </div>
  );
}
