import { salvarEstagioClinico } from 'services/estagioClinicoService';
import { batch } from 'react-redux';
import { update } from 'services/etapaService';
import { createTypes, createReducer } from '../duckCreator';
import { fetchTratamentos, fetchEtapas } from './paciente';

export const Types = createTypes(
  [
    'SET_ETAPA_TRATAMENTO',
    'CLEAR_ETAPA',
    'UPDATE_STATUS_ETAPA',
    'UPDATE_ESTAGIO_CLINICO_TRATAMENTO',
  ],
  'etapa'
);

const initialState = {
  etapa: undefined,
  tratamento: undefined,
};

const actionReducers = {
  [Types.SET_ETAPA_TRATAMENTO]: (state, payload) => ({ ...state, ...payload }),
  [Types.UPDATE_ESTAGIO_CLINICO_TRATAMENTO]: (state, payload) => ({
    ...state,
    tratamento: { ...state.tratamento, estagio_clinico: payload },
  }),
  [Types.CLEAR_ETAPA]: state => ({
    ...state,
    etapa: undefined,
    tratamento: undefined,
  }),
  [Types.UPDATE_STATUS_ETAPA]: (state, payload) => ({
    ...state,
    etapa: {
      ...state.etapa,
      status: payload,
    },
  }),
};

// Action Creators
export function setEtapaTratamento(etapa, tratamento) {
  return { type: Types.SET_ETAPA_TRATAMENTO, payload: { etapa, tratamento } };
}

export function updateStatusEtapa(status, propriedades) {
  return async (dispatch, getState) => {
    const etapa = getState().etapa.etapa;
    const prop = etapa.propriedades ?? {};

    console.log('adasda', {
      status,
      propriedades: { ...prop, ...propriedades },
    });

    await update(etapa.id, {
      status,
      propriedades: { ...prop, ...propriedades },
    });
    dispatch({ type: Types.UPDATE_STATUS_ETAPA, payload: status });
  };
}

function updateEstagioClinicoTratamento(estagioClinico) {
  return {
    type: Types.UPDATE_ESTAGIO_CLINICO_TRATAMENTO,
    payload: estagioClinico,
  };
}

export function clearEtapa() {
  return { type: Types.CLEAR_ETAPA };
}

export function addEstagioClinico(idEstagioClinico) {
  return async (dispatch, getState) => {
    const tratamento = getState().etapa.tratamento;

    const { data } = await salvarEstagioClinico(tratamento.id, {
      id: idEstagioClinico,
    });

    batch(() => {
      dispatch(updateEstagioClinicoTratamento(data));
    });
  };
}

export function reload() {
  return async (dispatch, getState) => {
    const tratamento = getState().etapa.tratamento;
    batch(() => {
      dispatch(fetchTratamentos(tratamento.id_paciente, tratamento.id_doenca));
      dispatch(fetchEtapas(tratamento.id));
    });
  };
}
export default createReducer(initialState, actionReducers);
