import styled from 'styled-components';

export const HeaderTratamentos = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;

  .pessoas-relacionadas {
    text-align: right;

    > span {
      color: #44444f;
      font-size: 11px;
      font-weight: 600;
      letter-spacing: 0.8px;
      text-transform: uppercase;
    }

    .pessoas-lista {
      margin-top: 8px;

      .ant-btn {
        margin-left: 10px;
        background-color: #fff !important;

        .anticon {
          font-size: 22px;
          vertical-align: -0.265em;
        }
      }
    }
  }
`;
