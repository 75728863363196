import React from 'react';
import { RadioGroup } from 'components/RadioGroup';

const opts = [
  { label: 'Positivo', value: 1 },
  { label: 'Negativo', value: 2 },
  { label: 'Não Realizado', value: 3 },
];

export default function RadioGroupResultado({ value, onChange, ...props }) {
  return (
    <RadioGroup options={opts} value={value} onChange={onChange} {...props} />
  );
}
