import { Button, Col, Row, Typography, Alert } from 'antd';
import { BigModal, ModalTextDivisor, Subtitle } from 'components/BigModal';
import { ButtonTransparent } from 'components/Button';
import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector, batch } from 'react-redux';
import { closeModal } from 'store/ducks/modal';
import { addEstagioClinico, reload, clearEtapa } from 'store/ducks/etapa';
import constantes from 'utils/constantes';
import { finalizarTratamento } from 'services/tratamentoService';
import {
  RadioGroupEfetuadoNovoTeste,
  RadioGroupSituacaoPaciente,
} from '../../pages/Pacientes/Page/Tratamentos/Doenca/Tratamento/EtapaDetalhe/RadioGroup';

const { Title } = Typography;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Column = styled.div`
  margin-bottom: 30px;
`;

export default function ModalFinalizarTratamento({ visible }) {
  const dispatch = useDispatch();
  const isSuspeito =
    useSelector(state => state.etapa?.tratamento?.estagio_clinico?.id) ===
    constantes.ESTADO_CLINICO.SUSPEITO;
  const idTratamento = useSelector(state => state?.etapa?.tratamento?.id);

  const [situacaoFinal, setSituacaoFinal] = useState();
  const [efetuadoTeste, setEfetuadoTeste] = useState();
  const [loadingFinalizar, setLoadingFinalizar] = useState(false);

  useEffect(() => {
    return () => {
      setSituacaoFinal(-1);
      setEfetuadoTeste(-1);
      setLoadingFinalizar(false);
    };
  }, [visible]);

  const handleChangeSituacao = useCallback(sit => {
    setSituacaoFinal(sit);
    setEfetuadoTeste(-1);
  }, []);

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  const finalizar = useCallback(() => {
    finalizarTratamento(idTratamento).then(() => {
      setLoadingFinalizar(false);
      batch(() => {
        dispatch(reload());
        dispatch(clearEtapa());
        dispatch(closeModal());
      });
    });
  }, [dispatch, idTratamento]);

  const handleFinalizar = useCallback(() => {
    if (situacaoFinal && efetuadoTeste && efetuadoTeste > 0) {
      setLoadingFinalizar(true);
      if (isSuspeito) {
        if (efetuadoTeste === 1) {
          dispatch(
            addEstagioClinico(constantes.ESTADO_CLINICO.CONFIRMADO)
          ).then(() => {
            dispatch(addEstagioClinico(situacaoFinal)).then(finalizar);
          });
        } else {
          dispatch(
            addEstagioClinico(constantes.ESTADO_CLINICO.DESCARTADO)
          ).then(finalizar);
        }
      } else {
        dispatch(addEstagioClinico(situacaoFinal)).then(finalizar);
      }
    }
  }, [dispatch, efetuadoTeste, finalizar, isSuspeito, situacaoFinal]);

  return (
    <BigModal
      width={645}
      visible={visible}
      onCancel={handleCloseModal}
      footer={null}>
      <Title level={4}>Finalizar Tratamento</Title>
      {isSuspeito && (
        <ModalTextDivisor>
          <Alert
            message="ATENÇÃO"
            description={
              <div style={{ textAlign: 'left' }}>
                O paciente se encontra como <strong>SUSPEITO</strong> até o
                momento, se for finalizado o tratamento sem antes ser{' '}
                <strong>CONFIRMADO</strong>, o tratamento será definido como{' '}
                <strong>DESCARTADO</strong>.
              </div>
            }
            type="warning"
            showIcon
          />
        </ModalTextDivisor>
      )}
      <ModalTextDivisor>
        Para finalizar o tratamento você precisa informar a situação final do
        paciente:
      </ModalTextDivisor>

      <ModalContent>
        <Column>
          <RadioGroupSituacaoPaciente
            onChange={handleChangeSituacao}
            value={situacaoFinal}
          />
        </Column>
        <Subtitle>Foi efetuado novo teste?</Subtitle>

        <Column>
          <RadioGroupEfetuadoNovoTeste
            removeOpt={
              situacaoFinal === constantes.ESTADO_CLINICO.OBITO ? 0 : 1
            }
            onChange={setEfetuadoTeste}
            value={efetuadoTeste}
          />
        </Column>
      </ModalContent>
      <Row type="flex" justify="center" align="middle" gutter={8}>
        <Col>
          <ButtonTransparent size="small" onClick={handleCloseModal}>
            Cancelar
          </ButtonTransparent>
        </Col>
        <Col>
          <Button
            size="large"
            type="primary"
            onClick={handleFinalizar}
            loading={loadingFinalizar}>
            <strong>Finalizar</strong>
          </Button>
        </Col>
      </Row>
    </BigModal>
  );
}
