import styled from 'styled-components';
import { Menu } from 'antd';

export const StyledMenuStep = styled.div`
  color: #0062ff;
  font-size: 25px;
  width: 16px;
`;

export const StyledDropdownMenu = styled(Menu)`
  .ant-dropdown-menu-item {
    color: #0062ff;
    font-size: 12px;
    font-weight: 500;

    .anticon {
      font-size: 18px;
      vertical-align: -0.2em;
    }
  }
`;
