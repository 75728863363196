import React from 'react';
import CommentForm from './CommentForm';
import CommentList from './CommentList';
import { StyledComments } from './styled';

export function Comments({ idEtapa }) {
  return (
    <StyledComments>
      <CommentList idEtapa={idEtapa} />
      <CommentForm idEtapa={idEtapa} />
    </StyledComments>
  );
}

export { CommentList, CommentForm };
