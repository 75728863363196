import React, { useCallback } from 'react';
import { Row, Col, Button } from 'antd';
import { openModal } from 'store/ducks/modal';
import Modals from 'modals';
import { useDispatch, useSelector } from 'react-redux';
import { EstagioClinico } from './EstagioClinico';

export default function Buttons({ tratamento }) {
  const dispatch = useDispatch();
  const finalizado = useSelector(state => state?.etapa?.tratamento?.finalizado);

  const handleFinalizarTratamento = useCallback(() => {
    dispatch(openModal(Modals.ModalFinalizarTratamento));
  }, [dispatch]);

  return (
    <Row type="flex" justify="space-between" gutter={16}>
      <EstagioClinico tratamento={tratamento} />
      <Col>
        <Button
          size="large"
          type="primary"
          onClick={handleFinalizarTratamento}
          disabled={finalizado}>
          <strong>Finalizar Tratamento</strong>
        </Button>
      </Col>
    </Row>
  );
}
