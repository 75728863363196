import React, { useCallback, useEffect, useState } from 'react';
import { BorderedBox } from 'components/Layout';
import { Typography, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPaciente, clear } from 'store/ducks/paciente';
import { save, update } from 'services/pacienteService';
import FormCadastro from './FormCadastro';

const { Title } = Typography;

function FormPaciente({ navigate, pacienteId }) {
  const dispatch = useDispatch();
  const paciente = useSelector(state => state.paciente.data || {});
  const [loading, setLoading] = useState();

  useEffect(() => {
    dispatch(fetchPaciente(pacienteId));
    return () => {
      dispatch(clear());
    };
  }, [dispatch, pacienteId]);

  const handleSave = useCallback(
    (idPaciente, formData) => {
      setLoading(true);

      const service = idPaciente
        ? update(idPaciente, formData)
        : save(formData);

      service
        .then(({ data }) => {
          setLoading(false);
          navigate(`/pacientes/${data.id}/tratamentos`);
        })
        .catch(e => {
          const { data = {} } = e.response;
          notification.error({
            message: 'Ocorreu um erro ao salvar',
            description:
              data.message || 'Verifique se todos os campos estão preenchidos',
          });
        })
        .finally(() => setLoading(false));
    },
    [navigate]
  );

  return (
    <>
      <Title level={3}>Cadastro de Paciente</Title>
      <BorderedBox>
        <FormCadastro
          paciente={paciente}
          loading={loading}
          onSave={handleSave}
          tipo="cadastro"
        />
      </BorderedBox>
    </>
  );
}

export default FormPaciente;
