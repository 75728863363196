import styled from 'styled-components';
import { Button } from 'antd';

export const StyledButtonPessoa = styled(Button)`
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  width: 95%;
  height: auto;
  padding: 12px 15px;
  margin-bottom: 14px;
  border: none;
  background-color: #ffffff;
  box-shadow: 3px 3px 10px 0 rgba(0, 98, 255, 0.09);

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    box-shadow: 3px 3px 10px 0 rgba(0, 98, 255, 0.2);
  }

  &.active {
    background-color: #0062ff;
    box-shadow: 0px 0px 0px 0px rgba(0, 98, 255, 0);

    .pessoa-content {
      span,
      strong {
        color: #fff;
      }
    }

    .arrow-right {
      right: -18px;
      z-index: 0;
      border-width: 12px 0 12px 18px;
      border-color: transparent transparent transparent #0062ff;
    }
  }

  .ant-avatar {
    flex: 0 0 auto;
    border: 1px solid #fff;
    margin-right: 10px;
  }

  .pessoa-content {
    flex: 1;
    min-width: 0;
    text-align: left;

    span,
    strong {
      display: block;
      overflow: hidden;
      font-size: 13px;
      color: #8f8fa2;
      white-space: nowrap;
      text-overflow: ellipsis;
      transition: color 300ms linear;
    }

    strong {
      font-size: 12px;
      color: #44444f;
      margin-top: 4px;
    }
  }

  .arrow-right {
    position: absolute;
    content: '';
    display: block;
    top: 19px;
    right: -10px;
    width: 0;
    height: 0;
    z-index: -1;
    border-radius: 0;
    border-style: solid;
    border-width: 12px 0 12px 15px;
    border-color: transparent;
    transition: all 300ms linear;
  }
`;
