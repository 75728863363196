import { useSelector, useDispatch } from 'react-redux';
import { fetchPacientePorTipo, setNewPacienteTipo } from 'store/ducks/paciente';
import { useCallback } from 'react';

export function useActionSelectParent() {
  const dispatch = useDispatch();
  const tipo = useSelector(state => state.modalPessoa?.tipo);

  const select = useCallback(
    pacienteId => {
      dispatch(fetchPacientePorTipo(pacienteId, tipo, true));
    },
    [dispatch, tipo]
  );

  const click = useCallback(() => {
    dispatch(setNewPacienteTipo(tipo));
  }, [dispatch, tipo]);

  return [select, click];
}
