import { Link } from '@reach/router';
import { Icon } from 'antd';
import { StyledMenu, StyledSider } from 'components/Layout';
import React, { useState, useEffect } from 'react';

const { Item } = StyledMenu;

const menuItems = [
  { name: 'Dashboard', path: '/dashboard', icon: 'appstore' },
  { name: 'Pacientes', path: '/pacientes', icon: 'user' },
  { name: 'Tratamentos', path: '/tratamentos', icon: 'heart' },
  {
    name: 'Etapas de tratamentos',
    path: '/etapas-tratamento',
    icon: 'check-circle',
  },
  {
    name: 'Cadastro microárea',
    path: '/microarea',
    icon: 'apartment',
  },
  {
    name: 'Cadastro unidade saúde',
    path: '/unidade-saude',
    icon: 'shop',
  },
  {
    name: 'Cadastro Usuários',
    path: '/usuarios',
    icon: 'usergroup-add',
  },
];

const items = menuItems.map(item => (
  <Item key={item.icon}>
    <Link to={item.path}>
      <div className="selected-border" />
      <Icon type={item.icon} />
      {item.name}
    </Link>
  </Item>
));

function MenuLateral({ currentPage = '' }) {
  const [collapsed, setCollapsed] = useState(false);
  useEffect(() => setCollapsed(currentPage === 'dashboard'), [currentPage]);
  return (
    <StyledSider
      width={250}
      theme="light"
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}>
      <StyledMenu theme="light">{items}</StyledMenu>
    </StyledSider>
  );
}

export default MenuLateral;
