import mapboxgl from 'mapbox-gl';
import * as d3 from 'd3';
import { findPacientesByMunicipioDoencaGeometria } from 'services/pacienteService';

export default class ClusterChart {
  constructor(map, data) {
    this.map = map;
    this.data = data;

    this.municipioClicado = undefined;

    this.markers = {};
    this.markersOnScreen = {};

    this.colorScale = d3
      .scaleOrdinal()
      .domain(['confirmado', 'recuperado', 'descartado', 'obito', 'suspeito'])
      .range(['#FFD422', '#3DD598', '#3DD500', '#000000', '#f77474']);

    this.updateMarkers();
  }

  async onClick(props, idMunicipio) {
    const map = this.map;

    // const infoEl = this.createTable(props);

    map.U.removeSource('municipio_paciente_source');
    if (this.municipioClicado === idMunicipio) {
      // document.getElementById('dialog-cluter').innerHTML = '';
      this.municipioClicado = undefined;
      return;
    }

    this.municipioClicado = idMunicipio;
    // document.getElementById('dialog-cluter').append(infoEl);

    const { data } = await findPacientesByMunicipioDoencaGeometria(
      idMunicipio,
      2
    );

    map.U.addGeoJSON('municipio_paciente_source', data);

    const confirmado = ['==', ['get', 'id_estagio_clinico'], 5];
    const recuperado = ['==', ['get', 'id_estagio_clinico'], 6];
    const descartado = ['==', ['get', 'id_estagio_clinico'], 7];
    const obito = ['==', ['get', 'id_estagio_clinico'], 8];
    const suspeito = ['==', ['get', 'id_estagio_clinico'], 4];

    const mapColors = [
      'case',
      confirmado,
      '#FFD422',
      recuperado,
      '#3DD598',
      descartado,
      '#3DD500',
      obito,
      '#000000',
      suspeito,
      '#f77474',
      '#fff',
    ];

    map.U.addCircle('municipio_paciente_circle', 'municipio_paciente_source', {
      'circle-color': mapColors,
      'circle-radius': 6,
      'circle-stroke-color': '#000',
      'circle-stroke-width': 1,
    });

    map.U.addCircle(
      'municipio_paciente_circle_outer',
      'municipio_paciente_source',
      {
        'circle-stroke-color': '#000',
        'circle-stroke-width': 2,
        'circle-radius': 8,
        'circle-color': 'rgba(0, 0, 0, 0)',
      }
    );
  }

  createDonutChart(props, idMunicipio) {
    const div = document.createElement('div');

    const data = [
      { type: 'confirmado', count: props.confirmados },
      { type: 'recuperado', count: props.recuperados },
      { type: 'descartado', count: props.descartados },
      { type: 'obito', count: props.obitos },
      { type: 'suspeito', count: props.suspeitos },
    ];

    const thickness = 8;
    const radius = 23;
    const circleRadius = radius - thickness;

    const svg = d3
      .select(div)
      .append('svg')
      .attr('class', 'pie')
      .attr('width', radius * 2)
      .attr('height', radius * 2)
      .style('cursor', 'pointer');

    const g = svg
      .append('g')
      .attr('transform', `translate(${radius}, ${radius})`);

    const arc = d3
      .arc()
      .padAngle(0.1)
      .innerRadius(radius - thickness)
      .outerRadius(radius);

    const pie = d3
      .pie()
      .value(d => d.count)
      .sort(null);

    g.selectAll('path')
      .data(pie(data.sort((x, y) => d3.ascending(y.count, x.count))))
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', d => this.colorScale(d.data.type));

    g.append('circle')
      .attr('r', circleRadius)
      .attr('fill', 'rgba(255, 255, 255, 0.7)')
      .attr('class', 'center-circle');

    g.append('text')
      .attr('class', 'total')
      .text(props.total)
      .attr('text-anchor', 'middle')
      .attr('font-weight', 700)
      .attr('dy', 5)
      .attr('fill', 'black');

    svg.on('click', () => {
      this.onClick(props, idMunicipio);
    });

    return div;
  }

  createTable(props) {
    const getPerc = count => {
      return count / props.total;
    };

    const data = [
      {
        type: 'confirmado',
        label: 'Confirmados',
        perc: getPerc(props.confirmados),
      },
      {
        type: 'recuperado',
        label: 'Recuperados',
        perc: getPerc(props.recuperados),
      },
      {
        type: 'descartado',
        label: 'Descartados',
        perc: getPerc(props.descartados),
      },
      { type: 'obito', label: 'Obitos', perc: getPerc(props.obitos) },
      {
        type: 'suspeito',
        label: 'Suspeitos',
        perc: getPerc(props.suspeitos),
      },
    ];

    const columns = ['label', 'perc'];
    const div = document.createElement('div');
    const close = document.createElement('div');
    close.innerHTML = 'X';

    div.appendChild(close);

    const table = d3
      .select(div)
      .append('table')
      .attr('class', 'table');

    const thead = table.append('thead');
    const tbody = table.append('tbody');

    thead
      .append('tr')
      .selectAll('th')
      .data(columns)
      .enter()
      .append('th')
      .text(d => (d === 'perc' ? '%' : 'Estagios'));

    const rows = tbody
      .selectAll('tr')
      .data(
        data.filter(i => i.perc).sort((x, y) => d3.descending(x.perc, y.perc))
      )
      .enter()
      .append('tr')
      .style('border-left', d => `20px solid ${this.colorScale(d.type)}`);

    rows
      .selectAll('td')
      .data(row => {
        return columns.map(column => {
          const val =
            column === 'perc' ? d3.format('.2%')(row[column]) : row[column];
          return { column, value: val };
        });
      })
      .enter()
      .append('td')
      .text(d => d.value)
      .style('text-transform', 'capitalize');

    return div;
  }

  updateMarkers() {
    const newMarkers = {};

    this.data.forEach(d => {
      const { geom, id, ...props } = d;
      let marker = this.markers[id];
      if (!marker) {
        const el = this.createDonutChart(props, id);
        this.markers[id] = new mapboxgl.Marker({
          element: el,
        }).setLngLat(geom);
        marker = this.markers[id];
      }

      newMarkers[id] = marker;

      if (!this.markersOnScreen[id]) {
        marker.addTo(this.map);
      }
    });

    // eslint-disable-next-line no-restricted-syntax
    for (const id in this.markersOnScreen) {
      if (!newMarkers[id]) {
        this.markersOnScreen[id].remove();
      }
    }
    this.markersOnScreen = newMarkers;
  }

  destroy() {
    try {
      this.map.U.removeSource('municipio_paciente_source');
    } catch (e) { }
    this.municipioClicado = undefined;
    Object.values(this.markersOnScreen).forEach(marker => marker.remove());
    Object.values(this.markers).forEach(marker => marker.remove());
  }
}
