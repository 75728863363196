import React, { useEffect, useState } from 'react';
import TagSelect from 'components/TagSelect';
import { findAll, save } from 'services/doencaPreexistentesService';

export default function SelectDoenca({ onChange, selectedTags }) {
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState(selectedTags);
  const [loading, setLoading] = useState(false);

  const tags = items.map(a => a.nome);
  const tagsSelecionadas = selectedItems.map(a => a.nome);

  async function list() {
    findAll().then(({ data }) => {
      setItems(data);
    });
  }

  useEffect(() => {
    list();
  }, []);

  useEffect(() => {
    if (selectedTags !== selectedItems) {
      setSelectedItems(selectedTags);
    }
  }, [selectedTags, selectedItems]);

  async function handleSave(nome) {
    let item = items.find(a => a.nome === nome);

    if (!item) {
      setLoading(true);
      const resposne = await save({ nome });
      item = resposne.data;
      setItems(old => [...old, item]);
      setLoading(false);
    }

    const newAlergiasSelecionadas = [...selectedItems, item];
    setSelectedItems(newAlergiasSelecionadas);
    onChange(newAlergiasSelecionadas);
  }

  function onRemove(tag) {
    const newAlergiasSelecionadas = selectedItems.filter(a => a.nome !== tag);
    setSelectedItems(old => old);
    onChange(newAlergiasSelecionadas);
  }

  return (
    <TagSelect
      sourceData={tags}
      selectedTags={tagsSelecionadas}
      onSelect={handleSave}
      onRemove={onRemove}
      loading={loading}
    />
  );
}
