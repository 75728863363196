const RacaCorEnum = {
  1: 'Branca',
  2: 'Preta',
  3: 'Amarela',
  4: 'Parda',
  5: 'Indígena',
  6: 'Ignorado',
};

export default RacaCorEnum;
