import React, { useState } from 'react';
import ListaUnidades from './ListaUnidades';
import ListaPessoas from './ListaPessoas';
import Header from './Header';
import { Row } from '../styled';
import { StyledSider, StyledTabs } from './styled';
import { TriggerClose } from './TriggerClose';

const { TabPane } = StyledTabs;

export default function DetalheMunicipio({
  municipio = {},
  visible,
  onClose = () => {},
}) {
  const [filter, setFilter] = useState('');

  return (
    <StyledSider
      theme="light"
      placement="left"
      width={370}
      collapsedWidth={0}
      trigger={null}
      collapsed={!visible}
      defaultCollapsed
      onCollapse={onClose}>
      <TriggerClose onClick={onClose} />
      <Row flex="unset">
        <Header municipio={municipio} onFilter={value => setFilter(value)} />
      </Row>
      <Row flex={2}>
        <StyledTabs defaultActiveKey="1" size="small">
          <TabPane key="1" tab="Internados">
            <ListaUnidades idMunicipio={municipio?.id} filter={filter} />
          </TabPane>
          <TabPane key="2" tab="Residentes">
            <ListaPessoas idMunicipio={municipio?.id} filter={filter} />
          </TabPane>
        </StyledTabs>
      </Row>
    </StyledSider>
  );
}
