import React from 'react';
import { RadioGroup } from 'components/RadioGroup';
import constantes from 'utils/constantes';

const opts = [
  { label: 'Recuperado', value: constantes.ESTADO_CLINICO.RECUPERADO },
  { label: 'Óbito', value: constantes.ESTADO_CLINICO.OBITO },
];

export default function RadioGroupSituacaoPaciente(props) {
  return <RadioGroup options={opts} {...props} />;
}
