import api from './api';

export function save(idTratamento, dados) {
  return api.post(`/notificacoes`, { id_tratamento: idTratamento, dados });
}

export function findById(id) {
  return api.get(`/notificacoes/${id}`);
}

export function get() {
  return api.get(`/notificacoes`);
}
