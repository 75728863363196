import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Icon, Dropdown, Menu } from 'antd';

const StyledDropdownFilter = styled(Dropdown)`
  display: inline-block;
  font-size: 13px;
  color: #44444f;
  cursor: pointer;

  .label-filter {
    color: #92929d;
  }

  .anticon {
    font-size: 14px;
    color: #92929e;
    margin-left: 8px;
    vertical-align: -0.285em;
  }
`;

export default function DropdownFilter({
  label = 'Filtrar Por',
  currentItem = '',
  options = [],
  placement = 'bottomLeft',
  onSelect = () => {},
}) {
  const menuOverlay = useMemo(() => {
    if (!Array.isArray(options) || options.length <= 0) return <Menu />;
    return (
      <Menu onClick={menu => onSelect(menu.key, menu.item.props.children)}>
        {options.map(opt => (
          <Menu.Item key={opt.key}>{opt.label}</Menu.Item>
        ))}
      </Menu>
    );
  }, [onSelect, options]);

  return (
    <StyledDropdownFilter
      overlay={menuOverlay}
      placement={placement}
      trigger={['click']}>
      <div>
        <span>{label}: </span>
        <strong>{currentItem}</strong>
        <Icon type="caret-down" />
      </div>
    </StyledDropdownFilter>
  );
}
