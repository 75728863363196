import React, { memo, useCallback } from 'react';
import { Col, Row } from 'antd';
import { EstagioClinicoProgresso } from 'components/ProgressBar';
import styled from 'styled-components';
import ButtonLocalizar from 'components/ButtonLocalizar';

const StyledItem = styled.div`
  .ant-btn-localizar {
    margin-right: 10px;
  }
`;

const Item = memo(({ item, showProgress = true, onLocalizar = undefined }) => {
  const { estagios = [], total_estagio = 0 } = item;

  const handleLocalizar = useCallback(() => {
    onLocalizar && onLocalizar(item.id);
  }, [item.id, onLocalizar]);

  return (
    <StyledItem>
      <Row type="flex" justify="space-between">
        <Col>
          {onLocalizar && <ButtonLocalizar onClick={handleLocalizar} />}
          <strong>{item.nome}</strong>
        </Col>
        <Col>
          <strong>{total_estagio}</strong>
        </Col>
      </Row>
      {showProgress && (
        <EstagioClinicoProgresso estagios={estagios} total={total_estagio} />
      )}
    </StyledItem>
  );
});

export default Item;
