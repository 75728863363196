import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getEstagiosMunicipio } from 'utils/Functions';

const StyledPercentEstagio = styled.span`
  display: inline-block;
  width: ${({ porcentagem }) => porcentagem || 0}%;
  height: 4px;
  background-color: ${({ cor }) => cor || '#fff'};
`;

function getPercent(value, total) {
  return (100 * value) / total;
}

export default function EstagioClinicoProgresso({ estagios = [], total = 0 }) {
  const estagiosDescricao = useSelector(state => state.estado.estagios || []);

  const estagiosFilled = useMemo(
    () => getEstagiosMunicipio(estagios, estagiosDescricao),
    [estagios, estagiosDescricao]
  );

  const estagiosEtapas = useMemo(
    () =>
      estagiosFilled.map(item => (
        <StyledPercentEstagio
          key={item?.id_estagio_clinico}
          porcentagem={getPercent(item?.total, total)}
          cor={item?.cor}
        />
      )),
    [estagiosFilled, total]
  );
  return <div>{estagiosEtapas}</div>;
}
