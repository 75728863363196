import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { useCallback, useState, useEffect, useRef } from 'react';
import SnapPolygonMode from 'utils/Draw/snapModes/SnapPolygonMode';

const initialData = { type: 'FeatureCollection', features: [] };

/**
 *
 * @param {*} shouldDraw define se a ferramenta deve iniciar ativa
 * @param {*} initialMode
 */
export default function useDraw(
  map,
  shouldDraw = true,
  initialMode = 'draw_polygon'
) {
  const drawRef = useRef();
  const [features, setFeatures] = useState([]);

  const changeMode = useCallback(
    (mode, props) => {
      if (drawRef.current) {
        drawRef.current.deleteAll();
        setFeatures([]);
        drawRef.current.changeMode(mode, props);
        if (map.getSource('draw-src'))
          map.getSource('draw-src').setData(initialData);
      }
    },
    [map]
  );

  useEffect(() => {
    if (!shouldDraw) {
      return () => {};
    }
    if (!map) return () => {};

    const draw = new MapboxDraw({
      displayControlsDefault: false,
      userProperties: true,
      defaultMode: initialMode,
      modes: {
        ...MapboxDraw.modes,
        snap_polygon: SnapPolygonMode,
      },
    });

    function onFeatureUpdate({ features: drawFeatures }) {
      setFeatures(drawFeatures);
    }

    map.addControl(draw);
    map.on('draw.create', onFeatureUpdate);
    map.on('draw.update', onFeatureUpdate);

    map.on('load', () => {
      map.U.removeSource('draw-src');
      map.addSource('draw-src', { type: 'geojson', data: initialData });
      map.addLayer({
        id: 'draw-layer',
        type: 'fill',
        source: 'draw-src',
        paint: {
          'fill-color': 'rgba(255, 255, 0, 0.5)',
        },
      });
    });

    drawRef.current = draw;

    return () => {
      setFeatures([]);
      map.removeControl(draw);
      map.U.removeSource('draw-src');
      map.off('draw.create', onFeatureUpdate);
      map.off('draw.update', onFeatureUpdate);
      drawRef.current = undefined;
    };
  }, [initialMode, map, shouldDraw]);

  return [features, changeMode, drawRef];
}
