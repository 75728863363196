import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clear,
  fetchPacienteMae,
  fetchPacientePai,
  fetchPacientePorTipo,
} from 'store/ducks/paciente';
import { BorderedBox } from 'components/Layout';
import { Button } from 'antd';
import {
  save,
  update,
  adicionarRelacionamento,
} from 'services/pacienteService';
import Modals from 'modals';
import { openModal } from 'store/ducks/modal';
import FormCadastro from '../FormPaciente/FormCadastro';
import { formHeight } from './comum';

const fetchPaciente = {
  mae: fetchPacienteMae,
  pai: fetchPacientePai,
};

export function TabPaiMae({ pacienteId, type, label }) {
  const dispatch = useDispatch();
  const paciente = useSelector(state => state.paciente[type]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (type !== undefined) {
      dispatch(fetchPaciente[type](pacienteId));
    }

    return () => {
      dispatch(clear());
    };
  }, [dispatch, pacienteId, type]);

  const handleOpenModalPessoa = useCallback(() => {
    dispatch(openModal(Modals.ModalAddPeople, { tipo: type }));
  }, [dispatch, type]);

  const handleSave = useCallback(
    (idPaciente, formData) => {
      async function request() {
        try {
          setLoading(true);

          const promise = idPaciente
            ? update(idPaciente, formData)
            : save(formData);

          const { data } = await promise;

          if (paciente.naoAssociado) {
            const relacionamento = {
              tipo: type,
              idPaciente: pacienteId,
            };

            await adicionarRelacionamento(data.id, relacionamento);

            await dispatch(fetchPacientePorTipo(idPaciente, type, false));
          }
          setLoading(false);
        } catch (e) {
          console.error(e);
        }
      }
      request();
    },
    [dispatch, paciente.naoAssociado, pacienteId, type]
  );

  const content = useMemo(() => {
    if (Object.keys(paciente).length === 0) {
      return (
        <Button
          type="primary"
          icon="plus"
          size="large"
          onClick={handleOpenModalPessoa}
          ghost
          style={{ marginBottom: 20 }}>
          <strong>{label}</strong>
        </Button>
      );
    }
    return (
      <FormCadastro
        loading={loading}
        height={formHeight}
        paciente={paciente}
        onSave={handleSave}
        tipo="associacao"
      />
    );
  }, [handleOpenModalPessoa, handleSave, label, loading, paciente]);

  return <BorderedBox padding={33}>{content}</BorderedBox>;
}
