import React from 'react';
import { Router } from '@reach/router';
import ListaPacientes from './ListaPacientes';
import FormPaciente from './Page/FormPaciente';
import Tratamentos from './Page/Tratamentos';
import PessoasRelacionadas from './Page/PessoasRelacionadas';

function Pacientes() {
  return (
    <Router>
      <ListaPacientes path="/" />
      <FormPaciente path="novo" />
      <FormPaciente path=":pacienteId/editar" />
      <Tratamentos path=":pacienteId/tratamentos/*" />
      <PessoasRelacionadas path=":pacienteId/pessoas-relacionadas/*" />
    </Router>
  );
}

export default Pacientes;
