import api from './api';

export function getById(id) {
  return api.get(`/unidadesbasica/${id}`);
}

export function findAll(props = {}) {
  const { idMunicipio, internacao } = props;
  const query = [];
  if (idMunicipio) {
    query.push(`idMunicipio=${idMunicipio}`);
  }
  if (internacao) {
    query.push(`internacao=${internacao}`);
  }

  return api.get(
    `/unidadesbasica?${query.length > 0 ? `${query.join('&')}` : ''}`
  );
}

export function updateUnidade(id, body) {
  return api.put(`/unidadesbasica/${id}`, body);
}

export function getGeomUnidades() {
  return api.get('/unidadesbasica/geojson');
}

export function salvarGeomUnidades(body) {
  return api.post('/unidadesbasica/geojson', body);
}

export function findUnidadesByMunicipioDoenca(idMunicipio, idDoenca) {
  return api.get(`/municipios/${idMunicipio}/doencas/${idDoenca}/unidades`);
}

export function getUnidadeBbox(idUnidade) {
  return api.get(`/unidadesbasica/${idUnidade}/bbox`);
}
