import { Icon } from 'antd';
import React from 'react';
import { SectionContent } from '../SectionContent';

export default function SectionDescricao({ etapa }) {
  return (
    <SectionContent
      title="Descrição/Orientação"
      icon={<Icon type="align-left" />}>
      <p>{etapa.descricao}</p>
    </SectionContent>
  );
}
