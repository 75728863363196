const TempoGestacaoEnum = {
  1: '1º Trimestre',
  2: '2º Trimestre',
  3: '3º Trimestre',
  4: 'Idade gestacional Ignorada ',
  5: 'Não',
  6: 'Não se aplica',
  7: 'Ignorado',
};

export default TempoGestacaoEnum;
