import { Col, Row } from 'antd';
import AlergiaSelect from 'components/AlergiaSelect';
import { RadioGroup, Select } from 'components/Form';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change, Field, formValueSelector } from 'redux-form';
import constantes from 'utils/constantes';
import { FORM } from './FORM';

const selector = formValueSelector(FORM);

export default function Gestante() {
  const gestante = useSelector(state => selector(state, 'gestante'));
  const sexo = useSelector(state => selector(state, 'sexo'));

  const dispatch = useDispatch();

  useEffect(() => {
    if (sexo === 'M') {
      dispatch(change(FORM, 'gestante', false));
    }
  }, [dispatch, sexo]);

  useEffect(() => {
    if (!gestante) {
      dispatch(
        change(
          FORM,
          'tempo_gestacao',
          +constantes.TEMPO_GESTACAO.NAO_SE_APLICA.value
        )
      );
    }
  }, [dispatch, gestante]);

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <span>Gestante?</span>
          <br />
          <Field name="gestante" disabled={sexo === 'M'} component={RadioGroup}>
            <RadioGroup.Radio value>Sim</RadioGroup.Radio>
            <RadioGroup.Radio value={false}>Não</RadioGroup.Radio>
          </Field>
        </Col>
        <Col span={12}>
          <Field
            label="Tempo de Gestação"
            name="tempo_gestacao"
            disabled={!gestante}
            component={Select}>
            {Object.keys(constantes.TEMPO_GESTACAO).map(t => {
              return (
                <Select.Option
                  key={t}
                  value={+constantes.TEMPO_GESTACAO[t].value}>
                  {constantes.TEMPO_GESTACAO[t].label}
                </Select.Option>
              );
            })}
          </Field>
        </Col>
      </Row>
      <div>
        <span>Alergia a</span>
        <Field
          type="select-multi"
          name="alergias"
          disabled={!gestante}
          component={({ input, meta, label, ...rest }) => {
            input.selectedTags = input.value === '' ? [] : input.value;
            return <AlergiaSelect {...input} {...rest} />;
          }}
        />
      </div>
    </>
  );
}
