/* eslint-disable new-cap */
import axios from 'axios';

export default class DelayAbortController {
  constructor(time = 500) {
    this.signal = undefined;
    this.time = time;
    this.timer = undefined;
  }

  execute(func) {
    return (...rest) => {
      if (this.signal) {
        this.signal.cancel('Api is being canceled');
      }

      if (this.timer) {
        clearTimeout(this.timer);
      }

      this.signal = axios.CancelToken.source();

      this.timer = setTimeout(() => {
        func(...rest, this.signal.token);
      }, this.time);
    };
  }
}
