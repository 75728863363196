/* eslint-disable no-param-reassign, react/no-this-in-sfc, func-names */
import Constants from '@mapbox/mapbox-gl-draw/src/constants';
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom';
import DrawPolygon from '@mapbox/mapbox-gl-draw/src/modes/draw_polygon';

import {
  findGuidesFromFeatures,
  makeFeature,
  roundLngLatTo1Cm,
  snapAndDrawGuides,
  GEOMETRY_TYPES,
} from './snapUtils';

const SnapPolygonMode = { ...DrawPolygon };

let handleMoveEnd;
let handleUpdate;

let pointFeature;

SnapPolygonMode.onSetup = function({
  onAdd,
  properties = {},
  snapConfig = {},
}) {
  const polygon = this.newFeature(
    makeFeature({
      type: GEOMETRY_TYPES.POLYGON,
      properties,
    })
  );

  pointFeature = makeFeature({
    type: GEOMETRY_TYPES.POINT,
    properties: { point: 'true' },
  });
  const point = this.newFeature(pointFeature);

  this.snapConfig = snapConfig;
  this.addFeature(polygon);
  this.addFeature(point);
  this.clearSelectedFeatures();
  doubleClickZoom.disable(this);

  this.layers = snapConfig.layers;

  const initialGuides = findGuidesFromFeatures({
    map: this.map,
    currentFeature: polygon,
    layers: this.layers,
  });

  // A dog's breakfast
  const state = {
    currentVertexPosition: 0,
    guidesCoord: initialGuides.guidesCoord,
    map: this.map,
    onAdd: onAdd || (() => {}),
    polygon,
    point,
    snapPx: 10,
  };

  handleMoveEnd = () => {
    // Update the guide locations after zoom, pan, rotate, or resize
    const { guidesCoord } = findGuidesFromFeatures({
      map: this.map,
      currentFeature: polygon,
      layers: this.layers,
    });
    state.guidesCoord = guidesCoord;
  };

  this.map.on('moveend', handleMoveEnd);
  this.map.on('draw.update', handleUpdate);
  return state;
};

SnapPolygonMode.onClick = function(state) {
  // We save some processing by rounding on click, not mousemove
  const lng = roundLngLatTo1Cm(state.snappedLng);
  const lat = roundLngLatTo1Cm(state.snappedLat);

  // End the drawing if this click is on the previous position
  if (state.currentVertexPosition > 0) {
    const lastVertex =
      state.polygon.coordinates[0][state.currentVertexPosition - 1];

    if (lastVertex[0] === lng && lastVertex[1] === lat) {
      return this.changeMode(Constants.modes.SIMPLE_SELECT);
    }
  }

  state.polygon.updateCoordinate(`0.${state.currentVertexPosition}`, lng, lat);

  state.currentVertexPosition += 1;

  state.polygon.updateCoordinate(`0.${state.currentVertexPosition}`, lng, lat);

  return null;
};

SnapPolygonMode.onMouseMove = function(state, e) {
  const { lng, lat } = snapAndDrawGuides(state, e);
  state.polygon.updateCoordinate(`0.${state.currentVertexPosition}`, lng, lat);
  state.point.updateCoordinate(state.currentVertexPosition, lng, lat);

  state.snappedLng = lng;
  state.snappedLat = lat;

  this.updateUIClasses({ mouse: Constants.cursors.ADD });
};

// This is 'extending' DrawPolygon.onStop
SnapPolygonMode.onStop = function(state) {
  this.map.off('moveend', handleMoveEnd);
  this.map.off('draw.update', handleUpdate);
  this.deleteFeature(pointFeature.id);

  // This relies on the the state of SnapPolygonMode being similar to DrawPolygon
  DrawPolygon.onStop.call(this, state);

  if (state.polygon && state.polygon.coordinates.length > 2) {
    state.onAdd(state.polygon);
  }
};

export default SnapPolygonMode;
