import { Typography } from 'antd';
import StyledTag from 'components/Tag';
import React from 'react';
import { formatDate } from 'utils/Functions';
import moment from 'moment';

const { Title } = Typography;

export default function HeaderTratamento({ etapa }) {
  return (
    <div className="header-tratamento">
      <div>
        <Title level={4}>{etapa.nome}</Title>
        <div>
          <StyledTag type="data" icon="clock-circle">
            Hoje às 16:00
          </StyledTag>
        </div>
      </div>

      <div className="data-info">
        <div className="data-info__cadastro">
          Tratamento cadastrado em
          <br />
          {formatDate(moment(etapa.created_at || ''))}
        </div>
        <div className="data-info__consulta">
          <strong>CONSULTA EM</strong>
          <span>{formatDate(moment(etapa.data_realizacao || ''))}</span>
        </div>
      </div>
    </div>
  );
}
