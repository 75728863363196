import { Col, Icon, Menu, Row, Input, Typography } from 'antd';
import {
  TableActions,
  TableList,
  DropdownFilter,
  DropdownSorter,
} from 'components/Table';
import React, { useCallback, useEffect, useReducer, useMemo } from 'react';
import StyledTag from 'components/Tag';
import { findAll } from 'services/pacienteService';
import { age } from 'utils/Functions';
import DelayAbortController from 'utils/DelayAbortController';
import TratamentoProgresso from 'components/TratamentoProgresso';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { StyledContentList } from './styled';
import { tagNotificacao, rowNotificacao } from './helpers';
import { Header } from './Header';

const abortController = new DelayAbortController();

const { Title } = Typography;
const { Column } = TableList;

const inititalState = {
  data: [],
  filterEtapas: {
    filterKey: 'todos',
    menuItem: 'Todos',
  },
  sortedInfo: {
    field: 'recente',
    order: 'desc',
    menuItem: 'Atividade recente',
  },
  modalVisible: false,
  filteredInfo: {
    n_cartao_sus: '',
  },
  pagination: { pageSize: 10 },
  loading: true,
};

function reducer(state, action) {
  switch (action.type) {
    case 'showModal':
      return { ...state, modalVisible: true };
    case 'hideModal':
      return { ...state, modalVisible: false };
    case 'setData':
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        pagination: { ...state.pagination, total: action.payload.total },
      };
    case 'updateData':
      return {
        ...state,
        data: action.payload.data,
        pagination: { ...state.pagination, total: action.payload.total },
        loading: false,
      };
    case 'loading': {
      return { ...state, loading: true };
    }
    case 'setFilteredInfo': {
      return { ...state, filteredInfo: action.payload };
    }
    case 'setFilterEtapas': {
      return { ...state, filterEtapas: action.payload };
    }
    case 'changeSortedOrder': {
      return { ...state, sortedInfo: action.payload };
    }

    default:
      throw new Error();
  }
}

const fetch = abortController.execute(
  (pagination, filter, callback, cancelToken) => {
    const { pageSize, current = 1 } = pagination;
    const { n_cartao_sus, nome, cpf, sortedInfo } = filter;
    const { field, order } = sortedInfo;
    findAll(
      {
        offset: pageSize * (current - 1),
        limit: pageSize,
        n_cartao_sus,
        nome,
        cpf,
        field,
        order,
        // TODO adicionar filtro por etapas atrasadas e a serem realizadas
        // filter_etapas: filterEtapas.filterKey,
      },
      { cancelToken }
    )
      .then(result => {
        callback(result.data);
      })
      .catch(() => {});
  }
);

const menuFilters = [
  {
    key: 'todos',
    label: 'Todos',
  },
  {
    key: 'anteriores',
    label: 'Etapas anteriores com falta',
  },
  {
    key: 'seguintes',
    label: 'Etapas seguintes em aberto',
  },
];

function ListaTratamentos({ navigate }) {
  const [state, dispatch] = useReducer(reducer, inititalState);
  const perfilUsuario = useSelector(st => st.usuario.id_perfil);

  const handleSearch = useCallback(
    filterValue => {
      dispatch({
        type: 'setFilteredInfo',
        payload: {
          n_cartao_sus: filterValue,
          nome: filterValue,
          cpf: filterValue,
        },
      });
      fetch(
        inititalState.pagination,
        {
          n_cartao_sus: filterValue,
          nome: filterValue,
          cpf: filterValue,
          sortedInfo: state.sortedInfo,
          filterEtapas: state.filterEtapas,
        },
        ({ data, total }) =>
          dispatch({ type: 'setData', payload: { data, total: +total } })
      );
    },
    [state.filterEtapas, state.sortedInfo]
  );

  function handleTableChange(pagination2) {
    dispatch({ type: 'loading' });
    fetch(pagination2, { sortedInfo: state.sortedInfo }, ({ data, total }) =>
      dispatch({
        type: 'updateData',
        payload: { data, pagination2, total: +total },
      })
    );
  }

  useEffect(() => {
    fetch(
      inititalState.pagination,
      { sortedInfo: state.sortedInfo, filterEtapas: state.filterEtapas },
      ({ data, total }) =>
        dispatch({ type: 'setData', payload: { data, total: +total } })
    );
  }, [state.sortedInfo, state.filterEtapas]);

  const menuSorters = useMemo(
    () => (
      <Menu
        onClick={menu => {
          const keys = menu.key.split(',');
          dispatch({
            type: 'changeSortedOrder',
            payload: {
              field: keys[0],
              order: keys[1],
              menuItem: menu.item.props.children,
            },
          });
        }}>
        <Menu.Item key="nome,asc">Nome A-Z</Menu.Item>
        <Menu.Item key="nome,desc">Nome Z-A</Menu.Item>
        <Menu.Item key="recente,desc">Atividade recente</Menu.Item>
        <Menu.Item key="status,0">Próximas consultas</Menu.Item>
        <Menu.Item key="status,2">Não comparecidas</Menu.Item>
      </Menu>
    ),
    []
  );

  const handleChangeFilter = useCallback(
    (key, label) =>
      dispatch({
        type: 'setFilterEtapas',
        payload: {
          filterKey: key,
          menuItem: label,
        },
      }),
    []
  );

  return (
    <StyledContentList>
      <Header />

      <div className="bordered-box">
        <Title level={4}>Pacientes com Tratamentos</Title>
        <div style={{ width: 380 }}>
          <Input
            className="search-header"
            placeholder="Pesquisar pelo N° Cartão SUS ou CPF ou Nome"
            prefix={
              <Icon type="search" style={{ fontSize: 23, color: '#92929D' }} />
            }
            onChange={e => handleSearch(e.target.value)}
          />
        </div>
        <TableActions>
          <DropdownFilter
            currentItem={state.filterEtapas.menuItem}
            options={menuFilters}
            onSelect={handleChangeFilter}
          />
          <DropdownSorter
            currentItem={state.sortedInfo.menuItem}
            options={menuSorters}
          />
        </TableActions>

        <TableList
          dataSource={state.data}
          className="table-pacientes"
          defaultExpandAllRows
          rowKey="id"
          rowClassName={record =>
            rowNotificacao(record.etapa_notificacao?.status, perfilUsuario)
          }
          pagination={state.pagination}
          onChange={handleTableChange}
          loading={state.loading}
          onRow={record => ({
            onClick: () => {
              navigate(`${record.id}/tratamentos`);
            },
          })}>
          <Column
            title="Nome e Idade"
            key="name"
            render={(_, record) => (
              <Row type="flex" justify="space-between">
                <Col>
                  <strong>{`${record.nome} ${record.sobrenome}`}</strong>
                  <br />
                  <span>{age(record.data_nascimento)}</span>
                </Col>
                <Col>
                  {record.gestante && (
                    <StyledTag type="gestante" text="Gestante" />
                  )}
                </Col>
              </Row>
            )}
          />
          <Column
            title="Anexos"
            dataIndex="anexos"
            key="anexos"
            width={120}
            render={anexos => (
              <>
                <Icon type="paper-clip" />
                {anexos}
              </>
            )}
          />
          <Column
            title="Notificação"
            dataIndex="etapa_notificacao"
            key="etapa_notificacao"
            width={190}
            render={etapa_notificacao => {
              if (etapa_notificacao) {
                const [text, type] = tagNotificacao(
                  etapa_notificacao.status,
                  perfilUsuario
                );
                if (text && type) return <StyledTag type={type} text={text} />;
              }
              return '';
            }}
          />
          <Column
            title="Consulta"
            dataIndex="consulta"
            key="consulta"
            width={210}
            render={(_, record) => {
              const proxima =
                record.proxima_consulta &&
                moment(record.proxima_consulta).diff(moment(), 'days', false);
              const anterior =
                record.consulta_anterior &&
                moment(record.consulta_anterior).diff(moment(), 'days', false);

              return (
                <div>
                  {anterior !== null && anterior <= 0 && (
                    <StyledTag icon="clock-circle" type="data">
                      {anterior === 0
                        ? 'Hoje'
                        : `${Math.abs(anterior)} ${
                            anterior > 1 ? 'dias' : 'dia'
                          } atrás`}
                    </StyledTag>
                  )}
                  {proxima !== null && proxima > 0 && (
                    <StyledTag icon="clock-circle" type="data_pendente">
                      {`Daqui a ${proxima} ${proxima > 1 ? 'dias' : 'dia'}`}
                    </StyledTag>
                  )}
                </div>
              );
            }}
          />
          <Column
            title="Evolução do Tratamento"
            dataIndex="evolucao"
            key="evolucao"
            width={280}
            render={(_, record) => (
              <TratamentoProgresso etapas={record.tratamento_atual} />
            )}
          />
        </TableList>
      </div>
    </StyledContentList>
  );
}

export default ListaTratamentos;
