import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Button, Tooltip } from 'antd';
import { ReactComponent as Icon3D } from 'assets/icons/map/tresd.svg';
import { ReactComponent as IconCalor } from 'assets/icons/map/calor.svg';
import { ReactComponent as IconCluster } from 'assets/icons/map/cluster.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setLayer, showTimeline, setShowTimeline } from 'store/ducks/map';

const StyledActionsContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 60px;
  width: 50px;
  z-index: 1;
`;

const StyledButton = styled(Button).attrs(({ active = false }) => ({
  className: active && 'active',
}))`
  display: flex;
  align-items: center;
  width: 48px;
  height: 48px;
  padding: 0 4px;
  border: 2px solid #d5d5dc;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 5px 5px 11px 0 rgba(16, 56, 121, 0.18);

  &.active {
    border: 3px solid #0062ff;
  }

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  svg {
    width: 100%;
    height: auto;
  }
`;

export function Actions() {
  const dispatch = useDispatch();
  const layer = useSelector(state => state.map.layer);

  const handleChangeLayer = useCallback(
    value => {
      let layerType = value;
      if (value === layer) {
        layerType = 'cluster';
      }

      dispatch(setShowTimeline(layerType === 'heat'));
      dispatch(setLayer(layerType));
    },
    [dispatch, layer]
  );

  return (
    <StyledActionsContainer>
      <Tooltip placement="left" title="Mapa de Cluster">
        <StyledButton
          onClick={() => handleChangeLayer('cluster')}
          active={layer === 'cluster'}>
          <IconCluster />
        </StyledButton>
      </Tooltip>

      <Tooltip placement="left" title="Mapa 3D">
        <StyledButton
          onClick={() => handleChangeLayer('extrusion')}
          active={layer === 'extrusion'}>
          <Icon3D />
        </StyledButton>
      </Tooltip>

      <Tooltip placement="left" title="Mapa de Calor">
        <StyledButton
          onClick={() => handleChangeLayer('heat')}
          active={layer === 'heat'}>
          <IconCalor />
        </StyledButton>
      </Tooltip>
    </StyledActionsContainer>
  );
}
