import React from 'react';
import { RadioGroup } from 'components/RadioGroup';

const opts = [
  { label: 'Sim', value: 1 },
  { label: 'Não', value: 2 },
];

export default function RadioGroupNecessarioRespirador({
  value,
  onChange,
  ...props
}) {
  return (
    <RadioGroup options={opts} value={value} onChange={onChange} {...props} />
  );
}
