import React from 'react';
import styled from 'styled-components';
import CONSTANTES from 'utils/constantes';
import { Icon } from 'antd';

const StyledCircleIcon = styled.div`
  .anticon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    width: 22px;
    height: 22px;
    font-size: 22px;
    border-radius: 22px;
    background-color: #fff;

    &.noicon {
      border: 1px solid rgba(177, 177, 183, 1);
    }
  }
`;

export function CircleIcon({ status = 0 }) {
  return (
    <StyledCircleIcon>
      {status !== CONSTANTES.ETAPA.STATUS.EXECUTADO &&
        status !== CONSTANTES.ETAPA.STATUS.NAO_COMPARECEU && (
          <div className="anticon noicon" />
        )}

      {status === CONSTANTES.ETAPA.STATUS.EXECUTADO && (
        <Icon
          type="check-circle"
          theme="filled"
          style={{ color: 'rgb(61, 213, 152)' }}
        />
      )}

      {status === CONSTANTES.ETAPA.STATUS.NAO_COMPARECEU && (
        <Icon
          type="close-circle"
          theme="filled"
          style={{ color: 'rgb(255,151,74)' }}
        />
      )}
    </StyledCircleIcon>
  );
}
