import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Form,
  Icon,
  Input,
  Menu,
  notification,
  Row,
  Typography,
  Spin,
  Select,
} from 'antd';
import { ButtonTransparent } from 'components/Button';
import { EtapaItem } from 'components/EtapaItem';
import { TableActions } from 'components/Table';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import * as tratamentoService from 'services/tratamentoModeloService';
import * as etapaService from 'services/etapaModeloService';
import * as doencaService from 'services/doencaService';
import TipoEtapaEnum from 'enums/TipoEtapaEnum';
import { DroppableBox, StyledEditContent } from './styled';

const { Search } = Input;
const { Title } = Typography;

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

/**
 * A semi-generic way to handle multiple lists. Matches
 * the IDs of the droppable container to the names of the
 * source arrays stored in the state.
 */
const id2List = {
  droppable: 'items',
  droppable2: 'selected',
};

const initState = {
  items: [],
  selected: [],
};

function FormTratamento({ form, tratamentoId, navigate }) {
  const [loading, setLoading] = useState(true);
  const { getFieldDecorator, validateFields } = form;
  const [tratamento, setTratamento] = useState({});
  const [state, setState] = useState(initState);
  const [loadingButton, setLoadingButton] = useState(false);
  const [filteredInfo, setFilteredInfo] = useState({
    filterText: '',
    filterKey: 'TODOS',
    menuItem: 'Todos tipos de etapas',
    items: [],
  });
  const [doencas, setDoencas] = useState([]);

  // FIXME: Ao mover para outra coluna, pega o index errado
  const itemsFiltered = state.items.filter(it => {
    if (it.nome.toUpperCase().includes(filteredInfo.filterText.toUpperCase())) {
      if (filteredInfo.filterKey === 'TODOS') {
        return true;
      }
      if (it.tipo === filteredInfo.filterKey) {
        return true;
      }
    }
    return false;
  });

  const menuFilters = useMemo(
    () => (
      <Menu
        onClick={menu =>
          setFilteredInfo(prevFiltered => ({
            ...prevFiltered,
            filterKey: menu.key,
            menuItem: menu.item.props.children,
          }))
        }>
        <Menu.Item key="TODOS">Todos tipos de etapas</Menu.Item>
        <Menu.Item key="EXAME">Exame</Menu.Item>
        <Menu.Item key="MEDICAMENTO">Medicamento</Menu.Item>
      </Menu>
    ),
    []
  );

  const handleSave = useCallback(
    values => {
      const service = tratamentoId
        ? tratamentoService.update(tratamentoId, values)
        : tratamentoService.save(values);
      service
        .then(() => {
          navigate('/tratamentos');
        })
        .catch(err => {
          setLoadingButton(false);
          console.log(err);
        });
    },
    [navigate, tratamentoId]
  );

  useEffect(() => {
    doencaService.findAll().then(r => setDoencas(r.data));
  }, []);

  // busca todas etapas
  useEffect(() => {
    const promises = [etapaService.findAll({ ativa: true })];
    if (tratamentoId) promises.push(tratamentoService.findById(tratamentoId));

    Promise.all(promises)
      .then(response => {
        // necessário adicionar um key novo objeto para poder duplicar as etapas
        const items = response[0].data.map(it => {
          const newIt = it;
          newIt.key = it.id;
          return newIt;
        });

        if (tratamentoId) {
          const tratamentoResponse = response[1].data;
          // const itemsTratamento = items.filter((item) => tratamentoResponse.etapas.find((i) => i._id === item._id));

          // necessário adicionar um key novo objeto para poder duplicar as etapas
          const selected = tratamentoResponse.etapas.map((it, index) => {
            const newIt = it;
            newIt.key = it.id + String(index);
            return newIt;
          });

          setTratamento(response[1].data);
          const newItems = items.filter(
            item => !selected.find(i => i.id === item.id)
          );
          setState(prevState => ({ ...prevState, selected, items: newItems }));
        } else {
          setState(prevState => ({ ...prevState, items }));
        }

        setLoading(false);
      })
      .catch(e => {
        console.log(e);
        notification.error({
          message: 'Ocorreu um erro ao listar as etapas de tratamentos.',
        });
      });
  }, [tratamentoId]);

  const getList = useCallback(id => state[id2List[id]], [state]);

  const removeEtapa = useCallback(
    idxEtapa => {
      const destination = { droppableId: 'droppable', index: 0 };
      const source = { droppableId: 'droppable2', index: idxEtapa };

      const sourceItem = state.selected[idxEtapa];

      const length = state.selected.filter(item => item.id === sourceItem.id)
        .length;

      const moved = move(state.selected, state.items, source, destination);

      setState(prevState => ({
        items: length > 1 ? prevState.items : moved.droppable,
        selected: moved.droppable2,
      }));
    },
    [state.items, state.selected]
  );

  // a little function to help us with duplicate the item
  const duplicateEtapa = useCallback(
    idxItem => {
      const droppable2 = state.selected;
      const item2Duplicate = { ...droppable2[idxItem], key: Date.now() };
      droppable2.splice(idxItem + 1, 0, item2Duplicate);
      setState(prevState => ({
        ...prevState,
        selected: droppable2,
      }));
      // return result;
    },
    [state.selected]
  );

  const onDragEnd = useCallback(
    result => {
      const { source, destination } = result;

      // dropped outside the list
      if (!destination) {
        return;
      }

      if (source.droppableId === destination.droppableId) {
        const items = reorder(
          getList(source.droppableId),
          source.index,
          destination.index
        );

        let newState = { items };

        if (source.droppableId === 'droppable2') {
          newState = { selected: items };
        }

        setState(prevState => ({ ...prevState, ...newState }));
      } else {
        const moved = move(
          getList(source.droppableId),
          getList(destination.droppableId),
          source,
          destination
        );

        setState({
          items: moved.droppable,
          selected: moved.droppable2,
        });
      }
    },
    [getList]
  );

  const handleSubmit = useCallback(
    event => {
      event.preventDefault();
      validateFields((err, values) => {
        if (!err) {
          setLoadingButton(true);
          const data = {
            ...values,
            etapas: state.selected.map(item => item.id),
          };
          handleSave(data);
        }
      });
    },
    [handleSave, state.selected, validateFields]
  );

  return (
    <StyledEditContent>
      <Title level={3}>
        {tratamentoId ? 'Editar Tratamento' : 'Novo Tratamento'}
      </Title>
      {loading ? (
        <div className="spin-wrapper">
          <Spin />
        </div>
      ) : (
        <div className="bordered-box">
          <Form onSubmit={handleSubmit}>
            <Row gutter={32}>
              <Col span={8}>
                <Form.Item>
                  {getFieldDecorator('nome', {
                    rules: [
                      {
                        required: true,
                        message: 'Por favor digite o nome do tratamento',
                      },
                    ],
                    initialValue: tratamento.nome || '',
                  })(<Input size="large" placeholder="Nome do Tratamento" />)}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item>
                  {getFieldDecorator('id_doenca', {
                    rules: [
                      {
                        required: true,
                        message: 'Por favor selecione a doença',
                      },
                    ],
                    initialValue: tratamento.id_doenca,
                  })(
                    <Select size="large" placeholder="Doença">
                      {doencas.map(d => (
                        <Select.Option key={d.id} value={d.id}>
                          {d.nome}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item>
                  {getFieldDecorator('tratamento_atual', {
                    valuePropName: 'checked',
                    initialValue: tratamento.tratamento_atual || false,
                  })(<Checkbox>Tornar tratamento atual</Checkbox>)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={64} className="etapas-box">
              <DragDropContext onDragEnd={onDragEnd}>
                <Col span={12} className="etapas-col-1">
                  <Title level={4}>Montar tratamento</Title>
                  <Form.Item>
                    <Search
                      size="large"
                      placeholder="Pesquise por uma etapa"
                      onSearch={(value, e) => {
                        e.preventDefault();
                        setFilteredInfo(prevFilter => ({
                          ...prevFilter,
                          filterText: value,
                        }));
                      }}
                    />
                  </Form.Item>
                  <TableActions>
                    <div>
                      <strong>
                        <span style={{ color: '#0062FF', marginRight: 5 }}>
                          {itemsFiltered.length}
                        </span>
                        etapas
                      </strong>
                    </div>

                    <Dropdown
                      className="box-filters"
                      overlay={menuFilters}
                      placement="bottomRight"
                      trigger={['click']}>
                      <div>
                        <span>Filtrar Por: </span>
                        <strong>{filteredInfo.menuItem}</strong>
                        <Icon type="caret-down" />
                      </div>
                    </Dropdown>
                  </TableActions>
                  <Droppable droppableId="droppable">
                    {provided => (
                      <div ref={provided.innerRef}>
                        {itemsFiltered.map((item, index) => (
                          <Draggable
                            key={item.key}
                            draggableId={item.key}
                            index={index}>
                            {(providedItem, snapshotItem) => (
                              <EtapaItem
                                type={item.tipo}
                                isDragging={snapshotItem.isDragging}
                                ref={providedItem.innerRef}
                                {...providedItem.draggableProps}
                                {...providedItem.dragHandleProps}
                                style={{
                                  ...providedItem.draggableProps.style,
                                }}>
                                <strong>{item.nome}</strong>
                                <span>{TipoEtapaEnum[item.tipo]}</span>
                              </EtapaItem>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </Col>
                <Col span={12} className="etapas-col-2">
                  <Droppable droppableId="droppable2">
                    {(provided, snapshot) => (
                      <DroppableBox
                        ref={provided.innerRef}
                        isDraggingOver={snapshot.isDraggingOver}>
                        <span className="legend-box">
                          Clique e arraste uma etapa da coluna ao lado para esta
                          caixa para organizar as etapas do tratamento.
                        </span>
                        {state.selected.map((item, index) => (
                          <Draggable
                            key={item.key}
                            draggableId={item.key}
                            index={index}>
                            {(providedItem, snapshotItem) => (
                              <div
                                ref={providedItem.innerRef}
                                {...providedItem.draggableProps}
                                {...providedItem.dragHandleProps}
                                style={{ ...providedItem.draggableProps.style }}
                                className="etapa-drag">
                                <Icon type="drag" />
                                <EtapaItem
                                  type={item.tipo}
                                  isDragging={snapshotItem.isDragging}
                                  noMargin>
                                  <div>
                                    <strong>{item.nome}</strong>
                                  </div>

                                  <div className="btns">
                                    <ButtonTransparent
                                      icon="copy"
                                      onClick={() => duplicateEtapa(index)}
                                    />
                                    <ButtonTransparent
                                      icon="delete"
                                      onClick={() => removeEtapa(index)}
                                    />
                                  </div>
                                </EtapaItem>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </DroppableBox>
                    )}
                  </Droppable>
                </Col>
              </DragDropContext>
            </Row>
            <Row type="flex" justify="end">
              <Col span={6}>
                <ButtonTransparent
                  size="small"
                  onClick={() => navigate('/tratamentos')}>
                  Cancelar
                </ButtonTransparent>
                <Button
                  loading={loadingButton}
                  type="primary"
                  htmlType="submit">
                  {tratamentoId ? 'Atualizar' : 'Salvar'}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </StyledEditContent>
  );
}

export default Form.create({ name: 'edit' })(FormTratamento);
