import api from './api';

export function listEtapas(idTratamento) {
  return api.get(`/tratamentos/${idTratamento}/etapas`);
}

export function listEstagios(idTratamento) {
  return api.get(`/tratamentos/${idTratamento}/estagios`);
}

export function findEtapaNotificacao(idTratamento) {
  return api.get(`/tratamentos/${idTratamento}/etapa/notificacao`);
}

export function findEstagioTratamento(idTratamento) {
  return api.get(`/tratamentos/${idTratamento}/estagio`);
}

export function novoTratamento(body) {
  return api.post('/tratamentos', body);
}

export function finalizarTratamento(id) {
  return api.put(`/tratamentos/${id}/finalizar`);
}
