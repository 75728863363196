/* eslint-disable no-use-before-define */
import React, { useEffect, useRef } from 'react';
import useFetch from 'hooks/useFetch';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import throttle from 'lodash.throttle';

import { Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { setLayer, setDate, setEstagio } from 'store/ducks/map';
import moment from 'moment';

am4core.useTheme(am4themes_animated);

function TimelineChart({ data: covid_total_timeline }) {
  const ref = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    // Themes end
    const container = am4core.create('chartdiv', am4core.Container);
    container.logo.height = -15;

    am4core.ready(() => {
      const backgroundColor = am4core.color('#ffffff');
      const activeColor = am4core.color('#FFD422');
      const totalColor = am4core.color('#ff8726');
      const recuperadosColor = am4core.color('#3DD598');
      const mortesColor = am4core.color('#000000');
      const suspeitosColor = am4core.color('#f77474');

      // for an easier access by key
      const colors = {
        confirmados: activeColor,
        total: totalColor,
        recuperados: recuperadosColor,
        mortes: mortesColor,
        suspeitos: suspeitosColor,
      };

      const buttonStrokeColor = am4core.color('#ccc');

      let currentIndex;

      // last date of the data
      const lastDate = moment(
        covid_total_timeline[covid_total_timeline.length - 1]?.date
      ).toDate();

      let sliderAnimation;
      // //////////////////////////////////////
      // PREPARE DATA
      // //////////////////////////////////////

      // calculated active cases in world data
      // (confirmados = total - recuperados)
      for (let i = 0; i < covid_total_timeline.length; i++) {
        const di = covid_total_timeline[i];
        di.confirmados = di.total - di.recuperados - di.mortes;
      }

      // END OF DATA

      // //////////////////////////////////////
      // LAYOUT & CHARTS
      // //////////////////////////////////////

      // main container
      // https://www.amcharts.com/docs/v4/concepts/svg-engine/containers/
      container.width = am4core.percent(100);
      container.height = am4core.percent(100);

      // buttons & chart container
      const buttonsAndChartContainer = container.createChild(am4core.Container);
      buttonsAndChartContainer.layout = 'vertical';
      // make this bigger if you want more space for the chart
      buttonsAndChartContainer.height = am4core.percent(100);
      buttonsAndChartContainer.width = am4core.percent(100);
      buttonsAndChartContainer.valign = 'bottom';

      // country name and buttons container
      const nameAndButtonsContainer = buttonsAndChartContainer.createChild(
        am4core.Container
      );
      nameAndButtonsContainer.width = am4core.percent(100);
      nameAndButtonsContainer.padding(0, 10, 5, 20);
      nameAndButtonsContainer.layout = 'horizontal';

      // name of a country and date label
      const countryName = nameAndButtonsContainer.createChild(am4core.Label);
      countryName.fontSize = '1.1em';
      countryName.valign = 'middle';

      // buttons container (active/total/recuperados/mortes)
      const buttonsContainer = nameAndButtonsContainer.createChild(
        am4core.Container
      );
      buttonsContainer.layout = 'grid';
      buttonsContainer.width = am4core.percent(100);
      buttonsContainer.x = 10;
      buttonsContainer.contentAlign = 'left';

      // Chart & slider container
      const chartAndSliderContainer = buttonsAndChartContainer.createChild(
        am4core.Container
      );
      chartAndSliderContainer.layout = 'vertical';
      chartAndSliderContainer.height = am4core.percent(100);
      chartAndSliderContainer.width = am4core.percent(100);
      chartAndSliderContainer.background.fill = am4core.color('#ffffff');
      chartAndSliderContainer.background = new am4core.RoundedRectangle();
      chartAndSliderContainer.background.cornerRadius(30, 30, 0, 0);
      chartAndSliderContainer.background.fillOpacity = 0.75;
      chartAndSliderContainer.paddingTop = 15;
      chartAndSliderContainer.paddingBottom = 0;

      const lineChart = chartAndSliderContainer.createChild(am4charts.XYChart);
      const sliderContainer = chartAndSliderContainer.createChild(
        am4core.Container
      );

      // Slider container
      sliderContainer.width = am4core.percent(100);
      sliderContainer.padding(0, 15, 15, 15);
      sliderContainer.layout = 'horizontal';

      const slider = sliderContainer.createChild(am4core.Slider);
      slider.width = am4core.percent(100);
      slider.valign = 'middle';
      slider.background.opacity = 0.4;
      slider.opacity = 0.7;
      slider.background.fill = am4core.color('#000000');
      slider.marginLeft = 20;
      slider.marginRight = 35;
      slider.height = 15;
      slider.start = 1;

      const changeSlider = throttle(date => {
        dispatch(setDate(date));
      }, 500);

      // what to do when slider is dragged
      slider.events.on('rangechanged', () => {
        const index = Math.round(
          (covid_total_timeline.length - 1) * slider.start
        );

        const time = covid_total_timeline[index];
        if (time) {
          changeSlider(time?.date);
        }

        updateTotals(slider.start);
      });
      // stop animation if dragged
      slider.startGrip.events.on('drag', () => {
        stop();
        if (sliderAnimation) {
          sliderAnimation.setProgress(slider.start);
        }
      });

      // play button
      const playButton = sliderContainer.createChild(am4core.PlayButton);
      playButton.valign = 'middle';
      // play button behavior
      playButton.events.on('toggled', event => {
        if (event.target.isActive) {
          dispatch(setLayer('heat'));
          play();
        } else {
          dispatch(setLayer('cluster'));
          stop();
        }
      });
      // make slider grip look like play button
      slider.startGrip.background.fill = playButton.background.fill;
      slider.startGrip.background.strokeOpacity = 0;
      slider.startGrip.icon.stroke = am4core.color('#ffffff');
      slider.startGrip.background.states.copyFrom(playButton.background.states);

      // play behavior
      function play() {
        if (!sliderAnimation) {
          sliderAnimation = slider
            .animate(
              { property: 'start', to: 1, from: 0 },
              1500 * covid_total_timeline.length,
              am4core.ease.linear
            )
            .pause();
          sliderAnimation.events.on('animationended', () => {
            playButton.isActive = false;
          });
        }

        if (slider.start >= 1) {
          slider.start = 0;
          sliderAnimation.start();
        }
        sliderAnimation.resume();
        playButton.isActive = true;
      }

      // stop behavior
      function stop() {
        if (sliderAnimation) {
          sliderAnimation.pause();
        }
        playButton.isActive = false;
      }

      // TOP CHART
      // https://www.amcharts.com/docs/v4/chart-types/xy-chart/
      lineChart.fontSize = '0.8em';
      lineChart.paddingRight = 30;
      lineChart.paddingLeft = 30;
      lineChart.maskBullets = false;
      lineChart.zoomOutButton.disabled = true;
      lineChart.paddingBottom = 7;

      // make a copy of data as we will be modifying it
      lineChart.data = JSON.parse(JSON.stringify(covid_total_timeline));

      // date axis
      // https://www.amcharts.com/docs/v4/concepts/axes/date-axis/
      const dateAxis = lineChart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.minGridDistance = 50;
      dateAxis.renderer.grid.template.stroke = am4core.color('#000000');
      dateAxis.max = lastDate.getTime();
      dateAxis.tooltip.label.fontSize = '0.8em';
      dateAxis.tooltip.background.fill = activeColor;
      dateAxis.tooltip.background.stroke = activeColor;

      // value axis
      // https://www.amcharts.com/docs/v4/concepts/axes/value-axis/
      const valueAxis = lineChart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.interpolationDuration = 3000;
      valueAxis.renderer.grid.template.stroke = am4core.color('#000000');
      valueAxis.renderer.baseGrid.disabled = true;
      valueAxis.tooltip.disabled = true;
      valueAxis.extraMax = 0.05;
      valueAxis.renderer.inside = true;
      valueAxis.renderer.labels.template.verticalCenter = 'bottom';
      valueAxis.renderer.labels.template.padding(2, 2, 2, 2);
      valueAxis.min = 0;

      // cursor
      // https://www.amcharts.com/docs/v4/concepts/chart-cursor/
      lineChart.cursor = new am4charts.XYCursor();
      lineChart.cursor.behavior = 'none'; // set zoomX for a zooming possibility
      lineChart.cursor.lineY.disabled = true;
      lineChart.cursor.lineX.stroke = activeColor;
      lineChart.cursor.xAxis = dateAxis;
      // this prevents cursor to move to
      // the clicked location while map is dragged
      am4core
        .getInteraction()
        .body.events.off(
          'down',
          lineChart.cursor.handleCursorDown,
          lineChart.cursor
        );
      am4core
        .getInteraction()
        .body.events.off(
          'up',
          lineChart.cursor.handleCursorUp,
          lineChart.cursor
        );

      // legend
      // https://www.amcharts.com/docs/v4/concepts/legend/
      lineChart.legend = new am4charts.Legend();
      lineChart.legend.parent = lineChart.plotContainer;

      // create series
      const totalSeries = addSeries('total', totalColor);
      const activeSeries = addSeries('confirmados', activeColor);
      const recuperadosSeries = addSeries('recuperados', recuperadosColor);
      const mortesSeries = addSeries('mortes', mortesColor);
      const suspeitosSeries = addSeries('suspeitos', suspeitosColor);
      // totalSeries.tooltip.disabled = true;
      // totalSeries.hidden = false;

      const series = {
        confirmados: activeSeries,
        total: totalSeries,
        recuperados: recuperadosSeries,
        mortes: mortesSeries,
        suspeitos: suspeitosSeries,
      };
      // add series
      function addSeries(name, color) {
        const _series = lineChart.series.push(new am4charts.LineSeries());
        _series.dataFields.valueY = name;
        _series.dataFields.dateX = 'date';
        _series.name = capitalizeFirstLetter(name);
        _series.strokeOpacity = 0.6;
        _series.stroke = color;
        _series.maskBullets = false;
        _series.minBulletDistance = 10;
        _series.hidden = true;
        _series.hideTooltipWhileZooming = true;
        // series bullet
        const bullet = _series.bullets.push(new am4charts.CircleBullet());

        bullet.setStateOnChildren = true;

        bullet.circle.fillOpacity = 1;
        bullet.circle.fill = backgroundColor;
        bullet.circle.radius = 2;

        const circleHoverState = bullet.circle.states.create('hover');
        circleHoverState.properties.fillOpacity = 1;
        circleHoverState.properties.fill = color;
        circleHoverState.properties.scale = 1.4;

        // tooltip setup
        _series.tooltip.pointerOrientation = 'down';
        _series.tooltip.getStrokeFromObject = true;
        _series.tooltip.getFillFromObject = false;
        _series.tooltip.background.fillOpacity = 0.2;
        _series.tooltip.background.fill = am4core.color('#888888');
        _series.tooltip.dy = -4;
        _series.tooltip.fontSize = '0.8em';
        _series.tooltipText = '{valueY}';

        return _series;
      }

      // BUTTONS
      // create buttons
      const totalButton = addButton('total', totalColor);
      const activeButton = addButton('confirmados', activeColor);
      const recuperadosButton = addButton('recuperados', recuperadosColor);
      const mortesButton = addButton('mortes', mortesColor);
      const suspeitosButton = addButton('suspeitos', suspeitosColor);

      const buttons = {
        confirmados: activeButton,
        total: totalButton,
        recuperados: recuperadosButton,
        mortes: mortesButton,
        suspeitos: suspeitosButton,
      };

      // add button
      function addButton(name, color) {
        const button = buttonsContainer.createChild(am4core.Button);
        button.label.valign = 'middle';
        button.fontSize = '0.80em';
        button.background.cornerRadius(30, 30, 30, 30);
        button.background.strokeOpacity = 0.8;
        button.background.fillOpacity = 0;
        button.background.stroke = buttonStrokeColor;
        button.background.padding(2, 3, 2, 3);
        button.states.create('active');
        button.setStateOnChildren = true;

        const activeHoverState = button.background.states.create('hoverActive');
        activeHoverState.properties.fillOpacity = 0;

        const circle = new am4core.Circle();
        circle.radius = 8;
        circle.fillOpacity = 0.3;
        circle.fill = buttonStrokeColor;
        circle.strokeOpacity = 0;
        circle.valign = 'middle';
        circle.marginRight = 5;
        button.icon = circle;

        // save name to dummy data for later use
        button.dummyData = name;

        const circleActiveState = circle.states.create('active');
        circleActiveState.properties.fill = color;
        circleActiveState.properties.fillOpacity = 0.5;

        button.events.on('hit', handleButtonClick);

        return button;
      }

      // handle button clikc
      function handleButtonClick(event) {
        // we saved name to dummy data
        dispatch(setEstagio(event.target.dummyData));
        changeDataType(event.target.dummyData);
      }

      // change data type (active/total/recuperados/mortes)
      function changeDataType(name) {
        // make button active
        const _activeButton = buttons[name];
        _activeButton.isActive = true;
        // make other buttons inactive
        Object.keys(buttons).forEach(key => {
          if (buttons[key] !== _activeButton) {
            buttons[key].isActive = false;
          }
        });
        // tell series new field name

        dateAxis.tooltip.background.fill = colors[name];
        dateAxis.tooltip.background.stroke = colors[name];
        lineChart.cursor.lineX.stroke = colors[name];

        // show series
        const _activeSeries = series[name];
        _activeSeries.show();
        // hide other series
        Object.keys(series).forEach(key => {
          if (series[key] !== _activeSeries) {
            series[key].hide();
          }
        });
      }

      function updateSeriesTooltip() {
        lineChart.cursor.triggerMove(lineChart.cursor.point, 'soft', true);
        lineChart.series.each(__series => {
          const _series = __series;
          if (!_series.isHidden) {
            _series.tooltip.disabled = false;
            _series.showTooltipAtDataItem(series.tooltipDataItem);
          }
        });
      }

      // update total values in buttons
      function updateTotals(start) {
        const index = Math.round((covid_total_timeline.length - 1) * start);
        if (!isNaN(index)) {
          const x = dateAxis.positionToCoordinate(start);

          if (lineChart.cursor) {
            lineChart.cursor.triggerMove({ x, y: 0 }, 'soft', true);
          }
          Object.keys(buttons).forEach(key => {
            buttons[key].label.text = `${capitalizeFirstLetter(
              key
            )}: ${lineChart.data[index]?.[key] ?? 0}`;
          });
          currentIndex = index;
        }
      }

      // capitalize first letter
      function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }

      container.events.on('layoutvalidated', () => {
        dateAxis.tooltip.hide();
        lineChart.cursor.hide();
        updateTotals(currentIndex);
      });

      // set initial data and names
      changeDataType('total');
      setTimeout(updateSeriesTooltip, 100);
    });
    return () => {
      if (container) {
        container.dispose();
      }
    };
  }, [covid_total_timeline, dispatch]);

  return (
    <div id="chartdiv" ref={ref} style={{ width: '100%', height: '350px' }} />
  );
}

export default function Timeline({
  municipioSelecionado,
  estadoSelecionado,
  doencaSelecionada,
}) {
  const query = municipioSelecionado
    ? `?idMunicipio=${municipioSelecionado}`
    : `?idUf=${estadoSelecionado}`;

  const [loading, data] = useFetch(
    `/graficos/doencas/${doencaSelecionada}/timeline${query}`
  );

  if (loading) return <Spin />;

  return (
    <TimelineChart
      data={
        data.length > 0
          ? data
          : [
            {
              date: new Date(),
              suspeitos: 0,
              total: 0,
              recuperados: 0,
              descartados: 0,
              mortes: 0,
            },
          ]
      }
      dateX="dia"
      valueY="total"
    />
  );
}
