import React, { useReducer, useEffect, useMemo } from 'react';
import { List, Spin, Row, Col } from 'antd';
import CardHoverable from 'components/CardHoverable';
import { findUnidadesByMunicipioDoenca } from 'services/unidadeBasicaService';
import { filterWords } from 'utils/Functions';
import { StyledListItem, StyledHeaderSider } from '../../styled';
import { StyledCollapse } from './styled';
import { HospitalItem } from './HospitalItem';
import { PacienteItem } from '../PacienteItem';

const { Panel } = StyledCollapse;

const initialState = {
  data: [],
  loading: true,
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_DATA':
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    case 'CLEAR':
      return { ...initialState };
    default:
      throw Error();
  }
}

export default function ListaUnidades({ idMunicipio, filter }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { data, loading } = state;

  useEffect(() => {
    if (idMunicipio) {
      findUnidadesByMunicipioDoenca(idMunicipio, 2).then(result =>
        dispatch({
          type: 'SET_DATA',
          payload: { ...result },
        })
      );
    }
  }, [idMunicipio]);

  const filteredData = useMemo(() => {
    if (filter !== '') {
      return data?.map(uni => {
        let pac = uni.pacientes;
        if (filter !== '') {
          pac = uni.pacientes?.filter(
            p =>
              filterWords(filter, p.nome || '') ||
              filterWords(filter, p.sobrenome || '')
          );
        }
        return { ...uni, p: pac };
      });
    }
    return data;
  }, [data, filter]);

  if (loading)
    return (
      <StyledHeaderSider>
        <Spin />
      </StyledHeaderSider>
    );

  return (
    <List
      loading={loading}
      itemLayout="horizontal"
      dataSource={filteredData}
      renderItem={hospital => (
        <li>
          <StyledListItem>
            <StyledCollapse expandIconPosition="right">
              <Panel header={<HospitalItem hospital={hospital} />}>
                <Row gutter={[0, 8]}>
                  {hospital.pacientes.map(paciente => (
                    <Col>
                      <CardHoverable height={73} key={paciente.id}>
                        <PacienteItem paciente={paciente} />
                      </CardHoverable>
                    </Col>
                  ))}
                </Row>
              </Panel>
            </StyledCollapse>
          </StyledListItem>
        </li>
      )}
    />
  );
}
