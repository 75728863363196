import styled from 'styled-components';

export const ProfilePicture = styled.div`
  position: absolute;
  top: 15px;
  right: -228px;
  width: 209px;
  padding: 16px;
  border-radius: 10px;
  background-color: #ffffff;
  z-index: 1;
  box-shadow: 3px 3px 10px 0 rgba(0, 98, 255, 0.09);

  .upload-picture {
    position: relative;
    margin-bottom: 16px;

    .ant-btn {
      position: absolute;
      right: 5px;
      bottom: 17px;
      z-index: 1;
      border-radius: 6px;
    }
  }

  img {
    width: 177px;
    height: auto;
    border-radius: 5px;
  }

  .nome {
    font-size: 14px;
    font-weight: 500;
  }

  .idade {
    font-size: 12px;
    font-weight: 500;
    color: #92929d;
  }

  .tags {
    margin-top: 4px;
  }
`;
