const EscolaridadeEnum = {
  0: 'Analfabeto ',
  1: '1ª a 4ª série incompleta do EF (antigo primário ou 1º grau) ',
  2: '4ª série completa do EF (antigo primário ou 1º grau)',
  3: '5ª à 8ª série incompleta do EF (antigo ginásio ou 1º grau)',
  4: 'Ensino fundamental completo (antigo ginásio ou 1º grau) ',
  5: 'Ensino médio incompleto (antigo colegial ou 2º grau )',
  6: 'Ensino médio completo (antigo colegial ou 2º grau ) ',
  7: 'Educação superior incompleta ',
  8: 'Educação superior completa ',
  9: 'Ignorado ',
  10: ' Não se aplica',
};

export default EscolaridadeEnum;
