import React, { useState, useEffect, useCallback } from 'react';
import { Select, Tag, notification, Typography, Button } from 'antd';
import useFetch from 'hooks/useFetch';
import { useDispatch } from 'react-redux';
import { closeModal } from 'store/ducks/modal';
import { novoTratamento } from 'services/tratamentoService';
import {
  fetchDoencas,
  setActiveDoencaKey,
  fetchTratamentos,
} from 'store/ducks/paciente';
import { BigModal, ModalActions } from 'components/BigModal';
import { ItemLabel } from 'components/Form';
import { ButtonTransparent } from 'components/Button';
import { SelectWithTag } from './styled';

const { Title } = Typography;

export default function ModalNovoTratamento({ visible, pacienteId }) {
  const dispatch = useDispatch();
  const [doencaSelecionada, setDoencaSelecionada] = useState();
  const [tratamentoSelecionado, setTratamentoSelecionado] = useState();
  const [loadingDoencas, doencas, fetchAllDoencas] = useFetch(
    '/doencas',
    [],
    false
  );

  const [loadingTratamentos, tratamentos, refetchTratamentos] = useFetch(
    `/doencas/${doencaSelecionada}/tratamentos-modelo`,
    [],
    false
  );

  useEffect(() => {
    if (visible) {
      fetchAllDoencas();
    }
    return () => {
      setDoencaSelecionada(null);
      setTratamentoSelecionado(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (visible && doencaSelecionada) {
      refetchTratamentos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doencaSelecionada, visible]);

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  useEffect(() => {
    setTratamentoSelecionado(tratamentos.find(t => t.tratamento_atual)?.id);
  }, [setTratamentoSelecionado, tratamentos]);

  const handleOkModal = useCallback(() => {
    novoTratamento({
      id_tratamento: tratamentoSelecionado,
      id_paciente: pacienteId,
      id_doenca: doencaSelecionada,
    })
      .then(({ data }) => {
        if (data.id) {
          notification.success({
            message: 'Sucesso',
            description: 'Novo tratamento iniciado',
          });
          dispatch(fetchDoencas(pacienteId)).then(() => {
            dispatch(fetchTratamentos(pacienteId, doencaSelecionada));
          });
          dispatch(setActiveDoencaKey(doencaSelecionada));
        }
      })
      .catch(err => {
        if (err?.response?.data?.message === 'tratamento existente') {
          notification.warn({
            message: 'Atenção',
            description:
              'Já existe um tratamento em andamento para esta doença, é necessário finaliza-lo antes de iniciar um novo tratamento',
          });
        }
      });
    handleCloseModal();
  }, [
    dispatch,
    doencaSelecionada,
    handleCloseModal,
    pacienteId,
    tratamentoSelecionado,
  ]);

  return (
    <BigModal visible={visible} onCancel={handleCloseModal} footer={null}>
      <Title level={4}>Iniciar novo tratamento</Title>
      <ItemLabel label="Selecione a doença" required>
        <Select
          size="large"
          loading={loadingDoencas}
          style={{ width: '100%' }}
          onSelect={setDoencaSelecionada}
          value={doencaSelecionada}>
          {doencas.map(d => (
            <Select.Option key={d.id} value={d.id}>
              {d.nome}
            </Select.Option>
          ))}
        </Select>
      </ItemLabel>
      <ItemLabel label="Selecione o tratamento" required>
        <SelectWithTag
          size="large"
          disabled={!doencaSelecionada}
          loading={loadingTratamentos}
          style={{ width: '100%' }}
          onSelect={setTratamentoSelecionado}
          value={tratamentoSelecionado}>
          {tratamentos.map(t => (
            <Select.Option key={t.id} value={t.id}>
              {t.nome}
              {t.tratamento_atual && (
                <Tag
                  color="#108ee9"
                  size="large"
                  style={{ position: 'absolute', right: 0 }}>
                  Tratamento Atual
                </Tag>
              )}
            </Select.Option>
          ))}
        </SelectWithTag>
      </ItemLabel>
      <ModalActions justify="middle">
        <ButtonTransparent size="small" onClick={handleCloseModal}>
          Cancelar
        </ButtonTransparent>
        <Button
          type="primary"
          size="large"
          onClick={handleOkModal}
          disabled={!tratamentoSelecionado}>
          <strong>Iniciar tratamento</strong>
        </Button>
      </ModalActions>
    </BigModal>
  );
}
