import React, { useCallback } from 'react';
import { StyledRadioGroup } from './styled';
import RadioOption from './RadioOption';

function RadioGroupComponent({
  value = undefined,
  options = [],
  disabled = false,
  onChange = () => {},
}) {
  const handleChange = useCallback(
    opt => {
      if (!disabled) onChange(opt);
    },
    [disabled, onChange]
  );

  return (
    <StyledRadioGroup disabled={disabled}>
      {options.map(opt => (
        <RadioOption
          key={opt.value}
          onClick={() => handleChange(opt)}
          checked={value === opt.value}>
          {opt.label}
        </RadioOption>
      ))}
    </StyledRadioGroup>
  );
}

export default React.memo(RadioGroupComponent);
