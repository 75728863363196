import { useCallback } from 'react';
import { navigate } from '@reach/router';
import { useDispatch } from 'react-redux';
import { setPaciente } from 'store/ducks/paciente';

export function useActionPaciente() {
  const dispatch = useDispatch();
  const select = useCallback(pacienteId => {
    navigate(`/pacientes/${pacienteId}/editar`);
  }, []);

  const click = useCallback(
    paciente => {
      dispatch(setPaciente(paciente));
      navigate(`/pacientes/novo`);
    },
    [dispatch]
  );

  return [select, click];
}
