import {
  Col,
  Form,
  Row,
  Typography,
  Input,
  Button,
  notification,
  Select,
  InputNumber,
} from 'antd';
import { ItemLabel } from 'components/Form';
import { BorderedBox } from 'components/Layout';
import mapboxgl from 'mapbox-gl';
import Map, { updateLayers } from 'components/Map';
import React, { useCallback, useState, useEffect } from 'react';
import useDraw from 'hooks/useDraw';
import {
  findAll,
  updateUnidade,
  salvarGeomUnidades,
} from 'services/unidadeBasicaService';
import { StyledContentList } from '../Pacientes/styled';

const { Title } = Typography;

function normalize(value) {
  return value === null || value === '' ? undefined : value;
}

const CadastroUnidadeSaude = ({
  form: { getFieldDecorator, validateFields, resetFields, setFieldsValue },
}) => {
  const [map, setMap] = useState();
  const [features, changeMode] = useDraw(map, true, 'simple_select');
  const [feature, setFeature] = useState();
  const [unidades, setUnidades] = useState([]);
  const [unidadeID, setUnidadeID] = useState();

  useEffect(() => {
    if (map) {
      map.U.addCircle('unidade_basica', 'unidade_basica-source', {
        circleColor: [
          'case',
          [
            'any',
            ['boolean', ['feature-state', 'hover'], false],
            ['boolean', ['feature-state', 'selecionado'], false],
          ],
          '#ffFF00',
          ['case', ['==', ['get', 'tipo'], 1], 'green', 'blue'],
        ],
      });

      return () => {
        map.U.removeLayer(['unidade_basica']);
      };
    }
    return () => { };
  }, [map]);

  const handleSelectEdit = useCallback(
    id => {
      setUnidadeID(id);
      const un = unidades.find(u => u.id === id) ?? {};
      setFieldsValue(un);
    },
    [setFieldsValue, unidades]
  );

  const handleClickLimpar = useCallback(() => {
    setUnidadeID(undefined);
    resetFields();
  }, [resetFields]);

  useEffect(() => {
    if (changeMode && map) {
      map.getCanvas().style.cursor = 'pointer';
      changeMode('draw_point');
    }
  }, [changeMode, map]);

  useEffect(() => {
    if (map && features.length > 0) map.getCanvas().style.cursor = '';
    setFeature(features[0]);
  }, [features, map]);

  useEffect(() => {
    let marker;
    if (features[0]) {
      marker = new mapboxgl.Marker()
        .setLngLat(features[0].geometry.coordinates)
        .addTo(map);
    }
    return () => {
      if (marker) {
        marker.remove();
      }
    };
  }, [features, map]);

  const changeGeom = useCallback(() => {
    if (changeMode) {
      map.getCanvas().style.cursor = 'pointer';
      changeMode('draw_point');
    }
  }, [changeMode, map]);

  const getAllUnidades = useCallback(() => {
    findAll().then(({ data }) => setUnidades(data));
  }, []);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();

      function cb(resp) {
        if (resp?.data?.id) {
          notification.success({
            message: 'Sucesso',
            description: 'Unidade cadastrada!',
          });
          updateLayers(map);
        } else {
          notification.error({
            message: 'Erro',
            description: 'Não foi possível salvar este cadastro',
          });
        }
        setUnidadeID(undefined);
        setFeature(undefined);
        resetFields();
        changeGeom();
        getAllUnidades();
      }

      validateFields((err, values) => {
        if (!err) {
          if (unidadeID) {
            updateUnidade(unidadeID, values)
              .then(cb)
              .catch(() => {
                notification.error({
                  message: 'Erro',
                  description: 'Não foi possível salvar este cadastro',
                });
              });
          } else if (feature) {
            salvarGeomUnidades({
              ...values,
              feature: feature.geometry,
            })
              .then(cb)
              .catch(() => {
                notification.error({
                  message: 'Erro',
                  description: 'Não foi possível salvar este cadastro',
                });
              });
          }
        }
      });
    },
    [
      changeGeom,
      feature,
      getAllUnidades,
      map,
      resetFields,
      unidadeID,
      validateFields,
    ]
  );

  useEffect(() => {
    getAllUnidades();
  }, [getAllUnidades]);

  return (
    <>
      <Title level={3}>Cadastro de Unidade de Saúde</Title>
      <BorderedBox>
        <Row type="flex" style={{ height: 'calc(100vh - 180px)' }} gutter={16}>
          <Col span={10}>
            <Form
              onSubmit={handleSubmit}
              style={{ position: 'relative', padding: 16 }}>
              <StyledContentList>
                <Row gutter={16}>
                  <Col span={18}>
                    <ItemLabel label="Editar" forInput="edit">
                      <Select
                        size="large"
                        onSelect={handleSelectEdit}
                        value={unidadeID}>
                        {unidades.map(u => {
                          return (
                            <Select.Option value={u.id} key={u.id}>
                              {u.nome}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </ItemLabel>
                  </Col>
                  <Col span={6}>
                    <Button size="large" onClick={handleClickLimpar}>
                      Limpar
                    </Button>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} xl={12}>
                    <ItemLabel label="Nome" forInput="edit_nome" required>
                      {getFieldDecorator('nome', {
                        rules: [
                          { required: true, message: 'Campo obrigatório' },
                        ],
                        normalize,
                      })(<Input size="large" />)}
                    </ItemLabel>
                  </Col>
                  <Col xs={24} xl={12}>
                    <ItemLabel label="Código" forInput="edit_codigo" required>
                      {getFieldDecorator('codigo', {
                        rules: [
                          { required: true, message: 'Campo obrigatório' },
                        ],
                        normalize,
                      })(<Input size="large" />)}
                    </ItemLabel>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} xl={12}>
                    <ItemLabel label="Leitos" forInput="edit_leitos" required>
                      {getFieldDecorator('leitos', {
                        rules: [
                          { required: true, message: 'Campo obrigatório' },
                        ],
                        normalize,
                      })(
                        <InputNumber
                          style={{ width: '100%' }}
                          size="large"
                          min={0}
                        />
                      )}
                    </ItemLabel>
                  </Col>
                  <Col xs={24} xl={12}>
                    <ItemLabel
                      label="Respiradores"
                      forInput="edit_respiradores"
                      required>
                      {getFieldDecorator('respiradores', {
                        rules: [
                          { required: true, message: 'Campo obrigatório' },
                        ],
                        normalize,
                      })(
                        <InputNumber
                          style={{ width: '100%' }}
                          size="large"
                          min={0}
                        />
                      )}
                    </ItemLabel>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} xl={12}>
                    <ItemLabel label="Tipo" forInput="edit_tipo" required>
                      {getFieldDecorator('tipo', {
                        rules: [
                          { required: true, message: 'Campo obrigatório' },
                        ],
                        normalize,
                      })(
                        <Select size="large">
                          <Select.Option value={1}>UBS</Select.Option>
                          <Select.Option value={2}>Hospital</Select.Option>
                        </Select>
                      )}
                    </ItemLabel>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button htmlType="submit" type="primary">
                      Salvar
                    </Button>
                    <div style={{ float: 'right' }}>
                      {!feature && !unidadeID && (
                        <span
                          style={{
                            color: 'red',
                            marginRight: 5,
                            fontSize: 12,
                          }}>
                          Selecione o ponto no mapa
                        </span>
                      )}
                      <Button
                        onClick={changeGeom}
                        disabled={!feature}
                        size="large">
                        Alterar ponto
                      </Button>
                    </div>
                  </Col>
                </Row>
              </StyledContentList>
            </Form>
          </Col>
          <Col span={14}>
            <Map setMapRef={setMap} />
          </Col>
        </Row>
      </BorderedBox>
    </>
  );
};
export default Form.create({ name: 'edit' })(CadastroUnidadeSaude);
