export function createTypes(types, prefix) {
  return types.reduce(
    (acc, curr) => ({ ...acc, [curr]: `${prefix}@${curr}` }),
    {}
  );
}

export function createReducer(initialState, actionReducers) {
  return (state = initialState, action) => {
    const actionsReducer = actionReducers[action.type];
    if (actionsReducer) {
      return actionsReducer(state, action.payload);
    }
    return state;
  };
}
