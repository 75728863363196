import { Button } from 'antd';
import Modals from 'modals';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { openModal } from 'store/ducks/modal';
import { finalizarTratamento } from 'services/tratamentoService';
import {
  addEstagioClinico,
  updateStatusEtapa,
  reload,
} from 'store/ducks/etapa';
import constantes from 'utils/constantes';

export default function ButtonProsseguir({ resultado, etapa }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const finalizado = useSelector(state => state.etapa.tratamento?.finalizado);
  const idTratamento = useSelector(state => state.etapa.tratamento?.id);

  const handleClick = useCallback(() => {
    if (resultado === 1 || resultado === 3) {
      dispatch(openModal(Modals.ModalOrientacao, { resultado, etapa }));
    }

    // encerra tratamento e coloca paciente como descartado.
    if (resultado === 2) {
      setLoading(true);
      batch(async () => {
        await dispatch(
          updateStatusEtapa(constantes.ETAPA.STATUS.EXECUTADO, { resultado })
        );
        await dispatch(addEstagioClinico(constantes.ESTADO_CLINICO.DESCARTADO));

        await finalizarTratamento(idTratamento);
        await dispatch(reload());

        setLoading(false);
      });
    }
  }, [dispatch, etapa, idTratamento, resultado]);

  return (
    <Button
      loading={loading}
      disabled={!resultado || finalizado}
      onClick={handleClick}
      size="large"
      type="primary">
      Prosseguir
    </Button>
  );
}
