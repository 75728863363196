import api from './api';

export function findById(id) {
  return api.get(`/etapas/${id}`);
}

export function updateSequence(etapas) {
  return api.put('/etapas/sequencia', etapas);
}

export function update(id, body) {
  return api.put(`/etapas/${id}`, body);
}

export function save(body) {
  return api.post('/etapas', body);
}

export function remove(id) {
  return api.delete(`/etapas/${id}`);
}

export function addAnexo(data) {
  const { file, onSuccess, onError, onProgress, action } = data;
  const form = new FormData();
  form.append('file', file, file.name);

  api
    .post(action, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: ({ total, loaded }) => {
        onProgress(
          { percent: Math.round((loaded / total) * 100).toFixed(2) },
          file
        );
      },
    })
    .then(({ data: response }) => {
      onSuccess(response, file);
    })
    .catch(onError);
}

export function getAnexo(id) {
  return api.get(`/etapas/${id}/anexo`);
}

export function deleteAnexo(id, nome) {
  return api.delete(`/etapas/${id}/anexo?nome=${nome}`);
}

export function downloadAnexo(id) {
  return api.get(`/etapas/${id}/anexo/download`, { responseType: 'blob' });
}
