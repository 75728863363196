import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Dropdown, Menu, Input, Tag, notification } from 'antd';
import TagsContainer from './styled';

function arrayContains(needle, arrhaystack) {
  return arrhaystack.indexOf(needle) > -1;
}

function TagSelect({
  disabled,
  sourceData = [], // Tag list to select
  selectedTags: selected = [], // Array keys of the current selected tags
  onSelect = () => { }, // callback on select tag
  onRemove = () => { }, // callback on remove tags
  textItemExists = 'O item já existe', // text alert when current tag selected
  notify = true, // if true show the alert notification when tag exists
  loading = false,
}) {
  const tags = sourceData;
  const [searchedText, setSearchedText] = useState('');
  const [selectedTags, setSelectedTags] = useState(selected);

  useEffect(() => {
    if (selected !== selectedTags) {
      setSelectedTags(selected);
    }
  }, [selected, selectedTags]);

  const handleClick = ({ key: tag }) => {
    if (!arrayContains(tag, selectedTags)) {
      const newSelectTags = [...selectedTags, tag];
      onSelect(tag, newSelectTags);
      setSelectedTags(newSelectTags);
      setSearchedText('');
    } else if (notify) notification.warn({ message: textItemExists });
  };

  const handleRemove = useCallback(
    removedTag => {
      const restedTags = selectedTags.filter(tag => tag !== removedTag);
      onRemove(removedTag, restedTags);
      setSelectedTags(restedTags);
    },
    [onRemove, selectedTags]
  );

  const menu = () => {
    let menuItem = tags
      .filter(tag => {
        if (searchedText !== '') {
          if (tag.toUpperCase().includes(searchedText.toUpperCase())) {
            return true;
          }
          return false;
        }
        return true;
      })
      .map(tag => <Menu.Item key={tag}>{tag}</Menu.Item>);
    menuItem =
      menuItem.length === 0 ? (
        <Menu.Item key={searchedText}>{searchedText}</Menu.Item>
      ) : (
          menuItem
        );
    return <Menu onClick={handleClick}>{menuItem}</Menu>;
  };

  const tagsList = useMemo(
    () =>
      selectedTags.map(tag => (
        <Tag
          color="#108ee9"
          key={tag}
          size="large"
          closable
          onClose={e => {
            e.preventDefault();
            handleRemove(tag);
          }}>
          {tag}
        </Tag>
      )),
    [handleRemove, selectedTags]
  );

  return (
    <TagsContainer>
      <div>
        <Dropdown disabled={disabled} overlay={menu} trigger={['click']}>
          <Input.Search
            size="large"
            value={searchedText}
            allowClear
            placeholder="Digite para pesquisar"
            onChange={e => setSearchedText(e.target.value)}
            style={{ width: '100%' }}
            loading={loading}
          />
        </Dropdown>
      </div>
      <div>{tagsList}</div>
    </TagsContainer>
  );
}

export default TagSelect;
