import styled from 'styled-components';
import { Layout } from 'antd';

const StyleLogin = styled(Layout)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #004dff;
  height: 100vh;

  .container {
    width: 360px;
    height: 425px;
  }

  .logo {
    display: flex;
    justify-content: center;
    height: 60px;

    svg {
      width: auto;
      height: 100%;
    }

    .rectangle {
      height: 42px;
      width: 42px;
      margin-right: 15px;
      border: 6px solid #ffffff;
      border-radius: 15px;
    }

    .text-logo {
      height: 43px;
      width: 97px;
      color: #ffffff;
      font-size: 30px;
      font-weight: 600;
      line-height: 46px;
    }
  }

  .login-form {
    width: 360px;
    height: 310px;
    text-align: center;
    padding: 30px 32px 0;
    margin-top: 45px;
    border-radius: 20px;
    background-color: #ffffff;
  }

  .login-form-button {
    width: 100%;
    height: 48px;
    line-height: 48px;
    font-size: 14px;

    .anticon {
      vertical-align: -0.2em;
    }
  }

  .link-form {
    font-size: 12px;
  }
`;

export default StyleLogin;
