import React, { useEffect, useState } from 'react';
import { Avatar, Comment, Icon, List } from 'antd';
import { ReactComponent as CommentIcon } from 'assets/icons/comment.svg';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { fetchComments, clear } from 'store/ducks/comments';
import { getToken } from 'storage/auth';
import { StyledCommentItem } from './styled';

const dateTimeFormat = '[em] DD [de] MMMM [de] YYYY [às] HH:mm';

export default function CommentList({ idEtapa }) {
  const comments = useSelector(state => state.comments);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(fetchComments(idEtapa)).then(() => {
      setLoading(false);
    });
    return () => {
      dispatch(clear());
    };
  }, [dispatch, idEtapa]);

  const commentsCount = comments?.length || 0;
  let commentTitle = comments.length > 1 ? 'Comentários' : 'Comentário';
  commentTitle += ` (${commentsCount})`;
  commentTitle = comments.length === 0 ? 'Nenhum comentário' : commentTitle;

  return (
    <List
      dataSource={comments}
      loading={loading}
      header={
        <>
          <Icon
            component={CommentIcon}
            style={{ fontSize: '17px', color: '#92929D', marginRight: 18 }}
          />
          <strong>{commentTitle}</strong>
        </>
      }
      itemLayout="horizontal"
      renderItem={item => (
        <StyledCommentItem>
          <Comment
            author={item.nome}
            avatar={
              <Avatar
                src={`${item.avatar}?token=${getToken()}`}
                alt={item.author}
              />
            }
            content={<p>{item.comentario}</p>}
            datetime={<span>{moment(item.data).format(dateTimeFormat)}</span>}
          />
        </StyledCommentItem>
      )}
    />
  );
}
