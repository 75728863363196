import React from 'react';
import { Avatar, Typography, Spin } from 'antd';
import { age } from 'utils/Functions';
import StyledTag from 'components/Tag';
import { Link } from '@reach/router';
import CONSTANTES from 'utils/constantes';
import { getImageURL } from 'services/pacienteService';
import StyledResumo from './styled';

const { Title } = Typography;

export default function PacienteResumo({ paciente, edit = false }) {
  if (!paciente?.id) return <Spin />;
  const { alergias = [] } = paciente;
  return (
    <StyledResumo>
      <Avatar
        style={{ marginBottom: 12 }}
        src={getImageURL(paciente.id)}
        shape="square"
        size={67}
        icon="user"
      />
      <div>
        <Title level={3}>
          {`${paciente.nome} ${paciente.sobrenome}`}
          {edit && (
            <div className="edit-user">
              <Link to={`/pacientes/${paciente.id}/editar`}>Editar</Link>
            </div>
          )}
        </Title>
        <div className="user-resumo">
          <span className="age">{age(paciente.data_nascimento)}</span>
          <div className="tags">
            {paciente.tempo_gestacao !==
              CONSTANTES.TEMPO_GESTACAO.NAO_SE_APLICA.value &&
              paciente.tempo_gestacao !==
                CONSTANTES.TEMPO_GESTACAO.IGNORADO.value &&
              paciente.tempo_gestacao !==
                CONSTANTES.TEMPO_GESTACAO.NAO.value && (
                <StyledTag type="gestante" text="Gestante" />
              )}
            {alergias.map(a => (
              <StyledTag
                type="medicamento"
                icon="exclamation-circle"
                text={a.nome}
              />
            ))}
          </div>
        </div>
      </div>
    </StyledResumo>
  );
}
