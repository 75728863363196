import React from 'react';
import { Icon } from 'antd';
import { ButtonTransparent } from 'components/Button';
import { StyledTrigger } from './styled';

export function TriggerClose({ onClick }) {
  return (
    <StyledTrigger>
      <ButtonTransparent onClick={onClick}>
        <Icon type="close" />
      </ButtonTransparent>
    </StyledTrigger>
  );
}
