import { useActionPaciente } from 'pages/Pacientes/action';
import { useActionSelectParent } from 'pages/Pacientes/Page/PessoasRelacionadas/action';

export function useAction(tipo = null) {
  const paciente = useActionPaciente();
  const parent = useActionSelectParent();
  const ACTION_TIPO = {
    paciente,
    mae: parent,
    pai: parent,
    parceiro: parent,
    filho: parent,
  };
  return tipo ? ACTION_TIPO[tipo] : [];
}
