import styled from 'styled-components';
import React from 'react';
import { Tag, Icon } from 'antd';

const styles = {
  tratamento: {
    fontSize: 10,
    bgColor: '#82C43C',
  },
  aguardando: {
    fontSize: 12,
    fontWeight: '600',
    bgColor: '#ffc542',
  },
  pendente: {
    fontSize: 12,
    fontWeight: '600',
    bgColor: '#FC5A5A',
  },
  assinada: {
    fontSize: 13,
    color: 'rgb(61,213,152)',
    bgColor: 'rgba(61,213,152,.1)',
  },
  alerta: {
    fontSize: 10,
    color: 'rgb(68,68,79)',
    bgColor: 'rgba(255,75,0,.1)',
  },
  gestante: {
    fontSize: 10,
    color: 'rgb(68,68,79)',
    bgColor: 'rgba(77,0,255,.14)',
  },
  medicamento: {
    fontSize: 10,
    color: 'rgb(68,68,79)',
    bgColor: 'rgba(255,75,0,.1)',
  },
  data: {
    fontSize: 13,
    fontWeight: '400',
    color: 'rgba(255,151,74,1)',
    bgColor: 'rgba(255,151,74,.1)',
  },
  data_pendente: {
    fontSize: 13,
    fontWeight: '400',
    color: 'rgba(252, 90, 90,1)',
    bgColor: 'rgba(252, 90, 90,.1)',
  },
  default: {
    fontSize: 12,
    fontWeight: '500',
    color: '#fff',
    bgColor: '#f1f1f5',
  },
};

const getPropStyle = (type, prop) => {
  if (type && styles[type] && styles[type][prop]) {
    return styles[type][prop];
  }
  return styles.default[prop];
};

const Styled = styled(Tag)`
  &.ant-tag {
    font-size: ${props => getPropStyle(props.type, 'fontSize')}px;
    font-weight: ${props => getPropStyle(props.type, 'fontWeight')};
    color: ${props => getPropStyle(props.type, 'color')};
    background-color: ${props => getPropStyle(props.type, 'bgColor')};
    padding: 3px 14px;
    border: none;
    white-space: pre-line;
    margin: ${props => (props.noMargin ? '0' : '4px 4px 0 0')};

    .anticon {
      &.icon-prop {
        margin-right: 5px;
      }
      &.anticon-exclamation-circle {
        font-size: 20px;
        color: rgb(252, 90, 90);
        vertical-align: -0.325em;
      }
    }
  }
`;

export default function StyledTag({ type, icon, text, children, ...props }) {
  return (
    <Styled type={type} {...props}>
      {icon && <Icon type={icon} className="icon-prop" />}
      {text || children}
    </Styled>
  );
}
