import styled from 'styled-components';
import constantes from 'utils/constantes';

function getBgColor({ status = undefined }) {
  const colors = {
    dragging: 'aliceblue',
    disabled: 'rgba(241,241,242,1)',
    done: 'rgba(61,213,152,0.24)',
    [constantes.ETAPA.STATUS.EXECUTADO]: 'rgba(61, 213, 152, 0.24)',
    [constantes.ETAPA.STATUS.NAO_COMPARECEU]: 'rgba(255,151,74,0.24)',
    default: 'rgba(255,255,255,1)',
    '#f77474': 'rgba(247, 116, 116, 0.24)',
    '#FFD422': 'rgba(255, 212, 34, 0.24)',
    '#3DD598': 'rgba(61, 213, 152, 0.24)',
    '#3DD500': 'rgba(61, 213, 0, 0.24)',
    '#35425B': 'rgba(53, 66, 91, 0.24)',
    '#000000': 'rgba(0, 0, 0, 0.24)',
  };
  return colors[status] || colors.default;
}

function getBorder({ status = 'blank' }) {
  const colors = {
    new: '1px dashed rgba(0,98,255,1)',
    [constantes.ETAPA.STATUS.NAO_EXECUTADO]: '1px solid rgba(192,192,202,1)',
    dragging: '1px solid rgba(192,192,202,1)',
    warning: '1px solid rgba(252,90,90,1)',
    selected: '1px solid rgb(0,98,255)',
    [constantes.ETAPA.STATUS.NAO_COMPARECEU]: '1px solid rgba(255,151,74,1)',
    [constantes.ETAPA.STATUS.EXECUTADO]: '1px solid rgba(61, 213, 152, 1)',
    default: '1px solid rgba(192,192,202,1)',
    '#f77474': '#f77474',
    '#FFD422': '#FFD422',
    '#3DD598': '#3DD598',
    '#3DD500': '#3DD500',
    '#35425B': '#35425B',
    '#000000': '#000000',
  };

  return colors[status] || colors.default;
}

const EtapaItem = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${props => (props.noPadding ? '0' : '15px 22px')};
  margin: ${props => (props.noMargin ? '0' : '5px 0')};
  border: ${props =>
    props.selected ? getBorder({ status: 'selected' }) : getBorder(props)};
  border-radius: 10px;
  background-color: ${props => getBgColor(props)};
  cursor: pointer;
  font-size: 14px;
  height: 60px;

  > span {
    font-size: 13px;
    font-weight: 500;
    color: ${props => (props.type === 'MEDICAMENTO' ? '#0062FF' : '#3DD598')};
    margin-right: 12px;
  }

  .new-step {
    flex: 1;
    text-align: center;
    color: rgba(0, 98, 255, 1);
    font-size: 24px;
    padding: 10px 22px;
    cursor: pointer;
    transition: all 200ms ease-in;

    &:hover {
      color: rgba(0, 98, 255, 0.6);
    }
  }

  .right-etapa {
    display: flex;
    height: 26px;
    line-height: 26px;
  }

  .step-count {
    color: rgba(146, 146, 157, 1);
    font-size: 13px;

    .anticon {
      margin: 0 5px;
    }
  }

  .ant-btn {
    &.ant-btn-icon-only {
      padding: 0;
      font-size: 21px;
      height: 24px;

      .anticon {
        color: #92929d;
        vertical-align: 0;

        &.anticon-delete {
          color: #fc5a5a;
        }
      }
    }
  }

  > .ant-tag {
    margin: 0;
    padding: 2px 6px;
  }

  .badge-alert {
    position: absolute;
    width: 20px;
    height: 20px;
    right: -5px;
    top: -5px;
    padding: 0;
    background: #ffffff;
    border: 5px solid rgb(252, 90, 90);
    border-radius: 20px;
    outline: unset;
    cursor: pointer;
  }
`;

export { EtapaItem };
