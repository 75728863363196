import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formValueSelector, Field, change } from 'redux-form';
import { Input, Checkbox } from 'components/Form';
import { FORM } from './FORM';

const selector = formValueSelector(FORM);

export default function NomeMae() {
  const dispatch = useDispatch();

  const nome_mae = useSelector(state => selector(state, 'nome_mae'));

  useEffect(() => {
    if (nome_mae?.trim()) {
      dispatch(change(FORM, 'mae_desconhecida', false));
    } else {
      dispatch(change(FORM, 'mae_desconhecida', true));
    }
  }, [dispatch, nome_mae]);

  return (
    <>
      <Field name="nome_mae" label="Nome da Mãe" component={Input} />
      <Field
        disabled
        name="mae_desconhecida"
        className="normal-lineHeight"
        component={Checkbox}>
        Desconhecida
      </Field>
    </>
  );
}
