class HeatMap {
  constructor() {
    this.init = false;
  }

  addTo(map, data) {
    this.map = map;
    this.init = true;

    const confirmado = ['==', ['get', 'id_estagio_clinico'], 5];
    const recuperado = ['==', ['get', 'id_estagio_clinico'], 6];
    const descartado = ['==', ['get', 'id_estagio_clinico'], 7];
    const obito = ['==', ['get', 'id_estagio_clinico'], 8];
    const suspeito = ['==', ['get', 'id_estagio_clinico'], 4];

    const mapColors = [
      'case',
      confirmado,
      '#FFD422',
      recuperado,
      '#3DD598',
      descartado,
      '#3DD500',
      obito,
      '#000000',
      suspeito,
      '#f77474',
      '#fff',
    ];

    map.addSource('heat-source', {
      type: 'geojson',
      data,
    });

    map.addLayer(
      {
        id: 'heat',
        type: 'heatmap',
        source: 'heat-source',
        paint: {
          'heatmap-radius': 25,
          'heatmap-weight': 0.1,
          'heatmap-color': [
            'interpolate',
            ['linear'],
            ['heatmap-density'],
            0,
            'rgba(0, 0, 255, 0)',
            0.1,
            '#ffffb2',
            0.3,
            '#feb24c',
            0.5,
            '#fd8d3c',
            0.7,
            '#fc4e2a',
            1,
            '#e31a1c',
          ],
        },
      },
      'waterway-label'
    );

    map.addLayer({
      id: 'heat_individual',
      type: 'circle',
      source: 'heat-source',
      minzoom: 10,
      paint: {
        'circle-color': mapColors,
        'circle-radius': 5,
      },
    });

    map.addLayer({
      id: 'heat_individual_outer',
      type: 'circle',
      source: 'heat-source',
      minzoom: 10,
      paint: {
        'circle-stroke-color': mapColors,
        'circle-stroke-width': 2,
        'circle-radius': 7,
        'circle-color': 'rgba(0, 0, 0, 0)',
      },
    });
  }

  update(data) {
    if (this.map) {
      this.map.getSource('heat-source').setData(data);
    }
  }

  destroy() {
    this.init = false;
    if (this.map) {
      try {
        this.map.U.removeSource('heat-source');
      } catch (error) {
        console.log(error);
      }
    }
  }
}
export default new HeatMap();
