import styled from 'styled-components';

export const StyledRadioGroup = styled.div.attrs(({ disabled = false }) => ({
  className: disabled ? 'radio-disabled' : '',
}))`
  display: flex;

  .radio-option-item {
    display: flex;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 40px;
    }
  }

  &.radio-disabled {
    .radio-option-item {
      cursor: not-allowed;
    }
  }
`;

export const StyledRadioOption = styled.div`
  font-size: 13px;
`;
