import { Comments } from 'components/Comments';
import React, { useMemo } from 'react';
import HeaderTratamento from './HeaderTratamento';
import { StyledEtapaDetalhe } from './styled';

export default function Teste({
  etapa,
  sections = [],
  footer = [],
  allowComments = true,
}) {
  const comments = useMemo(() => {
    if (allowComments && etapa?.id) {
      return <Comments idEtapa={etapa.id} />;
    }
    return null;
  }, [allowComments, etapa]);

  const header = useMemo(() => {
    return <HeaderTratamento etapa={etapa} />;
  }, [etapa]);

  return (
    <StyledEtapaDetalhe>
      {header}
      <div className="descricao-tratamento">
        {sections}
        <div className="descricao-tratamento__footer">{footer}</div>
      </div>
      {comments}
    </StyledEtapaDetalhe>
  );
}
