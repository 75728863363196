import { Button, Col, DatePicker, Input, Row, Typography } from 'antd';
import { BigModal, ModalActions, ModalTextDivisor } from 'components/BigModal';
import { ButtonTransparent } from 'components/Button';
import { ItemLabel } from 'components/Form';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { findAll } from 'services/pacienteService';
import { closeModal } from 'store/ducks/modal';
import { CardPaciente } from 'components/Paciente';
import { ResultSearchPeople } from './ResultSearchPeople';
import { useAction } from './useAction';

const { Title } = Typography;

const initialValue = {
  nome: '',
  data_nascimento: null,
  n_cartao_sus: '',
};

const tipos = ['mae', 'pai', 'paciente'];

export default function ModalAddPeople({ visible, tipo }) {
  const [searchValue, setSearchValue] = useState(initialValue);
  const [pacientes, setPacientes] = useState([]);
  const dispatch = useDispatch();

  const [select, click] = useAction(tipo);

  useEffect(() => {
    if (searchValue.nome || searchValue.n_cartao_sus) {
      const params = !searchValue.n_cartao_sus
        ? {
            data_nascimento: searchValue.data_nascimento?.format(),
            nome_mae: searchValue.nome,
          }
        : {
            limit: 5,
            n_cartao_sus: searchValue.n_cartao_sus,
            cpf: searchValue.n_cartao_sus,
            nome: searchValue.n_cartao_sus,
          };
      findAll(params)
        .then(({ data }) => setPacientes(data.data))
        .catch(() => {});
    }
  }, [searchValue.data_nascimento, searchValue.n_cartao_sus, searchValue.nome]);

  const handleClose = useCallback(() => {
    setPacientes([]);
    setSearchValue(initialValue);
    dispatch(closeModal());
  }, [dispatch]);

  const handleResultClick = useCallback(
    idPaciente => {
      handleClose();
      select(idPaciente);
    },
    [handleClose, select]
  );

  const handleContinueClick = useCallback(() => {
    let data_nascimento = '';
    if (searchValue.data_nascimento !== '') {
      data_nascimento = searchValue.data_nascimento?.format();
    }

    const paciente = {
      data_nascimento,
      nome_mae: searchValue.nome,
      n_cartao_sus: searchValue.n_cartao_sus,
    };

    handleClose();
    click(paciente);
  }, [click, handleClose, searchValue]);

  return (
    <BigModal
      width={640}
      visible={visible}
      onCancel={handleClose}
      footer={null}>
      <Title level={4}>
        Informe alguns dos dados abaixo
        <br />
        referentes {tipo === 'mae' ? 'a ' : 'ao '}
        {tipos.includes(tipo) ? tipo : `${tipo}(a)`}:
      </Title>

      <Row gutter={16}>
        <Col span={12}>
          <ItemLabel>
            <DatePicker
              value={searchValue.data_nascimento || null}
              size="large"
              placeholder="Data de Nascimento"
              onChange={value =>
                setSearchValue(prev => ({
                  ...prev,
                  data_nascimento: value,
                  n_cartao_sus: '',
                }))
              }
            />
          </ItemLabel>
        </Col>
        <Col span={12}>
          <ItemLabel label="Nome da mãe">
            <Input
              value={searchValue.nome}
              onChange={event =>
                setSearchValue({
                  nome: event.target.value,
                  data_nascimento: searchValue.data_nascimento,
                  n_cartao_sus: '',
                })
              }
              size="large"
            />
          </ItemLabel>
        </Col>
      </Row>

      <ModalTextDivisor>
        ou informando o nº de cartão do SUS ou CPF ou Nome
      </ModalTextDivisor>

      <ItemLabel label="Nº Cartão do SUS ou CPF ou Nome">
        <Input
          value={searchValue.n_cartao_sus}
          onChange={event =>
            setSearchValue({
              n_cartao_sus: event.target.value,
              nome: '',
              data_nascimento: '',
            })
          }
          size="large"
        />
      </ItemLabel>

      <ModalActions>
        <ButtonTransparent
          size="large"
          onClick={() => setSearchValue(initialValue)}>
          Limpar
        </ButtonTransparent>
        <Button
          type="primary"
          size="large"
          onClick={() => handleContinueClick()}>
          Continuar
        </Button>
      </ModalActions>

      <ResultSearchPeople length={pacientes.length} />
      {pacientes.map(paciente => (
        <CardPaciente
          key={paciente.id}
          paciente={paciente}
          onClick={() => handleResultClick(paciente.id)}
        />
      ))}
    </BigModal>
  );
}
