import { useEffect, useState } from 'react';
import { getMapaEstagioDoenca } from 'services/estadosService';
import { useSelector } from 'react-redux';
import moment from 'moment';
import HeatMap from './HeatMap';

// function agruparPorMunicipio(features) {
//   const featuresMap = features.reduce((acc, curr) => {
//     const total =
//       (acc[`${curr.id_municipio}-${curr.created_at}`]?.total || 0) + 1;
//     return {
//       ...acc,
//       [`${curr.id_municipio}-${curr.created_at}`]: {
//         ...acc[`${curr.id_municipio}-${curr.created_at}`],
//         ...curr,
//         total,
//       },
//     };
//   }, {});
//   return Object.values(featuresMap);
// }

function getAtualPorDia(features) {
  const featuresMap = features
    .sort((a, b) => moment(a.created_at) - moment(b.created_at))
    .reduce(
      (acc, curr) => ({
        ...acc,
        [curr.id_tratamento]: curr,
      }),
      {}
    );
  return Object.values(featuresMap);
}

function toGeojson(features) {
  return features.map(v => {
    const { geom, ...properties } = v;
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: geom,
      },
      properties,
    };
  });
}

export default function HeatLayer({ map }) {
  const [data, setData] = useState();

  const date = useSelector(state => state.map.date);
  const estagio = useSelector(state => state.map.estagio);

  useEffect(() => {
    getMapaEstagioDoenca(42)
      .then(resp => {
        setData(resp.data);
      })
      .catch(e => console.log(e));
    return () => {
      if (map) {
        HeatMap.destroy();
      }
    };
  }, [map]);

  useEffect(() => {
    if (data && map) {
      let filtered = data;

      if (date) {
        filtered = filtered.filter(f => moment(f.created_at) <= moment(date));
      }

      if (estagio) {
        if (estagio === 'total') {
          filtered = getAtualPorDia(filtered);
          filtered = filtered.filter(
            f =>
              f.id_estagio_clinico === 5 ||
              f.id_estagio_clinico === 6 ||
              f.id_estagio_clinico === 8
          );
        }
        if (estagio === 'confirmados') {
          filtered = getAtualPorDia(filtered);
          filtered = filtered.filter(f => f.id_estagio_clinico === 5);
        }

        if (estagio === 'recuperados') {
          filtered = getAtualPorDia(filtered);
          filtered = filtered.filter(f => f.id_estagio_clinico === 6);
        }

        if (estagio === 'mortes') {
          filtered = getAtualPorDia(filtered);
          filtered = filtered.filter(f => f.id_estagio_clinico === 8);
        }

        if (estagio === 'suspeitos') {
          filtered = filtered.filter(f => f.id_estagio_clinico === 4);
          filtered = getAtualPorDia(filtered);
        }
      }
      // filtered = agruparPorMunicipio(filtered);
      const features = toGeojson(filtered);
      if (!HeatMap.init) {
        HeatMap.addTo(map, { type: 'FeatureCollection', features });
      } else {
        HeatMap.update({ type: 'FeatureCollection', features });
      }
    }
  }, [data, date, estagio, map]);

  return null;
}
