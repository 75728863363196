import styled from 'styled-components';
import { Layout } from 'antd';

const StyledHeader = styled(Layout.Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;
  padding: 0 20px;
  box-shadow: inset 0 -1px 0 0 #e2e2ea;
  z-index: 1;

  .header-actions {
    display: flex;
    width: 470px;
    height: 38px;
    justify-content: space-between;
    align-items: center;
  }

  .search-header {
    width: 380px;
    height: 38px;

    .ant-input {
      font-weight: 600;
      background-color: #f1f1f5;
      padding-left: 43px;
      border-radius: 10px;
      border: none;

      &:active,
      &:focus {
        background-color: #fbfbfb;
        border: none;
      }

      &::placeholder {
        color: #696974;
      }
    }
  }
`;

export default StyledHeader;
