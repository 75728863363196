import React from 'react';
import { RadioGroup } from 'components/RadioGroup';

const opts = [
  { label: 'Leve', value: 1 },
  { label: 'Moderado', value: 2 },
  { label: 'Grave', value: 3 },
  { label: 'Gravíssimo', value: 4 },
];

export default function RadioGroupQuadroClinico({ value, onChange, ...props }) {
  return (
    <RadioGroup options={opts} value={value} onChange={onChange} {...props} />
  );
}
