import React from 'react';
import { EtapaItem } from 'components/EtapaItem';
import { formatDate } from 'utils/Functions';
import { CircleDot } from 'components/CircleDot';
import moment from 'moment';

export default function Etapa({ item, tag }) {
  const { nome, cor } = item;

  const etapaStatus = cor;
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <EtapaItem status={etapaStatus}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <CircleDot color={item.cor} />

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <strong>{`${nome}`}</strong>
            <strong style={{ fontSize: 10 }}>{`${formatDate(
              moment(item.data_realizacao || 0)
            )}`}</strong>
          </div>
        </div>

        <div className="right-etapa">{tag}</div>
      </EtapaItem>
    </div>
  );
}
