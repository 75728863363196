import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import { navigate, Link } from '@reach/router';
import { Avatar, Badge, Dropdown, Icon, Input, Menu, AutoComplete } from 'antd';
import { ReactComponent as LogoRadarSaude } from 'assets/logo/radarsaude.svg';
import { ButtonTransparent } from 'components/Button';
import { Logo, StyledHeader } from 'components/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { findAll } from 'services/pacienteService';
import { getToken, logout } from 'storage/auth';
import { fetchUsuarioLogado } from 'store/ducks/usuario';
import DelayAbortController from 'utils/DelayAbortController';
import { CardPaciente } from 'components/Paciente';

const abortController = new DelayAbortController();

const { Option } = AutoComplete;

const menu = (
  <Menu>
    <Menu.Item key="0" onClick={() => navigate('/usuarios/meu-perfil')}>
      Meu Perfil
    </Menu.Item>
    <Menu.Item
      key="1"
      onClick={() => {
        logout();
        navigate('/');
      }}>
      Sair
    </Menu.Item>
  </Menu>
);

const initialState = {
  data: [],
  loading: true,
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_DATA':
      return { ...state, data: action.payload.data, loading: false };
    case 'CLEAR_DATA':
      return { ...state, data: [], loading: false };
    default:
      throw new Error();
  }
}

function Header() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const reduxDispatch = useDispatch();
  const foto = useSelector(statux => statux.usuario?.foto);

  const userProfilePicture = useMemo(
    () => (foto ? `${foto}?token=${getToken()}` : undefined),
    [foto]
  );

  const fetch = abortController.execute((filter, callback, cancelToken) => {
    const { n_cartao_sus, nome, cpf } = filter;
    findAll(
      {
        offset: 0,
        limit: 5,
        n_cartao_sus,
        nome,
        cpf,
      },
      { cancelToken }
    )
      .then(result => {
        callback(result.data);
      })
      .catch(() => {});
  });

  const handleSearch = useCallback(
    filterValue => {
      const filter = {
        n_cartao_sus: filterValue,
        nome: filterValue,
        cpf: filterValue,
      };
      if (filterValue && filterValue !== '') {
        fetch(filter, ({ data }) =>
          dispatch({ type: 'SET_DATA', payload: { data } })
        );
      } else {
        dispatch({ type: 'CLEAR_DATA' });
      }
    },
    [fetch]
  );

  const options = useMemo(() => {
    return state.data?.map(p => (
      <Option key={p.id} value={p.nome}>
        <CardPaciente
          paciente={p}
          onClick={() => navigate(`/pacientes/${p.id}/tratamentos`)}
        />
      </Option>
    ));
  }, [state.data]);

  useEffect(() => {
    reduxDispatch(fetchUsuarioLogado());
  }, [reduxDispatch]);

  return (
    <StyledHeader>
      <Logo>
        <LogoRadarSaude />
      </Logo>
      <div className="header-actions">
        <AutoComplete
          className="certain-paciente-search"
          dropdownClassName="certain-paciente-search-dropdown"
          dropdownMatchSelectWidth={false}
          dropdownStyle={{ width: 300 }}
          size="large"
          style={{ width: '100%' }}
          onSearch={handleSearch}
          dataSource={options}
          optionLabelProp="value">
          <Input
            className="search-header"
            placeholder="Pesquisar pelo Nº Cartão SUS ou CPF ou Nome"
            prefix={
              <Icon type="search" style={{ fontSize: 25, color: '#92929D' }} />
            }
          />
        </AutoComplete>
        <ButtonTransparent>
          <Badge dot>
            <Icon type="bell" style={{ fontSize: 22 }} />
          </Badge>
        </ButtonTransparent>

        <Dropdown overlay={menu}>
          <Avatar
            src={userProfilePicture}
            icon={!userProfilePicture && 'user'}
          />
        </Dropdown>
      </div>
    </StyledHeader>
  );
}

export default Header;
