import styled from 'styled-components';
import { Menu } from 'antd';

const StyledMenu = styled(Menu)`
  &.ant-menu-vertical {
    border: none;
  }

  .ant-menu-item {
    position: relative;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1px;
    z-index: 0;
    transition: color 300ms ease-in-out;

    &.ant-menu-item-selected,
    &.ant-menu-item-active,
    &:hover {
      .anticon {
        color: #0062ff;
      }
    }

    &.ant-menu-item-selected {
      .selected-border {
        display: block;
        position: absolute;
        top: 4px;
        left: 0;
        height: 32px;
        width: 3px;
        border-radius: 0 100px 100px 0;
        background-color: #0062ff;
        z-index: 1;
      }
    }

    .anticon {
      font-size: 20px;
      color: #92929d;
      margin-right: 22px;
      vertical-align: text-bottom;
      transition: color 300ms ease-in-out;
    }

    .selected-border {
      display: none;
    }
  }
`;

export default StyledMenu;
