import React, { useState, useCallback, useEffect } from 'react';
import RadioGroupComponent from './RadioGroup';

export function RadioGroup({
  defaultChecked = undefined,
  value = undefined,
  options = [],
  onChange = () => {},
  ...props
}) {
  const [checked, setChecked] = useState(defaultChecked);

  useEffect(() => {
    if (value) {
      setChecked(value);
    }
  }, [value]);

  const handleClick = useCallback(
    option => {
      const { value: optionValue } = option;
      setChecked(optionValue);
      onChange(optionValue, option);
    },
    [onChange]
  );

  if (!Array.isArray(options) || options.length <= 0) return null;

  return (
    <RadioGroupComponent
      options={options}
      value={checked}
      onChange={handleClick}
      {...props}
    />
  );
}
