import { Button } from 'antd';
import styled from 'styled-components';

export const ButtonTransparent = styled(Button)`
  &.ant-btn {
    border: none;
    background: transparent;
    padding: 5px;
    box-shadow: unset;
  }
`;

export const ButtonShallowNow = styled(Button)`
  &.ant-btn {
    font-weight: 500;
    border-radius: 10px;
    color: #696974;
    background-color: ${props =>
      props.active === 'true' ? '#fff' : 'transparent'};
    border: unset;
    box-shadow: ${props =>
      props.active === 'true' ? '0 1px 4px 0 rgba(68,68,79,0.1)' : 'unset'};

    .anticon {
      font-size: 18px;
      color: ${props =>
        props.active === 'true' ? props.iconcolor : 'inherit'};
      vertical-align: -0.265em;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    &:active,
    &.active,
    &:hover,
    &:focus {
      background: #ffffff;
      box-shadow: 0 1px 4px 0 rgba(68, 68, 79, 0.1);

      .anticon {
        color: ${props => (props.iconcolor ? props.iconcolor : 'inherit')};
      }
    }
  }
`;

export const ButtonTinyIcon = styled(Button).attrs(() => ({
  size: 'small',
  className: 'ant-btn-icon-only ant-btn-tiny',
}))`
  &.ant-btn {
    font-size: 18px;
    border: none;
    border-radius: 6px;
    color: #0062ff;
    box-shadow: 0 2px 5px 0 rgba(7, 68, 166, 0.34);

    path {
      stroke: #0062ff;
    }

    &:hover {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.4);

      path {
        stroke: #fff;
      }
    }
  }
`;
