import React, { useMemo, useCallback, useState } from 'react';
import { Form, Input, Button, Comment, Avatar, Icon } from 'antd';
import { ReactComponent as SentIcon } from 'assets/icons/sent.svg';
import { useSelector, useDispatch } from 'react-redux';
import { getToken } from 'storage/auth';
import { addComment } from 'store/ducks/comments';
import moment from 'moment';

const { TextArea } = Input;

function CommentForm({ form, idEtapa }) {
  const { getFieldDecorator, validateFields, resetFields } = form;

  const usuario = useSelector(state => state.usuario);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const userProfilePicture = useMemo(
    () => (usuario?.foto ? `${usuario.foto}?token=${getToken()}` : null),
    [usuario]
  );

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      validateFields((err, values) => {
        if (!err) {
          const newComment = {
            idUsuario: usuario.id,
            idEtapa,
            comentario: values.comment,
            data: moment(Date.now()).format('YYYY-MM-DD HH:mm:ss'),
          };

          setLoading(true);
          dispatch(addComment(newComment)).then(() => {
            setLoading(false);
            resetFields();
          });
        }
      });
    },
    [dispatch, idEtapa, resetFields, usuario.id, validateFields]
  );

  return (
    <Comment
      avatar={<Avatar src={userProfilePicture} alt={usuario?.nome} />}
      content={
        <Form onSubmit={handleSubmit}>
          <Form.Item>
            {getFieldDecorator('comment', {
              rules: [
                { required: true, message: 'Please input your username!' },
              ],
            })(
              <TextArea placeholder="Digite aqui seu comentário..." rows={4} />
            )}
          </Form.Item>
          <Form.Item>
            <Button
              size="large"
              htmlType="submit"
              loading={loading}
              type="primary">
              {!loading ? (
                <Icon
                  component={SentIcon}
                  style={{ fontSize: 20, marginRight: 5 }}
                />
              ) : null}
              <strong>{loading ? 'Enviando' : 'Enviar'}</strong>
            </Button>
          </Form.Item>
        </Form>
      }
    />
  );
}

export default Form.create()(CommentForm);
