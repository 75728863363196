import React from 'react';
import styled from 'styled-components';

const StyledSectionContent = styled.div`
  ${({ width = 'auto' }) => `max-width: ${width}px`};

  > .section-title {
    color: rgba(23, 23, 37, 1);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 13px;

    &.no-icon {
      margin-left: 34px;
    }

    .anticon {
      margin-right: 18px;
      font-size: 17px;
      color: rgba(146, 146, 157, 1);
    }
  }

  > .section-description {
    margin-left: 35px;
    margin-bottom: 35px;
    font-size: 13px;
    color: rgba(143, 143, 162, 1);
    line-height: 21px;
  }
`;

export function SectionContent({
  title = '',
  icon = undefined,
  width = undefined,
  children,
}) {
  return (
    <StyledSectionContent width={width}>
      <div className={`section-title ${icon ? 'has-icon' : 'no-icon'}`}>
        {icon}
        <strong>{title}</strong>
      </div>
      <div className="section-description">{children}</div>
    </StyledSectionContent>
  );
}
