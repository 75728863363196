import React, { useMemo, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import constantes from 'utils/constantes';
import { updateStatusEtapa, reload } from 'store/ducks/etapa';
import { Button } from 'antd';
import Etapa from '../TemplateEtapa';
import SectionDescricao from '../Sections/SectionDescricao';
import SectionQuadroClinico from '../Sections/SectionQuadroClinico';

function Footer({ etapa, propriedades }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    setLoading(true);
    dispatch(
      updateStatusEtapa(constantes.ETAPA.STATUS.EXECUTADO, propriedades)
    ).then(() => {
      dispatch(reload()).then(() => {
        setLoading(false);
      });
    });
  }, [dispatch, propriedades]);

  if (etapa.status === constantes.ETAPA.STATUS.NAO_EXECUTADO) {
    return (
      <Button
        loading={loading}
        className="btn-green"
        icon="check"
        onClick={handleClick}
        size="large">
        Confirmar isolamento
      </Button>
    );
  }
  return null;
}

export default function EtapaAtualizacaoEstagio({ etapa }) {
  const [propriedades, setPropriedades] = useState({});

  const sectionDescricao = useMemo(() => {
    return <SectionDescricao etapa={etapa} />;
  }, [etapa]);

  const handleChangeProperties = useCallback(data => {
    setPropriedades(prev => ({ ...prev, ...data }));
  }, []);

  const sectionQuadroClinico = useMemo(() => {
    return (
      <SectionQuadroClinico etapa={etapa} onChange={handleChangeProperties} />
    );
  }, [etapa, handleChangeProperties]);

  const footer = useMemo(() => {
    return <Footer etapa={etapa} propriedades={propriedades} />;
  }, [etapa, propriedades]);

  return (
    <Etapa
      etapa={etapa}
      sections={[sectionDescricao, sectionQuadroClinico]}
      footer={footer}
    />
  );
}
