import React, { useCallback } from 'react';
import { navigate, Link } from '@reach/router';
import { Form, Input, Typography, Button, notification, Icon } from 'antd';
import { authenticate } from 'services/usuarioService';
import { login } from 'storage/auth';
import { validarCPF } from 'utils/Functions';
import useBooleanState from 'hooks/useBooleanState';

const { Title } = Typography;

function LoginForm({ form: { getFieldDecorator, validateFields } }) {
  const [loading, changeLoading] = useBooleanState(false);

  const validatorCPF = useCallback((rule, value, callback) => {
    if (value === 'admin') callback();
    if (validarCPF(value)) {
      callback();
    } else callback('CPF Inválido');
  }, []);

  function handleSubmit(event) {
    event.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        changeLoading();
        authenticate(values.cpf, values.senha)
          .then(({ data }) => {
            login(data.token);
            navigate('/dashboard');
          })
          .catch(({ response }) => {
            if (response.status === 401) {
              notification.warn({
                message: 'Atenção',
                description: response.data.message,
              });
            }
          })
          .finally(changeLoading);
      }
    });
  }

  return (
    <Form className="login-form" onSubmit={handleSubmit}>
      <Title level={4}>Acesso Restrito</Title>
      <Form.Item>
        {getFieldDecorator('cpf', {
          rules: [
            {
              required: true,
              message: 'Por favor, digite seu CPF!',
              validator: validatorCPF,
            },
          ],
        })(<Input size="large" placeholder="CPF" />)}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator('senha', {
          rules: [{ required: true, message: 'Por favor, digite sua senha!' }],
        })(<Input.Password size="large" placeholder="Senha" />)}
      </Form.Item>
      <Form.Item>
        <Button
          loading={loading}
          size="large"
          type="primary"
          htmlType="submit"
          className="login-form-button">
          <strong>Acessar</strong>
          <Icon type="right" />
        </Button>
        <Link to="recuperar-senha" className="link-form">
          Esqueci minha senha
        </Link>
      </Form.Item>
    </Form>
  );
}

export default Form.create({ name: 'login' })(LoginForm);
