import { userLoggedDetail } from 'services/usuarioService';
import { createTypes, createReducer } from '../duckCreator';

export const Types = createTypes(['CLEAR', 'SET_USUARIO'], 'usuario');

const initialState = {
  id: null,
  cpf: null,
  email: '',
  nome: '',
  foto: null,
  id_perfil: null,
};

const actionReducers = {
  [Types.SET_USUARIO]: (state, payload) => {
    return { ...state, ...payload };
  },
  [Types.CLEAR]: () => {
    return initialState;
  },
};

// Action Creators

export function setUsuario(usuario) {
  return { type: Types.SET_USUARIO, payload: usuario };
}

export function fetchUsuarioLogado() {
  return async dispatch => {
    const { data } = await userLoggedDetail();
    dispatch(setUsuario(data));
  };
}

export function clearUsuario() {
  return { type: Types.CLEAR };
}

export default createReducer(initialState, actionReducers);
