import { Select } from 'antd';
import styled from 'styled-components';

export const SelectWithTag = styled(Select)`
  .ant-select-selection-selected-value {
    .ant-tag {
      position: absolute;
      right: 0;
      top: 12px;
    }
  }
`;
