import styled from 'styled-components';

export const StyledResultSearchPeople = styled.div`
  display: flex;
  font-size: 16px;
  color: #171725;
  font-weight: 600;

  span {
    color: #0062ff;
    margin-right: 5px;
    margin-bottom: 10px;
  }
`;
