import React from 'react';
import { useSelector } from 'react-redux';
import ModalAddPeople from './ModalAddPeople';
import ModalEtapa from './ModalEtapa';
import ModalAssinatura from './ModalAssinatura';
import ModalOrientacao from './ModalOrientacao';
import ModalFinalizarTratamento from './ModalFinalizarTratamento';
import ModalNovoTratamento from './ModalNovoTratamento';

export default function Modals() {
  const modal = useSelector(state => state.modal);
  return [
    <ModalAddPeople
      key={Modals.ModalAddPeople}
      visible={modal?.id === Modals.ModalAddPeople}
      {...modal.properties}
    />,
    <ModalEtapa
      key={Modals.ModalEtapa}
      visible={modal?.id === Modals.ModalEtapa}
      {...modal.properties}
    />,
    <ModalAssinatura
      key={Modals.ModalAssinatura}
      visible={modal?.id === Modals.ModalAssinatura}
      {...modal.properties}
    />,
    <ModalOrientacao
      key={Modals.ModalOrientacao}
      visible={modal?.id === Modals.ModalOrientacao}
      {...modal.properties}
    />,
    <ModalFinalizarTratamento
      key={Modals.ModalFinalizarTratamento}
      visible={modal?.id === Modals.ModalFinalizarTratamento}
      {...modal.properties}
    />,
    <ModalNovoTratamento
      key={Modals.ModalNovoTratamento}
      visible={modal?.id === Modals.ModalNovoTratamento}
      {...modal.properties}
    />,
  ];
}

Modals.ModalAddPeople = 'modal-add-people';
Modals.ModalEtapa = 'modal-etapa';
Modals.ModalAssinatura = 'modal-assinatura';
Modals.ModalOrientacao = 'modal-orientacao';
Modals.ModalFinalizarTratamento = 'modal-finalizar-tratamento';
Modals.ModalNovoTratamento = 'modal-novo-tratamento';
