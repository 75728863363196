import { navigate } from '@reach/router';
import { Layout } from 'antd';
import React, { useEffect } from 'react';
import { isAuthenticated } from 'storage/auth';
import Header from './header';
import MenuLateral from './menuLateral';
import Page from './page';

function Main({ currentPage, uri }) {
  useEffect(() => {
    if (uri === '/') {
      navigate('/dashboard', { replace: true });
    }
  }, [uri]);

  useEffect(() => {
    const token = isAuthenticated();
    if (!token) navigate('/login');
  }, []);

  return (
    <Layout>
      <Header />
      <Layout>
        <MenuLateral currentPage={currentPage} />
        <Page currentPage={currentPage} />
      </Layout>
    </Layout>
  );
}

export default Main;
