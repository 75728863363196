import styled from 'styled-components';

export default styled.div`
  display: flex;

  .ant-avatar {
    margin-right: 13px;
  }

  .user-resumo {
    display: flex;
    align-items: center;
  }

  .ant-typography {
    position: relative;
    margin-bottom: 0;
  }

  .age {
    color: #92929d;
    font-size: 12px;
    font-weight: 500;
    margin-right: 15px;
  }

  .edit-user {
    position: absolute;
    color: #0062ff;
    font-size: 11px;
    font-weight: 600;
    top: 10px;
    right: -45px;
  }
`;
