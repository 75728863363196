import React, { useCallback } from 'react';
import { Avatar } from 'antd';
import { useSelector } from 'react-redux';
import { getImageURL } from 'services/pacienteService';
import styled from 'styled-components';
import { Link } from '@reach/router';

const StyledAvatars = styled.div`
  display: inline-block;

  > a {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;

export function AvatarRelacionadas() {
  const { pai = {}, mae = {}, filhos = [], parceiros = [] } = useSelector(
    state => state.paciente.data
  );

  const avatarsLoop = useCallback((items, tipo) => {
    if (Array.isArray(items) && items.length > 0) {
      return items.map(
        item =>
          item.id && (
            <Link to={`/pacientes/${item.id}/tratamentos`} title={tipo}>
              <Avatar src={getImageURL(item.id)} alt={tipo} />
            </Link>
          )
      );
    }
    return '';
  }, []);

  return (
    <StyledAvatars>
      {pai.id && (
        <Link to={`/pacientes/${pai.id}/tratamentos`} title="Pai">
          <Avatar src={getImageURL(pai.id)} alt="Pai" />
        </Link>
      )}
      {mae.id && (
        <Link to={`/pacientes/${mae.id}/tratamentos`} title="Mãe">
          <Avatar src={getImageURL(mae.id)} alt="Mãe" />
        </Link>
      )}
      {avatarsLoop(filhos, 'Filho')}
      {avatarsLoop(parceiros, 'Parceiro')}
    </StyledAvatars>
  );
}
