import styled from 'styled-components';
import { Button, Calendar } from 'antd';

export const StyledContent = styled.div`
  color: #8f8fa2;
  font-size: 14px;
  text-align: center;

  > h4.ant-typography {
    margin-bottom: 10px;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-form-item-label {
    line-height: 2.5;

    > label {
      color: #8f8fa2;
      font-size: 14px;
    }
  }

  .time-item {
    margin: 20px 0 10px;
  }

  .ant-time-picker,
  .ant-select {
    margin-bottom: 10px !important;
  }

  .bts-actions {
    margin-top: 20px;
  }
`;

export const StyleCalendar = styled(Calendar)`
 &:not(.ant-fullcalendar) {
  width: 387px;
  height: 353px;
  box-shadow: 0 2px 23px 0 rgba(2, 43, 108, 0.12);
  padding: 20px;
  margin: 0 auto;
  border-radius: 20px;

  .custom-fullcalendar-header {
    font-size: 17px;
    color: rgba(68, 68, 79, 1);
    margin-bottom: 24px;
    padding: 0 12px;

    .btns {
      button:first-child {
        margin-right: 12px;
      }
    }
  }

  .ant-fullcalendar {
    border-top: none;

    .ant-fullcalendar-calendar-body {
      padding: 0;
    }

    .ant-fullcalendar-value {
      width: 34px;
      height: 34px;
      line-height: 34px;
      border-radius: 34px;
    }
  }
`;

export const ButtonToggleMonth = styled(Button)`
  &.ant-btn {
    &.ant-btn-icon-only {
      background-color: rgba(0, 98, 255, 0.07);
      width: 22px;
      height: 22px;
      font-size: 12px;
      border-radius: 22px;
      border: none;
      color: rgba(0, 98, 255, 1);

      &:hover {
        background-color: rgba(0, 98, 255, 0.1);
      }
    }
  }
`;
