export default {
  TEMPO_GESTACAO: {
    '1_TRIMESTE': {
      value: 1,
      label: '1º Trimestre',
    },
    '2_TRIMESTRE': {
      value: 2,
      label: '2º Trimestre',
    },
    '3_TRIMESTRE': {
      value: 3,
      label: '3º Trimestre',
    },
    IDADE_IGNORADA: {
      value: 4,
      label: 'Idade gestacional Ignorada',
    },
    NAO: {
      value: 5,
      label: 'Não',
    },
    NAO_SE_APLICA: {
      value: 6,
      label: 'Não se aplica',
    },
    IGNORADO: {
      value: 7,
      label: 'Ignorado',
    },
  },
  SEXO: {
    M: 'Masculino',
    F: 'Feminino',
    I: 'Ignorado',
  },
  RACA: {
    BRANCA: {
      value: 1,
      label: 'Branca',
    },
    PRETA: {
      value: 2,
      label: 'Preta',
    },
    AMARELA: {
      value: 3,
      label: 'Amarela',
    },
    PARDA: {
      value: 4,
      label: 'Parda',
    },
    INDIGENA: {
      value: 5,
      label: 'Indígena',
    },
    IGNORADO: {
      value: 6,
      label: 'Ignorado',
    },
  },
  ETAPA: {
    STATUS: {
      NAO_EXECUTADO: 0,
      EXECUTADO: 1,
      NAO_COMPARECEU: 2,
      NAO_ENVIADA: 3,
      ENVIADA: 4, //      ENVIADA = NAO_ASSINADA
      NAO_ASSINADA: 4, // NAO_ASSINADA = ENVIADA
      ASSINADA: 5,
    },
  },
  PERFIL: {
    ADMIN: 0,
    GESTOR: 1,
    AGENTE: 2,
  },
  STATUS: {
    ATIVO: 1,
    INATIVO: 2,
    BLOQUEADO: 3,
  },
  ESTADO_CLINICO: {
    SIFILIS_RECENTE: 1,
    SIFILIS_TARDIA: 2,
    NEUROSIFILIS: 3,
    SUSPEITO: 4,
    CONFIRMADO: 5,
    RECUPERADO: 6,
    DESCARTADO: 7,
    OBITO: 8,
  },
};
