import styled from 'styled-components';

export const CircleDot = styled.span.attrs(props => ({
  className: props.size || '',
}))`
  display: inline-block;
  height: 20px;
  width: 20px;
  border: 4px solid #fff;
  border-radius: 20px;
  vertical-align: -0.325em;
  ${({ position = 'left' }) => {
    if (position === 'right') {
      return `margin-left: 8px;`;
    }
    return `margin-right: 8px;`;
  }}
  background-color: ${({ color }) => color || '#fff'};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  &.small {
    height: 16px;
    width: 16px;
    ${({ position = 'left' }) => {
      if (position === 'right') {
        return `margin-left: 4px;`;
      }
      return `margin-right: 4px;`;
    }}
    vertical-align: -0.225em;
    border: 4px solid #fff;
    border-radius: 18px;
  }
`;
