/* eslint-disable no-param-reassign */
import uuid from 'uuid/v4';
import { coordAll } from '@turf/meta';
import distance from '@turf/distance';
import length from '@turf/length';
import explode from '@turf/explode';
import midpoint from '@turf/midpoint';
import area from '@turf/area';

let draw;

export const setDraw = d => {
  draw = d;
};

export const addDraw = feat => draw.add(feat);

export const getDraw = () => draw;

export const GEOMETRY_TYPES = {
  POLYGON: 'Polygon',
  LINE_STRING: 'LineString',
  POINT: 'Point',
};

// Note: this lng/lat rounding doesn't have anything to do with snapping,
// but can reduce output file size quite a lot
// There's not much point in 13 decimal places of lng/lat.

// Number of decimal places in lat/lng values
// One day could be configurable
export const ACCURACY = {
  '1 m': 5,
  '10 cm': 6,
  '1 cm': 7,
  '1 mm': 8,
};

// eslint-disable-next-line no-mixed-operators
export const round = (num, decimals) =>
  Math.round(num * 10 ** decimals) / 10 ** decimals;

export const roundLngLatTo1Cm = num => round(num, ACCURACY['1 cm']);

/**
 * Creates a GeoJSON feature to pass to mapbox-gl-draw
 *
 * @param {string} type
 * @param properties
 * @returns GeoJSONFeature
 */
export const makeFeature = ({ type, properties = {} }) => ({
  id: uuid(),
  type: 'Feature',
  properties: {
    ...properties,
    pam: 'true',
  },
  geometry: {
    type,
    coordinates: [[]],
  },
});

/**
 * Takes a point and maybe adds it to the list of guides.
 *
 * Mutates guides.
 *
 * @param {object} guides
 * @param {Array<number>} guides.vertical
 * @param {Array<number>} guides.horizontal
 * @param {object} point
 * @param {number} point.x
 * @param {number} point.y
 * @param {boolean} [forceInclusion]
 */
export const addCoordToGuides = (guides, coord, point) => {
  const { x, y } = point;
  const pointIsOnTheScreen =
    x > 0 && x < window.innerWidth && y > 0 && y < window.innerHeight;

  if (pointIsOnTheScreen) {
    if (!guides.includes(coord)) guides.push(coord);
  }
};

/**
 * Loops over all features to get vertical and horizontal guides to snap to
 *
 * @param {object} props
 * @param props.map
 * @param props.currentFeature
 * @returns {{vertical: Array, horizontal: Array}}
 */
export const findGuidesFromFeatures = ({ map, currentFeature, layers }) => {
  const guidesCoord = [];

  const features = map.queryRenderedFeatures({ layers });
  features.forEach(feature => {
    if (feature.properties.isSnapGuide === 'true') return;

    /*
    const isTheCurrentFeature = feature.id === currentFeature.id;

    let coordinates;

    if (isTheCurrentFeature) {
      // For the current polygon, the last two points are the mouse position and back home
      // so we chop those off (else we get guides showing where the user clicked, even
      // if they were just panning the map)
      if (currentFeature.type === GEOMETRY_TYPES.POLYGON) {
        coordinates = feature.geometry.coordinates[0].slice(0, -2);
      } else if (currentFeature.type === GEOMETRY_TYPES.LINE_STRING) {
        // Similar for lines, chop off the last point
        coordinates = feature.geometry.coordinates.slice(0, -1);
      } else {
        // if the currentFeature is a Point, don't do anything with it
        return;
      }
    } else {
      // If this isn't the current feature, use all the points within it
      coordinates = coordAll(feature);
    }
    */

    const coordinates = coordAll(feature);

    coordinates.forEach(coordinate => {
      addCoordToGuides(guidesCoord, coordinate, map.project(coordinate));
    });
  });
  return {
    guidesCoord,
  };
};

/**
 * For a given point, this returns any vertical and/or horizontal guides within snapping distance
 *
 * @param guides
 * @param point
 * @param snapPx
 * @returns {{vertical: number | undefined, horizontal: number | undefined}}
 */
const getNearbyGuides = (guides, point, snapPx, guidesCoord, lngLat) => {
  let nearestDist = {};
  guidesCoord.forEach(guide => {
    const dist = distance(guide, [lngLat.lng, lngLat.lat]) * 1000;
    if (!nearestDist.dist || nearestDist.dist > dist) {
      nearestDist = { guide, dist };
    }
  });

  const nearbyCoordGuide =
    nearestDist.dist < snapPx ? nearestDist.guide : undefined;

  return {
    coordPx: nearbyCoordGuide,
  };
};

/**
 * Returns snap points if there are any, otherwise the original lng/lat of the event
 * Also, defines if guides should show on the state object
 *
 * Mutates the state object
 *
 * @param state
 * @param e
 * @returns {{lng: number, lat: number}}
 */
export const snapAndDrawGuides = (state, e) => {
  let lng = e.lngLat.lng;
  let lat = e.lngLat.lat;

  // Holding alt bypasses all snapping
  if (e.originalEvent.altKey) {
    return { lng, lat };
  }

  const { coordPx } = getNearbyGuides(
    state.guides,
    e.point,
    state.snapPx,
    state.guidesCoord,
    e.lngLat
  );

  if (coordPx) {
    lng = coordPx[0];
    lat = coordPx[1];
  }

  return { lng, lat };
};

/**
 * Returns a guide GeoJSON feature to pass to mapbox-gl-draw
 * @param id
 * @returns GeoJSONFeature
 */
export const getGuideFeature = id => ({
  id,
  type: 'Feature',
  properties: {
    isSnapGuide: 'true', // for styling
  },
  geometry: {
    type: GEOMETRY_TYPES.LINE_STRING,
    coordinates: [],
  },
});

export const setAreaPolygon = data => {
  if (data && (data.type === 'Polygon' || data.geometry.type === 'Polygon')) {
    const valueArea = area(data);
    const value_area =
      valueArea > 1000000
        ? `${Math.round((valueArea / 1000000) * 100) / 100}km²`
        : `${Math.round(valueArea * 100) / 100}m²`;
    draw && draw.setFeatureProperty(data.id, 'value_area', value_area);
  }
};

export const setComprimentoLine = data => {
  if (data && data.geometry.type === 'LineString') {
    const valueExplode = explode(data);
    for (let i = 1; i < valueExplode.features.length; i++) {
      const dist = distance(
        valueExplode.features[i - 1],
        valueExplode.features[i]
      );

      let value_comprimento =
        dist < 1
          ? `${Math.round(dist * 1000 * 100) / 100}m`
          : `${Math.round(dist * 100) / 100}km`;

      const mid = midpoint(
        valueExplode.features[i - 1],
        valueExplode.features[i]
      );

      addDraw({
        id: `value-dist-${data.id}-${i}`,
        type: 'Feature',
        properties: { value_comprimento },
        geometry: { type: 'Point', coordinates: mid.geometry.coordinates },
      });

      if (i + 1 === valueExplode.features.length) {
        const valueComprimento = length(data);
        value_comprimento =
          valueComprimento < 1
            ? `Total: ${Math.round(valueComprimento * 1000 * 100) / 100}m`
            : `Total: ${Math.round(valueComprimento * 100) / 100}km`;

        addDraw({
          id: `value-dist-${data.id}-${i + 1}`,
          type: 'Feature',
          properties: { value_comprimento },
          geometry: {
            type: 'Point',
            coordinates: valueExplode.features[i].geometry.coordinates,
          },
        });
      }
    }
  }
};
