import React from 'react';
import TratamentoProgresso from 'components/TratamentoProgresso';
import Text from 'components/Text';
import { useSelector } from 'react-redux';

export default function EvolucaoTratamento({ tratamento }) {
  const etapas = useSelector(
    state => state.paciente.etapas[tratamento.id] ?? []
  );
  return (
    <>
      <Text>EVOLUÇÃO DO TRATAMENTO</Text>
      <TratamentoProgresso width={220} fracao={false} etapas={etapas} />
    </>
  );
}
