import React, { useEffect } from 'react';
import { MainTabs } from 'components/Tabs';
import { useDispatch, batch } from 'react-redux';
import { fetchDoencas, clear, fetchPaciente } from 'store/ducks/paciente';
import Header from './Header';
import { TabRelacionados } from './TabRelacionados';
import { TabPaiMae } from './TabPaiMae';

const { TabPane } = MainTabs;

function PessoasRelacionadas({ pacienteId }) {
  const dispatch = useDispatch();

  useEffect(() => {
    batch(() => {
      dispatch(fetchPaciente(pacienteId));
      dispatch(fetchDoencas(pacienteId));
    });

    return () => {
      dispatch(clear());
    };
  }, [dispatch, pacienteId]);

  return (
    <>
      <Header />
      <MainTabs type="card">
        <TabPane tab="Mãe" key="1">
          <TabPaiMae pacienteId={pacienteId} label="Vincular mãe" type="mae" />
        </TabPane>
        <TabPane tab="Pai" key="2">
          <TabPaiMae pacienteId={pacienteId} label="Vincular pai" type="pai" />
        </TabPane>
        <TabPane tab="Parceiro(a)s" key="3">
          <TabRelacionados pacienteId={pacienteId} type="parceiro" />
        </TabPane>
        <TabPane tab="Filho(a)s" key="4">
          <TabRelacionados pacienteId={pacienteId} type="filho" />
        </TabPane>
      </MainTabs>
    </>
  );
}

export default PessoasRelacionadas;
