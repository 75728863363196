import React from 'react';
import { Button } from 'antd';
import { navigate } from '@reach/router';
import { PacienteResumo } from 'components/Paciente';

import { useSelector } from 'react-redux';
import { HeaderTratamentos } from './styled';
import { AvatarRelacionadas } from './AvatarRelacionadas';

export default function Header() {
  const paciente = useSelector(state => state.paciente.data);
  return (
    <HeaderTratamentos>
      <PacienteResumo paciente={paciente} edit />
      <div className="pessoas-relacionadas">
        <span>Pessoas Relacionadas</span>
        <div className="pessoas-lista">
          <AvatarRelacionadas />
          <Button
            title="Editar/Adicionar pessoas relacionadas"
            onClick={() =>
              navigate(`/pacientes/${paciente.id}/pessoas-relacionadas`)
            }
            type="primary"
            shape="circle"
            icon="plus"
            ghost
          />
        </div>
      </div>
    </HeaderTratamentos>
  );
}
