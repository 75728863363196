import styled from 'styled-components';
import { Table } from 'antd';
import DropdownFilter from './DropdownFilter';
import DropdownSorter from './DropdownSorter';

const theadBorderRadius = 10;
const tbodyBorderRadius = 20;
const spaceBorderColor = 'rgb(250,250,251)';

const TableList = styled(Table)`
  &.ant-table-wrapper {
    .ant-table-column-title {
      color: #44444f;
      font-size: 11px;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 0.8px;
    }
    .ant-table-thead {
      tr {
        th {
          background: #f2f2f2;
          padding: 11px 13px;
          border-top: 5px solid ${spaceBorderColor};
          border-bottom: 5px solid ${spaceBorderColor};

          &:first-child {
            border-top-left-radius: ${theadBorderRadius}px;
            border-bottom-left-radius: ${theadBorderRadius}px;
          }
          &:last-child {
            border-top-right-radius: ${theadBorderRadius}px;
            border-bottom-right-radius: ${theadBorderRadius}px;
          }
        }
      }
    }

    .ant-table-tbody {
      tr {
        td {
          font-weight: 600;
          background: #ffffff;
          border-top: 5px solid ${spaceBorderColor};
          border-bottom: 5px solid ${spaceBorderColor};

          &:first-child {
            border-top-left-radius: ${tbodyBorderRadius}px;
            border-bottom-left-radius: ${tbodyBorderRadius}px;
          }
          &:last-child {
            border-top-right-radius: ${tbodyBorderRadius}px;
            border-bottom-right-radius: ${tbodyBorderRadius}px;
          }
        }

        .ant-table-row-indent,
        .ant-table-row-expand-icon {
          display: none;
        }

        &.ant-table-row-level-1 {
          td:first-child {
            border-left: 25px solid #fafafb;
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;
          }
        }
      }
    }
  }
`;

const TableActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 40px 0 50px;

  .box-sorters,
  .box-filters {
    font-size: 13px;
    color: #44444f;
    cursor: pointer;
  }

  .label-filter {
    color: #92929d;
  }

  .box-filters {
    .anticon {
      font-size: 14px;
      color: #92929e;
      margin-left: 8px;
      vertical-align: -0.285em;
    }
  }

  .box-sorters {
    text-align: right;

    > strong {
      font-size: 11px;
      text-transform: uppercase;
    }

    > span {
      display: inline-block;
      width: 167px;
      font-size: 14px;

      .anticon {
        font-size: 20px;
        margin-right: 8px;
        vertical-align: -0.235em;
      }
    }

    .bts-group-sorters {
      display: inline-block;

      .ant-btn {
        margin: 0 5px;

        &:first-child {
          margin-left: 15px;
        }
      }
    }
  }
`;

const TableContainer = styled.div`
  margin-bottom: 10px;
  padding: 10px 6px 6px;
  border: 1px solid #e2e2ea;
  border-radius: 23px;
`;

const TableHeader = styled.div`
  padding: 6px;

  h4 {
    color: #696974;
    font-family: Poppins;
    font-size: 16px;
  }

  .total {
    height: 26px;
    width: 27px;
    border-radius: 5px;
    background-color: #ffffff;

    span {
      color: #0062ff;
      font-size: 14px;
      line-height: 21px;
    }
  }

  .ant-select-selection {
    height: 38px;
    border-radius: 10px;
    font-size: 14px;

    .ant-select-selection__rendered {
      display: flex;
      height: 38px;
      line-height: 38px;
    }
  }
`;

export {
  TableList,
  TableActions,
  TableContainer,
  TableHeader,
  DropdownFilter,
  DropdownSorter,
};
