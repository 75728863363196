import React from 'react';
import { SectionContent } from '../SectionContent';
import { RadioGroupResultado } from '../RadioGroup';

export default function SectionResultado({ value, onChange, ...props }) {
  return (
    <SectionContent title="Resultado">
      <RadioGroupResultado value={value} onChange={onChange} {...props} />
    </SectionContent>
  );
}
