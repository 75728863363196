import React from 'react';
import { CircleDot } from 'components/CircleDot';
import styled from 'styled-components';

export const StyledLegends = styled.div`
  display: flex;
  color: #696974;
  margin: 0 0 10px;

  > .description {
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-left: 5px;
    font-size: 13px;
  }
`;

export function Legenda({ estagios = [] }) {
  return estagios.map(estagio => (
    <StyledLegends key={estagio.id}>
      <CircleDot size="small" color={estagio.cor} />
      <div className="description">
        <span>{estagio.nome}</span>
        <strong>{estagio.total}</strong>
      </div>
    </StyledLegends>
  ));
}
