import styled from 'styled-components';
import { Form } from 'antd';

const StyledFormItem = styled(Form.Item)`
  &.normal-lineHeight {
    .ant-form-item-control {
      line-height: normal;
    }
    .ant-checkbox-wrapper {
      line-height: normal;
    }
  }

  .ant-checkbox-wrapper {
    span {
      color: rgba(143, 143, 162, 1);
    }
  }

  .inline-label {
    position: absolute;
    top: -0.8rem;
    left: 0.5rem;
    pointer-events: none;
    padding-left: 0.125rem;
    z-index: 1;
    color: rgba(143, 143, 162, 1);
    font-weight: normal;
    background-color: transparent;
    transition: all 0.28s ease;
  }

  &.has-value input ~ .inline-label,
  &.has-value div ~ .inline-label,
  &.has-value span ~ .inline-label,
  input:focus ~ .inline-label {
    font-size: 12px;
    top: -1.1rem;
    left: 0.8rem;
    line-height: 16px;
    padding: 0 5px;
    background-color: #ffffff;
  }

  input:focus ~ .inline-label {
    color: rgba(80, 181, 255, 1);
  }

  input:focus {
    outline: none;
  }

  .ant-form-explain {
    margin-top: 0;
    font-size: 12px;
  }
`;

export default StyledFormItem;
