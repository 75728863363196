import { Button, Col, Icon, Row, Typography } from 'antd';
import { ReactComponent as IconPlace } from 'assets/icons/place.svg';
import { ReactComponent as IconRelationship } from 'assets/icons/relationship.svg';
import { BigModal, ModalTextDivisor } from 'components/BigModal';
import { ButtonTransparent } from 'components/Button';
import React, { useCallback } from 'react';
import { useDispatch, batch } from 'react-redux';
import { save } from 'services/etapaService';
import {
  addEstagioClinico,
  reload,
  updateStatusEtapa,
} from 'store/ducks/etapa';
import { closeModal } from 'store/ducks/modal';
import constantes from 'utils/constantes';

const { Title } = Typography;

export default function ModalOrientacao({ visible, resultado, etapa }) {
  console.log('ModalOrientacao -> resultado, etapa', resultado, etapa);
  const dispatch = useDispatch();

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  const handleOk = useCallback(
    id_etapa_modelo => {
      const { id_tratamento } = etapa;
      async function handle() {
        batch(async () => {
          await dispatch(
            updateStatusEtapa(constantes.ETAPA.STATUS.EXECUTADO, {
              resultado,
            })
          );

          if (resultado === 1) {
            await dispatch(
              addEstagioClinico(constantes.ESTADO_CLINICO.CONFIRMADO)
            );
          }

          await save({
            id_etapa_modelo,
            id_tratamento,
          });

          await dispatch(reload());
          await dispatch(closeModal());
        });
      }
      handle();
    },
    [dispatch, etapa, resultado]
  );

  return (
    <BigModal
      width={640}
      visible={visible}
      onCancel={handleCloseModal}
      footer={null}>
      <Title level={4}>Orientação</Title>
      <ModalTextDivisor>
        Informe a orientação conforme estágio clínico do paciente
      </ModalTextDivisor>
      <Row type="flex" justify="center" gutter={[8, 16]}>
        <Col>
          <Button
            size="large"
            type="primary"
            onClick={() => {
              handleOk(8);
            }}>
            <Icon component={IconPlace} />
            <strong>Determinar Quarentena</strong>
          </Button>
        </Col>
        <Col>
          <Button
            size="large"
            type="primary"
            className="btn-orange"
            onClick={() => {
              handleOk(2);
            }}>
            <Icon component={IconRelationship} />
            <strong>Efetruar Internação</strong>
          </Button>
        </Col>
        <Col span={24} style={{ textAlign: 'center' }}>
          <ButtonTransparent size="small" onClick={handleCloseModal}>
            Cancelar
          </ButtonTransparent>
        </Col>
      </Row>
    </BigModal>
  );
}
