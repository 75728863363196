import { useEffect, useReducer, useCallback } from 'react';
import api from 'services/api';

const INITIAL_STATE = {
  data: undefined,
  isLoading: true,
};

const reducer = (_, action) => {
  switch (action.type) {
    // TODO: por enquanto nao é necessario (economizar um render)
    // case 'FETCH_INIT':
    //   return { data: undefined, isLoading: true };
    case 'FETCH_SUCCESS':
      return { data: action.data, isLoading: false };
    default:
      throw new Error();
  }
};

export default function useFetch(url, initialData, fetchOnMount = true) {
  const [state, dispatch] = useReducer(reducer, {
    ...INITIAL_STATE,
    data: initialData,
  });

  const fetch = useCallback(() => {
    api.get(url).then(result => {
      dispatch({ type: 'FETCH_SUCCESS', data: result.data });
    });
  }, [url]);

  useEffect(() => {
    if (fetchOnMount) {
      fetch();
    }
  }, [fetchOnMount, fetch]);

  return [state.isLoading, state.data, fetch];
}
