import { Col, Row } from 'antd';
import { BorderedBox } from 'components/Layout';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  adicionarRelacionamento,
  save,
  update,
} from 'services/pacienteService';
import {
  clear,
  fetchPacienteFilhos,
  fetchPacienteParceiros,
} from 'store/ducks/paciente';
import FormCadastro from '../FormPaciente/FormCadastro';
import ButtonGroupPessoas from './ButtonGroupPessoas';
import { formHeight } from './comum';

const fetchRelacionados = {
  parceiro: fetchPacienteParceiros,
  filho: fetchPacienteFilhos,
};

export function TabRelacionados({ pacienteId, type }) {
  const dispatch = useDispatch();

  const [paciente, setPaciente] = useState({});
  const [loading, setLoading] = useState(false);

  const handleClickPessoa = useCallback((_, p) => {
    setPaciente(p);
  }, []);

  useEffect(() => {
    if (type !== undefined) {
      dispatch(fetchRelacionados[type](pacienteId));
    }

    return () => {
      dispatch(clear());
    };
  }, [dispatch, pacienteId, type]);

  const handleSave = useCallback(
    (idFilhoParceiro, formData) => {
      async function request() {
        try {
          setLoading(true);

          const promise = idFilhoParceiro
            ? update(idFilhoParceiro, formData)
            : save(formData);

          const { data } = await promise;

          if (paciente.naoAssociado) {
            const relacionamento = {
              tipo: type,
              idPaciente: pacienteId,
            };

            await adicionarRelacionamento(data.id, relacionamento);
            await dispatch(fetchRelacionados[type](pacienteId));
          }
          setLoading(false);
        } catch (e) {
          console.error(e);
        }
      }
      request();
    },
    [dispatch, paciente.naoAssociado, pacienteId, type]
  );
  return (
    <BorderedBox padding={33}>
      <Row>
        <Col span={5}>
          <ButtonGroupPessoas
            label={`Adicionar ${type}(a)`}
            type={type}
            onClick={handleClickPessoa}
          />
        </Col>
        <Col span={19}>
          <FormCadastro
            loading={loading}
            spanForm={15}
            height={formHeight}
            paciente={paciente}
            onSave={handleSave}
            tipo="associacao"
          />
        </Col>
      </Row>
    </BorderedBox>
  );
}
