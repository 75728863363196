/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { CircleIcon } from 'components/CircleIcon';
import { StyledRadioOption } from './styled';

export default function RadioOption({
  onClick = () => {},
  checked = false,
  children,
}) {
  const status = checked ? 1 : 0;
  return (
    <StyledRadioOption
      role="button"
      onClick={onClick}
      tabIndex={-1}
      className="radio-option-item">
      <CircleIcon status={status} />
      {children}
    </StyledRadioOption>
  );
}
