import React from 'react';
import { Avatar } from 'antd';
import { getImageURL } from 'services/pacienteService';
import { StyledButtonPessoa } from './styled';

export function ButtonPessoa({ pessoa, ...props }) {
  const { nome, tratamentos_relacionados } = pessoa;
  const tratamentos =
    tratamentos_relacionados > 0
      ? `${tratamentos_relacionados} tramento(s) em comum`
      : 'Sem tratamento em comum';
  return (
    <StyledButtonPessoa {...props}>
      <Avatar src={getImageURL(pessoa.id)} />
      <div className="pessoa-content">
        <span>{nome}</span>
        <strong>{tratamentos}</strong>
      </div>
      <div className="arrow-right" />
    </StyledButtonPessoa>
  );
}
