import { useEffect } from 'react';

export default function ExtrusionLayer({ map }) {
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (map) {
      map.U.show('municipio-3d');
      map.U.hide('municipio');

      return () => {
        map.U.show('municipio');
        map.U.hide('municipio-3d');
      };
    }
  }, [map]);

  return null;
}
