import { Button, Col, Dropdown, Icon, Menu, Row, Typography, Tag } from 'antd';
import {
  TableActions,
  TableList,
  DropdownFilter,
  DropdownSorter,
} from 'components/Table';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { findAll, remove } from 'services/tratamentoModeloService';
import { formatDate } from 'utils/Functions';
import moment from 'moment';

const { Title } = Typography;
const { Column } = TableList;

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      'selectedRows: ',
      selectedRows
    );
  },
};

const menuFilters = [
  {
    key: 'Todos',
    label: 'Todos',
  },
  {
    key: 'TratamentoAtual',
    label: 'Tratamento atual',
  },
];

function ListaTratamentos({ navigate }) {
  const [loadingTable, setLoadingTable] = useState(true);
  const [data, setData] = useState([]);
  const [sortedInfo, setSortedInfo] = useState({
    columnKey: 'nome',
    order: 'ascend',
    menuItem: 'Nome A-Z',
  });
  const [filteredInfo, setFilteredInfo] = useState({
    filterKey: 'Todos',
    menuItem: 'Todos',
  });

  function list() {
    findAll()
      .then(response => {
        setData(response.data);
        setLoadingTable(false);
      })
      .catch(() => {
        setData([]);
        setLoadingTable(false);
      });
  }

  useEffect(() => {
    list();
  }, []);

  const menuSorters = useMemo(
    () => (
      <Menu
        onClick={menu => {
          const keys = menu.key.split(',');
          setSortedInfo({
            columnKey: keys[0],
            order: keys[1],
            menuItem: menu.item.props.children,
          });
        }}>
        <Menu.Item key="nome,ascend">Nome A-Z</Menu.Item>
        <Menu.Item key="nome,descend">Nome Z-A</Menu.Item>
        <Menu.Item key="doenca_nome,ascend">Doença A-Z</Menu.Item>
        <Menu.Item key="doenca_nome,descend">Doença Z-A</Menu.Item>
      </Menu>
    ),
    []
  );

  const handleDelete = useCallback(tratamento => {
    remove(tratamento.id).then(list);
  }, []);

  const menuRowDropdown = useCallback(
    record => {
      const menu = (
        <Menu
          onClick={({ key }) => {
            if (key === 'editar') {
              navigate(`editar/${record.id}`);
            } else if (key === 'delete') {
              handleDelete(record);
            }
          }}>
          <Menu.Item key="editar">Editar</Menu.Item>
          <Menu.Item key="delete">Excluir</Menu.Item>
        </Menu>
      );
      return menu;
    },
    [handleDelete, navigate]
  );

  return (
    <>
      <Row type="flex" justify="space-between">
        <Col span={12}>
          <Title level={3}>Tratamentos</Title>
        </Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          <Button
            type="primary"
            icon="plus"
            size="large"
            onClick={() => navigate('novo')}>
            Novo Tratamento
          </Button>
        </Col>
      </Row>

      <TableActions>
        <DropdownFilter
          currentItem={filteredInfo.menuItem}
          options={menuFilters}
          onSelect={(key, label) =>
            setFilteredInfo({
              filterKey: key,
              menuItem: label,
            })
          }
        />

        <DropdownSorter
          currentItem={sortedInfo.menuItem}
          options={menuSorters}
        />
      </TableActions>

      <TableList
        loading={loadingTable}
        rowSelection={rowSelection}
        dataSource={data}
        rowKey="id">
        <Column
          sorter={(a, b) => a.nome.localeCompare(b.nome)}
          sortOrder={sortedInfo.columnKey === 'nome' && sortedInfo.order}
          title="Nome do Tratamento"
          dataIndex="nome"
        />
        <Column
          filteredValue={[filteredInfo.filterKey]}
          onFilter={(value, record) =>
            value.toLowerCase() === 'todos' || record.tratamento_atual
          }
          title=""
          dataIndex="tratamento_atual"
          render={atual => {
            if (atual) {
              // TODO: Corrigir a opacidade desta tag
              return <Tag color="#82C43C">Tratamento Atual</Tag>;
            }
            return '';
          }}
        />
        <Column
          title="Doença"
          dataIndex="doenca_nome"
          sorter={(a, b) => a.doenca_nome.localeCompare(b.doenca_nome)}
          sortOrder={sortedInfo.columnKey === 'doenca_nome' && sortedInfo.order}
        />
        <Column
          title="Cadastrado em"
          dataIndex="created_at"
          render={text => <span>{formatDate(moment(text || 0))}</span>}
        />
        <Column
          title="Atualizado em"
          dataIndex="updated_at"
          render={text => <span>{formatDate(moment(text || 0))}</span>}
        />
        <Column
          title="Ações"
          width={80}
          align="center"
          render={(_, record) => (
            <span>
              <Dropdown
                overlay={menuRowDropdown(record)}
                placement="bottomRight"
                trigger={['click']}>
                <Icon type="more" style={{ fontSize: 20 }} />
              </Dropdown>
            </span>
          )}
        />
      </TableList>
    </>
  );
}

export default ListaTratamentos;
