import { Alert, Col, Radio, Row } from 'antd';
import { ItemLabel, Input, Select, Checkbox } from 'components/Form';
import SelectAddress from 'components/SelectAddress';
import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';
import { Field, formValueSelector, change } from 'redux-form';
import estados from 'utils/municipios.json';
import distritoMap from 'utils/distritos.json';
import { useSelector, useDispatch, batch } from 'react-redux';
import { setBuscaPorEndereco } from 'store/ducks/map';
import { filterSelectOption } from 'utils/Functions';
import { FORM } from './FORM';

const selector = formValueSelector(FORM);

export default function Endereco() {
  const buscaPorEndereco = useSelector(state => state.map.buscaPorEndereco);
  const [bbox, setBbox] = useState([]);
  const ref = useRef(buscaPorEndereco);

  useEffect(() => {
    ref.current = buscaPorEndereco;
  }, [buscaPorEndereco]);

  const uf = useSelector(state => selector(state, 'endereco.uf'));
  const municipio = useSelector(state => selector(state, 'endereco.municipio'));

  const dispatch = useDispatch();

  /* MEMO */
  const optionsUF = useMemo(
    () =>
      estados.map(i => (
        <Select.Option key={i.id} value={i.id} title={i.nome}>
          {i.sigla}
        </Select.Option>
      )),
    []
  );

  const optionsMunicipio = useMemo(() => {
    const estado = estados.find(e => e.id === uf);
    if (!estado) return [];

    dispatch(change(FORM, 'endereco.municipio', undefined));

    return estado.municipios?.map(i => (
      <Select.Option key={i.id} value={i.id} title={i.nome}>
        {i.nome}
      </Select.Option>
    ));
  }, [dispatch, uf]);

  const optionsDistrito = useMemo(() => {
    return distritoMap[municipio]?.map(i => (
      <Select.Option key={i.id} value={i.id} title={i.nome}>
        {i.nome}
      </Select.Option>
    ));
  }, [municipio]);
  /* MEMO */

  /* EFFECT */
  useEffect(() => {
    if (ref.current === 1) {
      batch(() => {
        dispatch(change(FORM, 'endereco.codigo_ibge_municipio', municipio));
        dispatch(change(FORM, 'endereco.distrito', undefined));
        dispatch(change(FORM, 'endereco.bairro', undefined));
        dispatch(change(FORM, 'endereco.logradouro', undefined));
        dispatch(change(FORM, 'endereco.codigo_logradouro', undefined));
        dispatch(change(FORM, 'endereco.numero', undefined));
        dispatch(change(FORM, 'endereco.complemento', undefined));
        dispatch(change(FORM, 'endereco.ponto_referencia', undefined));
        dispatch(change(FORM, 'endereco.cep', undefined));
        dispatch(change(FORM, 'endereco.geolocalizacao', undefined));
      });
      const municipioSelecionado = estados
        .map(e => e.municipios)
        .flat()
        .find(m => m.id === municipio);

      if (municipioSelecionado) {
        setBbox(municipioSelecionado.bbox);
      }
    }
  }, [dispatch, municipio]);

  const handleChangeBuscaPorEndereco = useCallback(
    e => {
      dispatch(setBuscaPorEndereco(e.target.value));
    },
    [dispatch]
  );
  /* EFFECT */

  /* CALLBACK */
  const handleChangeSelectAddress = useCallback(
    data => {
      const { center, ...rest } = data;
      batch(() => {
        dispatch(change(FORM, 'endereco.codigo_ibge_municipio', municipio));
        dispatch(change(FORM, 'endereco.distrito', rest.distrito));
        dispatch(change(FORM, 'endereco.bairro', rest.bairro));
        dispatch(change(FORM, 'endereco.logradouro', rest.logradouro));
        dispatch(change(FORM, 'endereco.codigo_logradouro', undefined));
        dispatch(change(FORM, 'endereco.numero', rest.numero));
        dispatch(change(FORM, 'endereco.complemento', undefined));
        dispatch(change(FORM, 'endereco.ponto_referencia', undefined));
        dispatch(change(FORM, 'endereco.cep', rest.cep));
        dispatch(change(FORM, 'endereco.geolocalizacao', center));
      });
    },
    [dispatch, municipio]
  );
  /* CALLBACK */

  return (
    <>
      <Row gutter={16} style={{ marginTop: 10 }}>
        <Col span={16} style={{ marginBottom: 20 }}>
          <span>Informar a localização utilizando:</span>
          <br />
          <Radio.Group
            defaultValue={buscaPorEndereco}
            buttonStyle="solid"
            onChange={handleChangeBuscaPorEndereco}>
            <Radio.Button value={1}>Endereço</Radio.Button>
            <Radio.Button value={0}>Mapa</Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {buscaPorEndereco === 0 ? (
            <Alert
              message="Clique no mapa para indicar a localização do paciente."
              type="info"
              showIcon
              style={{ marginBottom: 20 }}
            />
          ) : null}
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={4}>
          <Field
            name="endereco.uf"
            label="UF"
            component={Select}
            showSearch
            filterOption={filterSelectOption}>
            {optionsUF}
          </Field>
        </Col>
        <Col span={15}>
          <Field
            name="endereco.municipio"
            label="Município"
            component={Select}
            showSearch
            filterOption={filterSelectOption}>
            {optionsMunicipio}
          </Field>
        </Col>
        <Col span={5}>
          <Field
            label="Código"
            name="endereco.codigo_ibge_municipio"
            component={Input}
          />
        </Col>
        {buscaPorEndereco === 1 ? (
          <Col span={24}>
            <ItemLabel forInput="edit_endereco">
              <SelectAddress
                placeholder="Digite o endereço para pesquisar"
                bbox={bbox}
                onSelect={handleChangeSelectAddress}
              />
            </ItemLabel>
          </Col>
        ) : null}
      </Row>
      <Row gutter={8}>
        <Col span={8}>
          <Field
            name="endereco.distrito"
            label="Distrito"
            component={Select}
            showSearch>
            {optionsDistrito}
          </Field>
        </Col>
        <Col span={16}>
          <Field name="endereco.bairro" label="Bairro" component={Input} />
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={19}>
          <Field
            name="endereco.logradouro"
            label="Logradouro"
            component={Input}
          />
        </Col>
        <Col span={5}>
          <Field
            name="endereco.codigo_logradouro"
            label="Código"
            component={Input}
          />
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={8}>
          <Field name="endereco.numero" label="Número" component={Input} />
        </Col>
        <Col span={16}>
          <Field
            name="endereco.complemento"
            label="Complemento"
            component={Input}
          />
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={16}>
          <Field
            name="endereco.ponto_referencia"
            label="Ponto de Referência"
            component={Input}
          />
        </Col>
        <Col span={8}>
          <Field name="endereco.cep" label="CEP" component={Input} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={11}>
          <Field
            name="endereco.aproximado"
            className="normal-lineHeight"
            component={Checkbox}>
            Endereço aproximado
          </Field>
        </Col>
        <Col span={13}>
          <Field
            name="pessoa_situacao_rua"
            className="normal-lineHeight"
            component={Checkbox}>
            Pessoa em situação de rua
          </Field>
        </Col>
      </Row>
    </>
  );
}
