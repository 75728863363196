import React, { useCallback, useEffect, useState, useRef } from 'react';

import { notification } from 'antd';
import { reverse } from 'services/enderecoService';
import Map, { Marker } from 'components/Map';
import estados from 'utils/municipios.json';
import distritoMap from 'utils/distritos.json';
import { useSelector, useDispatch, batch } from 'react-redux';
import { formValueSelector, change } from 'redux-form';
import { FORM } from './FORM';

const selector = formValueSelector(FORM);

export default function Mapa() {
  const buscaPorEndereco = useSelector(state => state.map.buscaPorEndereco);
  const municipio = useSelector(state => selector(state, 'endereco.municipio'));

  const geolocalizacao = useSelector(state =>
    selector(state, 'endereco.geolocalizacao')
  );

  const dispatch = useDispatch();
  const [map, setMap] = useState();

  const ref = useRef(buscaPorEndereco);

  useEffect(() => {
    ref.current = buscaPorEndereco;
  }, [buscaPorEndereco]);

  useEffect(() => {
    if (municipio && map) {
      const municipioSelecionado = estados
        .map(e => e.municipios)
        .flat()
        .find(m => m.id === municipio);

      if (municipioSelecionado) {
        map.fitBounds(municipioSelecionado.bbox);
      }
    }
  }, [map, municipio]);

  const onClickMap = useCallback(
    e => {
      async function fetch() {
        if (ref.current === 0) {
          const { data } = await reverse(e.lngLat.lng, e.lngLat.lat);
          const { center, ...rest } = data;

          if (!rest.uf) {
            notification.warn({
              message: 'Aviso',
              description:
                'Não foi possivel encontrar nenhum endereço neste local.',
            });
            return;
          }

          const estado = estados.find(f => f.sigla === rest.uf);

          // TODO: arrumar comparacao por nome
          const _municipio = estado.municipios.find(
            m => m.nome === data.municipio
          );

          const _distritos = distritoMap[_municipio.id];
          const _distrito =
            _distritos?.length === 1
              ? _distritos[0]
              : _distritos.find(d => d.nome === rest.distrito);

          batch(() => {
            dispatch(change(FORM, 'endereco.uf', estado.id));
            dispatch(change(FORM, 'endereco.bairro', rest.bairro));
            dispatch(change(FORM, 'endereco.cep', rest.cep));
            dispatch(change(FORM, 'endereco.logradouro', rest.logradouro));
            dispatch(change(FORM, 'endereco.numero', rest.numero));
            dispatch(
              change(FORM, 'endereco.codigo_ibge_municipio', _municipio.id)
            );
            dispatch(change(FORM, 'endereco.municipio', _municipio.id));
            dispatch(change(FORM, 'endereco.distrito', _distrito?.id));
            dispatch(change(FORM, 'endereco.geolocalizacao', center));
          });
        }
      }
      fetch();
    },
    [dispatch]
  );

  return (
    <>
      <Map onClick={onClickMap} setMapRef={setMap} />
      {geolocalizacao && (
        <Marker
          center={geolocalizacao}
          map={map}
          draggable
          onDragEnd={lngLat => {
            dispatch(
              change(FORM, 'endereco.geolocalizacao', [lngLat.lng, lngLat.lat])
            );
          }}
        />
      )}
    </>
  );
}
