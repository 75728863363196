import React, { useCallback } from 'react';
import { Icon, Modal } from 'antd';
import { ReactComponent as IconAssignOutline } from 'assets/icons/assignments-outline.svg';
import { ReactComponent as IconRelationship } from 'assets/icons/relationship.svg';
import { ReactComponent as IconInternado } from 'assets/icons/sent.svg';
import { EtapaItem } from 'components/EtapaItem';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from 'utils/Functions';
import CONSTANTES from 'utils/constantes';
// import MenuStep, { EVENT } from './MenuStep';
import { setEtapaTratamento } from 'store/ducks/etapa';
import { CircleIcon } from 'components/CircleIcon';
import moment from 'moment';
import { IconEtapa } from './styled';
import MenuStep from './MenuStep';

const id_tipo_etapaEtapaIcon = {
  1: IconRelationship,
  2: IconAssignOutline,
  4: IconInternado,
};

function StepCount({ index, size }) {
  return (
    <div className="step-count">
      <Icon type="check-circle" />
      <span>{`${+index + 1}/${+size}`}</span>
    </div>
  );
}

export default function Etapa({ item, tratamento, tag, step }) {
  const { nome, status, id_tipo_etapa: type } = item;
  const dispatch = useDispatch();

  const etapaStatus = status;

  const etapa = useSelector(state => state.etapa.etapa);
  const selected = etapa && etapa.id === item.id;

  function handleClickEtapaItem() {
    dispatch(setEtapaTratamento(item, tratamento));
  }

  // TODO: Para exibir a etapa com o aviso, o status deve ser 'warning'
  const showModalAlert = useCallback(e => {
    e.stopPropagation();
    Modal.confirm({
      icon: null,
      content:
        'Este paciente possui alergia. Você deseja selecionar um tratamento alternativo?',
      centered: true,
      className: 'modal-warning-fullred',
      okText: 'Selecionar',
      cancelText: 'Não representa risco',
      okButtonProps: { icon: 'right', type: 'default' },
      cancelButtonProps: { icon: 'close', ghost: true },
    });
  }, []);

  console.log('Etapa -> item.data_realizacao', item.data_realizacao);
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <IconEtapa selected={selected} status={etapaStatus}>
        <Icon component={id_tipo_etapaEtapaIcon[type]} />
      </IconEtapa>

      <EtapaItem
        onClick={handleClickEtapaItem}
        status={etapaStatus}
        selected={selected}
        type={type}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <CircleIcon status={status} />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <strong>{`${nome}`}</strong>
            <strong style={{ fontSize: 10 }}>{`${formatDate(
              moment(item.data_realizacao || 0)
            )}`}</strong>
          </div>
        </div>

        <div className="right-etapa">
          {tag}
          {step && <StepCount index={step[0]} size={step[1]} />}
          <MenuStep
            item={item}
            showDelete={status === CONSTANTES.ETAPA.STATUS.NAO_EXECUTADO}
          />
        </div>

        {etapaStatus === 'warning' && (
          <button
            type="button"
            onClick={showModalAlert}
            className="badge-alert">
            {' '}
          </button>
        )}
      </EtapaItem>
    </div>
  );
}
