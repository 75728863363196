import React from 'react';

import EtapaComum from './EtapaComum';
import EtapaInternacao from './EtapaTipoIsolamento/EtapaInternacao';
import EtapaQuarentena from './EtapaTipoIsolamento/EtapaQuarentena';
import EtapaTesteCovid from './EtapaTesteCovid';
import EtapaNotificacao from './EtapaNotificacao';
import EtapaAtualizacaoEstagio from './EtapaAtualizacaoEstagio/index';

function rswitch(param, cases) {
  if (cases[param]) {
    return cases[param];
  }
  return cases.default;
}

export default function EtapaDetalhe({ etapa }) {
  return rswitch(etapa?.id_etapa_modelo, {
    1: <EtapaNotificacao etapa={etapa} />,
    2: <EtapaInternacao etapa={etapa} />,
    8: <EtapaQuarentena etapa={etapa} />,
    9: <EtapaTesteCovid etapa={etapa} />,
    10: <EtapaAtualizacaoEstagio etapa={etapa} />,
    default: <EtapaComum etapa={etapa} />,
  });
}
