import React, { memo } from 'react';
import { List, Icon } from 'antd';
import { CircleDot } from 'components/CircleDot';
import { age } from 'utils/Functions';
import ButtonLocalizar from 'components/ButtonLocalizar';
import { ButtonTinyIcon } from 'components/Button';
import { useDispatch } from 'react-redux';
import { setPaciente } from 'store/ducks/localizar';
import { ReactComponent as IconAtalhoTratamento } from 'assets/icons/atalho-tratamento.svg';
import { useNavigate } from '@reach/router';
import { StyledPaciente } from './styled';

export const PacienteItem = memo(({ paciente }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <StyledPaciente>
      <List.Item.Meta
        title={
          <>
            <CircleDot position="left" color={paciente.estagio_clinico_cor} />
            <span>
              {paciente.nome} {paciente.sobrenome}
            </span>
            <strong>{age(paciente.data_nascimento) || '-'}</strong>
          </>
        }
        description={
          <strong>
            {paciente?.doencas_preexistentes?.join(', ') ||
              'Nenhuma doença preexistente'}
          </strong>
        }
      />
      <ButtonTinyIcon
        title="Ir para o tratamento"
        onClick={() => navigate(`/pacientes/${paciente.id}/tratamentos`)}>
        <Icon component={IconAtalhoTratamento} />
      </ButtonTinyIcon>
      <ButtonLocalizar
        title="Localizar no mapa"
        onClick={() => dispatch(setPaciente(paciente.id))}
      />
    </StyledPaciente>
  );
});
