import { Button, notification } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { findAll } from 'services/unidadeBasicaService';
import { reload, updateStatusEtapa } from 'store/ducks/etapa';
import constantes from 'utils/constantes';
import { internarPaciente } from 'store/ducks/paciente';
import SectionDescricao from '../../Sections/SectionDescricao';
import SectionUnidade from '../../Sections/SectionUnidade';
import Etapa from '../../TemplateEtapa';
import SectionQuadroClinico from '../../Sections/SectionQuadroClinico';

function Footer({ etapa, propriedades }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    async function execute() {
      setLoading(true);
      await dispatch(
        updateStatusEtapa(constantes.ETAPA.STATUS.EXECUTADO, propriedades)
      );
      await dispatch(internarPaciente(propriedades.unidade));
      await dispatch(reload());
      setLoading(false);
    }

    if (!propriedades.unidade) {
      notification.warn({
        message: 'Unidade de Internação não selecionada.',
      });
    } else {
      execute();
    }
  }, [dispatch, propriedades]);

  if (etapa.status === constantes.ETAPA.STATUS.NAO_EXECUTADO) {
    return (
      <Button
        loading={loading}
        className="btn-green"
        icon="check"
        onClick={handleClick}
        size="large">
        Confirmar isolamento
      </Button>
    );
  }
  return null;
}

export default function EtapaInternacao({ etapa }) {
  const [hospitais, setHospitais] = useState([]);
  const [propriedades, setPropriedades] = useState({});

  useEffect(() => {
    findAll({ internacao: true }).then(respHosp => {
      setHospitais(respHosp.data.filter(h => h.id !== -1));
    });
  }, []);

  const footer = useMemo(() => {
    return <Footer etapa={etapa} propriedades={propriedades} />;
  }, [etapa, propriedades]);

  const handleChangeProperties = useCallback(data => {
    setPropriedades(prev => ({ ...prev, ...data }));
  }, []);

  return (
    <Etapa
      etapa={etapa}
      sections={[
        <SectionDescricao etapa={etapa} />,
        <SectionUnidade
          etapa={etapa}
          hospitais={hospitais}
          onChange={handleChangeProperties}
        />,
        <SectionQuadroClinico
          etapa={etapa}
          onChange={handleChangeProperties}
        />,
      ]}
      footer={[footer]}
    />
  );
}
