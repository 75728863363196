import React from 'react';
import { Button, Typography } from 'antd';
import { navigate } from '@reach/router';
import { PacienteResumo } from 'components/Paciente';
import { useSelector } from 'react-redux';
import { HeaderPessoas } from './styled';

const { Title } = Typography;

export default function Header() {
  const paciente = useSelector(state => state.paciente.data);
  return (
    <HeaderPessoas>
      <div>
        <Button
          type="ghost"
          size="small"
          onClick={() => navigate('tratamentos')}
          className="btn-top-back">
          Voltar
        </Button>
        <Title level={3}>Pessoas Relacionadas</Title>
      </div>
      <PacienteResumo paciente={paciente} />
    </HeaderPessoas>
  );
}
