import React, { useEffect, useMemo, useCallback, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { findMunicipiosComEstagiosPorEstado } from 'services/estadosService';
import { removeSpecialChars } from 'utils/Functions';
import { fetchEstagiosEstado } from 'store/ducks/estado';
import { ButtonTransparent } from 'components/Button';
import { setMunicipio } from 'store/ducks/localizar';
import { setShowTimeline } from 'store/ducks/map';
import ListaMunicipio from './ListaMunicipio';
import { HeaderSider } from './HeaderSider';
import DetalheMunicipio from './DetalheMunicipio';
import {
  BorderedBox,
  Column,
  Row,
  RelativeContainer,
  StyledGraphs,
} from './styled';
import Mapa from './Mapa';
import Timeline from './Timeline';
// import Donut from './Donut';

const initialState = {
  loadingMunicipios: true,
  municipios: [],
  municipio: {},
  filterMunicipios: '',
  municipioVisible: false,
  timelineVisible: true,
  filterEstagios: {
    key: 'Todos',
    label: 'Todos',
  },
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_MUNICIPIOS':
      return {
        ...state,
        municipios: action.payload.municipios,
        loadingMunicipios: false,
      };
    case 'SET_FILTER_MUNICIPIO':
      return {
        ...state,
        filterMunicipios: action.payload.filter,
      };
    case 'SELECT_MUNICIPIO':
      return {
        ...state,
        municipio: action.payload.municipio,
        municipioVisible: true,
      };
    case 'CHANGE_FILTER':
      return {
        ...state,
        filterEstagios: action.payload.filter,
      };
    case 'TOGGLE_TIMELINE_VISIBLE':
      return {
        ...state,
        timelineVisible: action.payload.visible,
      };
    case 'CLEAR_MUNICIPIO':
      return {
        ...state,
        municipio: {},
        municipioVisible: false,
      };
    default:
      throw Error();
  }
}

export default function Dashboard() {
  const usuario = useSelector(state => state.usuario);
  const reduxDispatch = useDispatch();
  const timelineVisible = useSelector(state => state.map.showTimeline);

  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    loadingMunicipios,
    municipios,
    filterMunicipios,
    municipio,
    municipioVisible,
    filterEstagios, // TODO: Passar na request o estagio filtrado
  } = state;

  useEffect(() => {
    if (usuario?.id) {
      const idMunicipio = usuario.id_uf || 42;
      reduxDispatch(fetchEstagiosEstado(idMunicipio, 2));
      findMunicipiosComEstagiosPorEstado(idMunicipio, 2).then(({ data = [] }) =>
        dispatch({ type: 'SET_MUNICIPIOS', payload: { municipios: data } })
      );
    }
  }, [reduxDispatch, usuario, usuario.id_uf]);

  const filteredMunicipios = useMemo(
    () =>
      municipios.filter(m =>
        removeSpecialChars(m.nome).includes(
          removeSpecialChars(filterMunicipios)
        )
      ),
    [filterMunicipios, municipios]
  );

  const handleSearchMunicipios = useCallback(e => {
    const value = e.target.value;
    dispatch({ type: 'SET_FILTER_MUNICIPIO', payload: { filter: value } });
  }, []);

  const handleSelectMunicipio = useCallback(
    value => {
      dispatch({ type: 'SELECT_MUNICIPIO', payload: { municipio: value } });
      reduxDispatch(setMunicipio(value.id));
    },
    [reduxDispatch]
  );

  const handleChangeFilter = useCallback(filter => {
    dispatch({ type: 'CHANGE_FILTER', payload: { filter } });
  }, []);

  const onCloseDrawer = useCallback(() => {
    dispatch({ type: 'CLEAR_MUNICIPIO' });
    reduxDispatch(setMunicipio());
  }, [reduxDispatch]);

  const handleHideTimeline = useCallback(
    visible => {
      reduxDispatch(setShowTimeline(visible));
    },
    [reduxDispatch]
  );

  const graficos = useMemo(() => {
    return (
      <StyledGraphs visible={timelineVisible}>
        <ButtonTransparent
          icon={timelineVisible ? 'down' : 'up'}
          onClick={() => handleHideTimeline(!timelineVisible)}
        />
        <Timeline
          municipioSelecionado={municipio?.id}
          estadoSelecionado={usuario.id_uf || 42}
          doencaSelecionada={2}
        />
      </StyledGraphs>
    );
  }, [handleHideTimeline, municipio, timelineVisible, usuario.id_uf]);

  const header = useMemo(() => {
    return (
      <Row flex="unset">
        <HeaderSider
          filterEstagios={filterEstagios}
          onSearchMunicipio={handleSearchMunicipios}
          onChangeFilter={handleChangeFilter}
        />
      </Row>
    );
  }, [filterEstagios, handleChangeFilter, handleSearchMunicipios]);

  return (
    <>
      <BorderedBox>
        <Column sider width={370}>
          {header}
          <Row flex={2}>
            <ListaMunicipio
              loading={loadingMunicipios}
              selectedKey={municipio?.id}
              onSelectMunicipio={handleSelectMunicipio}
              municipios={filteredMunicipios}
            />
          </Row>
        </Column>
        <Column flex={3}>
          <Row type="flex" flex={1}>
            <DetalheMunicipio
              visible={municipioVisible}
              onClose={onCloseDrawer}
              municipio={municipio}
            />
            <RelativeContainer>
              {graficos}
              <Mapa />
            </RelativeContainer>
          </Row>
        </Column>
      </BorderedBox>
    </>
  );
}
