import {
  Col,
  Divider,
  Form,
  Input,
  Row,
  Typography,
  Select,
  DatePicker,
  Button,
  Icon,
} from 'antd';
import { ItemLabel } from 'components/Form';
import React, { useCallback, useState, useEffect } from 'react';
import moment from 'moment';
import { ReactComponent as IconSent } from 'assets/icons/sent.svg';
import { GreyCollapse, Panel } from 'components/Collapse';
import * as notificacaoService from 'services/notificacaoService';
import { useSelector, useDispatch } from 'react-redux';
import { age } from 'utils/Functions';
import { getById } from 'services/unidadeBasicaService';
import StyledTag from 'components/Tag';
import estados from 'utils/municipios.json';
import distritoMap from 'utils/distritos.json';
import {
  TempoGestacaoEnum,
  RacaCorEnum,
  EscolaridadeEnum,
  SexoEnum,
} from 'enums';
import CONSTANTES from 'utils/constantes';
import { openModal } from 'store/ducks/modal';
import Modals from 'modals';
import { StyledEtapaDetalhe } from '../styled';

const { Title } = Typography;

const getNotificacaoInicial = ({
  estado,
  paciente,
  doenca,
  usuario,
  unidadeBasicaUsuario,
}) => {
  const notificacao = {
    dados_gerais: { tipo_notificacao: 2 },
    notificacao_individual: {},
    dados_residencia: {},
    notificante: {},
    dados_complementares: {},
    local_provavel: {},
  };
  const municipio = estado?.municipios?.find(
    m => m.id === paciente?.endereco?.municipio
  );
  const distrito = distritoMap[municipio.id].find(
    d => d.id === paciente.endereco.distrito
  );
  notificacao.dados_gerais.doenca = doenca.nome;
  const estadoNotificacao = estados.find(e => e.id === Number(usuario.id_uf));

  notificacao.dados_gerais.uf = estadoNotificacao?.sigla;

  const municipioNotificacao = estadoNotificacao?.municipios?.find(
    m => m.id === Number(usuario.id_municipio)
  );
  notificacao.dados_gerais.municipio_nome = municipioNotificacao?.nome;
  notificacao.dados_gerais.municipio_codigo = municipioNotificacao?.id;
  notificacao.dados_gerais.unidade_saude = unidadeBasicaUsuario.nome;
  notificacao.dados_gerais.codigo_unidade_saude = unidadeBasicaUsuario.codigo;
  notificacao.notificacao_individual.nome_paciente = `${paciente.nome.trim()} ${
    paciente.sobrenome
    }`;
  notificacao.notificacao_individual.data_nascimento = paciente.data_nascimento;
  notificacao.notificacao_individual.idade = age(paciente.data_nascimento);
  notificacao.notificacao_individual.sexo = paciente.sexo;
  notificacao.notificacao_individual.tempo_gestacao = paciente.tempo_gestacao;
  notificacao.notificacao_individual.raca_cor = paciente.raca_cor;
  notificacao.notificacao_individual.escolaridade = paciente.escolaridade;
  notificacao.notificacao_individual.cartao_sus = paciente.n_cartao_sus;
  notificacao.notificacao_individual.nome_mae = paciente.nome_mae;
  notificacao.dados_residencia.paciente_uf = estado.sigla;
  notificacao.dados_residencia.paciente_municipio = municipio.nome;
  notificacao.dados_residencia.paciente_codigo_ibge_municipio = municipio.id;
  notificacao.dados_residencia.paciente_cep = paciente?.endereco?.cep;
  notificacao.dados_residencia.paciente_ponto_referencia =
    paciente?.endereco?.ponto_referencia;
  notificacao.dados_residencia.paciente_distrito = distrito?.nome;
  notificacao.dados_residencia.paciente_bairro = paciente?.endereco?.bairro;
  notificacao.dados_residencia.paciente_logradouro =
    paciente?.endereco?.logradouro;
  notificacao.dados_residencia.paciente_endereco_numero =
    paciente?.endereco?.numero;
  notificacao.dados_residencia.paciente_endereco_complemento =
    paciente?.endereco?.complemento;
  notificacao.notificante.municipio_unidade_saude = 'verificar qual o valor';
  notificacao.notificante.notificante_nome = 'verificar qual o valor';
  notificacao.notificante.notificante_funcao = 'verificar qual o valor';

  return notificacao;
};

function EtapaNotificacao({ form }) {
  const { getFieldDecorator, validateFields } = form;
  const activeDoencaKey = useSelector(state => state.paciente.activeDoencaKey);
  const doenca = useSelector(state => state.paciente.doencas[activeDoencaKey]);
  const paciente = useSelector(state => state.paciente.data);
  const idTratamento = useSelector(state => state.etapa.etapa.id_tratamento);
  const usuario = useSelector(state => state.usuario);
  const [unidadeBasicaUsuario, setUnidadeBasicaUsuario] = useState({});
  const estado = estados.find(e => e.id === paciente?.endereco?.uf);
  const notificacao = getNotificacaoInicial({
    estado,
    paciente,
    doenca,
    usuario,
    unidadeBasicaUsuario,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    notificacaoService.get();
  }, []);

  useEffect(() => {
    if (usuario.id_unidade_basica) {
      getById(usuario.id_unidade_basica).then(resp => {
        setUnidadeBasicaUsuario(resp.data);
      });
    }
  }, [usuario.id_unidade_basica]);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values, notificacao);

          notificacao.dados_complementares = {
            data_primeira_coleta_amostra: values.data_primeira_coleta_amostra,
            data_primeira_coleta_outra_amostra:
              values.data_primeira_coleta_outra_amostra,
            especificar_tipo_exame: values.especificar_tipo_exame,
            obito: values.obito,
            contato_caso_semelhante: values.contato_caso_semelhante,
            presenca_exantema: values.presenca_exantema,
            data_inicio_exatema: values.data_inicio_exatema,
            presenca_petequias_sufoes: values.presenca_petequias_sufoes,
            realizado_liquor: values.realizado_liquor,
            resultado_bacterioscopia: values.resultado_bacterioscopia,
            tomou_vacina_contra_agravo: values.tomou_vacina_contra_agravo,
            data_ultima_dose_tomada: values.data_ultima_dose_tomada,
            ocorreu_hospitalizacao: values.ocorreu_hospitalizacao,
            data_hospitalizacao: values.data_hospitalizacao,
            hospital_uf: values.hospital_uf,
            hospital_municipio: values.hospital_municipio,
            hospital_municipio_codigo: values.hospital_municipio_codigo,
            hospital_nome: values.hospital_nome,
            hospital_codigo: values.hospital_codigo,
          };

          notificacao.local_provavel = {
            infeccao_pais: values.infeccao_pais,
            infeccao_uf: values.infeccao_uf,
            infeccao_municipio: values.infeccao_municipio,
            infeccao_distrito: values.infeccao_distrito,
            infeccao_bairro: values.infeccao_bairro,
          };

          notificacaoService
            .save(idTratamento, notificacao)
            .then(a => console.log(a));
        }
      });
    },
    [idTratamento, notificacao, validateFields]
  );

  const handleOpenModal = useCallback(() => {
    dispatch(openModal(Modals.ModalAssinatura));
  }, [dispatch]);

  return (
    <StyledEtapaDetalhe>
      <Row type="flex" justify="space-between">
        <Col>
          <Title level={3}>Notificação</Title>
        </Col>
        <Col>
          <StyledTag type="pendente">Assinatura pendente</StyledTag>
        </Col>
      </Row>
      <Divider />
      <Form onSubmit={handleSubmit}>
        <GreyCollapse defaultActiveKey={['1']}>
          {/* INICIO - Dados Gerais */}
          <Panel header="Dados Gerais" key="1">
            <Row gutter={8}>
              <Col span={8}>
                <ItemLabel label="Tipo notificação">
                  <Input
                    size="large"
                    readOnly
                    value={
                      { 2: 'Individual' }[
                      notificacao.dados_gerais.tipo_notificacao
                      ]
                    }
                  />
                </ItemLabel>
              </Col>
              <Col span={8}>
                <ItemLabel label="Agravo/doença">
                  <Input
                    size="large"
                    readOnly
                    value={notificacao.dados_gerais.doenca}
                  />
                </ItemLabel>
              </Col>
              <Col span={8}>
                <ItemLabel>
                  {getFieldDecorator('data_notificacao', {
                    initialValue: notificacao.data_notificacao
                      ? moment(notificacao.dados_gerais.data_notificacao)
                      : moment(),
                  })(
                    <DatePicker
                      placeholder="Data da notificação"
                      size="large"
                      style={{ width: '100%' }}
                    />
                  )}
                </ItemLabel>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={4}>
                <ItemLabel label="UF">
                  <Input
                    size="large"
                    readOnly
                    value={notificacao.dados_gerais.uf}
                  />
                </ItemLabel>
              </Col>
              <Col span={14}>
                <ItemLabel label="Município de Notificação">
                  <Input
                    size="large"
                    readOnly
                    value={notificacao.dados_gerais.municipio_nome}
                  />
                </ItemLabel>
              </Col>
              <Col span={6}>
                <ItemLabel label="Código IBGE">
                  <Input
                    size="large"
                    readOnly
                    value={notificacao.dados_gerais.municipio_codigo}
                  />
                </ItemLabel>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <ItemLabel label="Unidade de Saúde">
                  <Input
                    size="large"
                    readOnly
                    value={notificacao.dados_gerais.unidade_saude}
                  />
                </ItemLabel>
              </Col>
              <Col span={6}>
                <ItemLabel label="Código">
                  <Input
                    size="large"
                    readOnly
                    value={notificacao.dados_gerais.codigo_unidade_saude}
                  />
                </ItemLabel>
              </Col>
              <Col span={6}>
                <ItemLabel>
                  {getFieldDecorator('data_primeiros_sintomas', {
                    initialValue: notificacao.dados_gerais
                      .data_primeiros_sintomas
                      ? moment(notificacao.dados_gerais.data_primeiros_sintomas)
                      : undefined,
                  })(
                    <DatePicker
                      placeholder="Data dos primeiros sintomas"
                      size="large"
                      style={{ width: '100%' }}
                    />
                  )}
                </ItemLabel>
              </Col>
            </Row>
          </Panel>
          {/* FIM - Dados Gerais */}

          {/* INICIO - Notificação Individual */}
          <Panel header="Notificação Individual" key="2">
            <Row gutter={8}>
              <Col span={16}>
                <ItemLabel label="Nome do Paciente">
                  <Input
                    size="large"
                    readOnly
                    value={notificacao.notificacao_individual.nome_paciente}
                  />
                </ItemLabel>
              </Col>
              <Col span={8}>
                <ItemLabel label="Idade">
                  {getFieldDecorator('idade', {
                    initialValue: notificacao.notificacao_individual.idade,
                    rules: [
                      {
                        required: !!notificacao.notificacao_individual
                          .data_nascimento,
                        message: 'Campo obrigatório',
                      },
                    ],
                  })(
                    <Input
                      size="large"
                      readOnly={
                        !!notificacao.notificacao_individual.data_nascimento
                      }
                    />
                  )}
                </ItemLabel>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={8}>
                <ItemLabel label="Sexo">
                  <Input
                    size="large"
                    readOnly
                    value={SexoEnum[notificacao.notificacao_individual.sexo]}
                  />
                </ItemLabel>
              </Col>
              <Col span={8}>
                <ItemLabel label="Gestante">
                  <Input
                    size="large"
                    readOnly
                    value={
                      TempoGestacaoEnum[
                      notificacao.notificacao_individual.tempo_gestacao
                      ]
                    }
                  />
                </ItemLabel>
              </Col>
              <Col span={8}>
                <ItemLabel label="Raça/Cor">
                  <Input
                    size="large"
                    readOnly
                    value={
                      RacaCorEnum[notificacao.notificacao_individual.raca_cor]
                    }
                  />
                </ItemLabel>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={24}>
                <ItemLabel label="Escolaridade">
                  <Input
                    size="large"
                    readOnly
                    value={
                      EscolaridadeEnum[
                      notificacao.notificacao_individual.escolaridade
                      ]
                    }
                  />
                </ItemLabel>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={8}>
                <ItemLabel label="Número do Cartão SUS">
                  <Input
                    size="large"
                    readOnly
                    value={notificacao.notificacao_individual.cartao_sus}
                  />
                </ItemLabel>
              </Col>
              <Col span={16}>
                <ItemLabel label="Nome da mãe">
                  <Input
                    size="large"
                    readOnly
                    value={notificacao.notificacao_individual.nome_mae}
                  />
                </ItemLabel>
              </Col>
            </Row>
          </Panel>
          {/* FIM - Notificação Individual */}

          {/* INICIO - Dados de Residência */}
          <Panel header="Dados de Residência" key="4">
            <Row gutter={8}>
              <Col span={4}>
                <ItemLabel label="UF">
                  <Input
                    size="large"
                    value={notificacao.dados_residencia.paciente_uf}
                    readOnly
                  />
                </ItemLabel>
              </Col>
              <Col span={10}>
                <ItemLabel label="Município de Residência">
                  <Input
                    size="large"
                    value={notificacao.dados_residencia.paciente_municipio}
                    readOnly
                  />
                </ItemLabel>
              </Col>
              <Col span={4}>
                <ItemLabel label="Código">
                  <Input
                    size="large"
                    value={
                      notificacao.dados_residencia
                        .paciente_codigo_ibge_municipio
                    }
                    readOnly
                  />
                </ItemLabel>
              </Col>
              <Col span={6}>
                <ItemLabel label="Distrito">
                  <Input
                    size="large"
                    value={notificacao.dados_residencia.paciente_distrito}
                    readOnly
                  />
                </ItemLabel>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={8}>
                <ItemLabel label="Bairro">
                  <Input
                    size="large"
                    value={notificacao.dados_residencia.paciente_bairro}
                    readOnly
                  />
                </ItemLabel>
              </Col>
              <Col span={12}>
                <ItemLabel label="Logradouro">
                  <Input
                    size="large"
                    value={notificacao.dados_residencia.paciente_logradouro}
                    readOnly
                  />
                </ItemLabel>
              </Col>
              <Col span={4}>
                <ItemLabel label="Código">
                  <Input
                    size="large"
                    value={
                      notificacao.dados_residencia.paciente_codigo_logradouro
                    }
                    readOnly
                  />
                </ItemLabel>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={8}>
                <ItemLabel label="Número">
                  <Input
                    size="large"
                    value={
                      notificacao.dados_residencia.paciente_endereco_numero
                    }
                    readOnly
                  />
                </ItemLabel>
              </Col>
              <Col span={16}>
                <ItemLabel label="Complemento">
                  <Input
                    size="large"
                    value={
                      notificacao.dados_residencia.paciente_endereco_complemento
                    }
                    readOnly
                  />
                </ItemLabel>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <ItemLabel label="Geo campo 1">
                  <Input
                    size="large"
                    value={notificacao.dados_residencia.paciente_geo1}
                  />
                </ItemLabel>
              </Col>
              <Col span={12}>
                <ItemLabel label="Geo campo 2">
                  <Input
                    size="large"
                    value={notificacao.dados_residencia.paciente_geo2}
                  />
                </ItemLabel>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={16}>
                <ItemLabel label="Ponto de Referência">
                  <Input
                    size="large"
                    value={
                      notificacao.dados_residencia.paciente_ponto_referencia
                    }
                    readOnly
                  />
                </ItemLabel>
              </Col>
              <Col span={8}>
                <ItemLabel label="CEP">
                  <Input
                    size="large"
                    value={notificacao.dados_residencia.paciente_cep}
                    readOnly
                  />
                </ItemLabel>
              </Col>
            </Row>
          </Panel>
          {/* FIM - Dados de Residência */}

          {/* INICIO - Notificante */}
          <Panel header="Notificante" key="5">
            <Row gutter={8}>
              <Col span={24}>
                <ItemLabel label="RF_Município/Unidade de Saúde">
                  <Input
                    size="large"
                    readOnly
                    value={notificacao.notificante.municipio_unidade_saude}
                  />
                </ItemLabel>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={14}>
                <ItemLabel label="RF_Nome do Notificante">
                  <Input
                    size="large"
                    readOnly
                    value={notificacao.notificante.notificante_nome}
                  />
                </ItemLabel>
              </Col>
              <Col span={10}>
                <ItemLabel label="RF_Função do Notificante">
                  <Input
                    size="large"
                    readOnly
                    value={notificacao.notificante.notificante_funcao}
                  />
                </ItemLabel>
              </Col>
            </Row>
          </Panel>
          {/* FIM - Notificante */}

          {/* INICIO - Notificação Individual (dados complementares) */}
          <Panel header="Notificação Individual (dados complementares)" key="6">
            <Row gutter={8}>
              <Col span={12}>
                <ItemLabel>
                  {getFieldDecorator('data_primeira_coleta_amostra', {
                    initialValue: notificacao.dados_complementares
                      .data_primeira_coleta_amostra
                      ? moment(
                        notificacao.dados_complementares
                          .data_primeira_coleta_amostra
                      )
                      : undefined,
                  })(
                    <DatePicker
                      placeholder="Data da coleta da 1ª amostra da sorologia"
                      size="large"
                      style={{ width: '100%' }}
                    />
                  )}
                </ItemLabel>
              </Col>
              <Col span={12}>
                <ItemLabel>
                  {getFieldDecorator('data_primeira_coleta_outra_amostra', {
                    initialValue: notificacao.dados_complementares
                      .data_primeira_coleta_outra_amostra
                      ? moment(
                        notificacao.dados_complementares
                          .data_primeira_coleta_outra_amostra
                      )
                      : undefined,
                  })(
                    <DatePicker
                      placeholder="Data da coleta da 1ª amostra de outra amostra"
                      size="large"
                      style={{ width: '100%' }}
                    />
                  )}
                </ItemLabel>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={24}>
                <ItemLabel label="Especificar tipo de exame">
                  {getFieldDecorator('especificar_tipo_exame', {
                    initialValue:
                      notificacao.dados_complementares.especificar_tipo_exame,
                  })(<Input size="large" />)}
                </ItemLabel>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <ItemLabel label="Óbito ?">
                  {getFieldDecorator('obito', {
                    initialValue: notificacao.dados_complementares.obito,
                  })(
                    <Select size="large">
                      <Select.Option key="1">Sim</Select.Option>
                      <Select.Option key="2">Não</Select.Option>
                      <Select.Option key="3">Ignorado</Select.Option>
                    </Select>
                  )}
                </ItemLabel>
              </Col>
              <Col span={12}>
                <ItemLabel label="Contato com caso semelhante ?">
                  {getFieldDecorator('contato_caso_semelhante', {
                    initialValue:
                      notificacao.dados_complementares.contato_caso_semelhante,
                  })(
                    <Select size="large">
                      <Select.Option key="1">Sim</Select.Option>
                      <Select.Option key="2">Não</Select.Option>
                      <Select.Option key="3">Ignorado</Select.Option>
                    </Select>
                  )}
                </ItemLabel>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={8}>
                <ItemLabel label="Presença de exantema ?">
                  {getFieldDecorator('presenca_exantema', {
                    initialValue:
                      notificacao.dados_complementares.presenca_exantema,
                  })(
                    <Select size="large">
                      <Select.Option key="1">Sim</Select.Option>
                      <Select.Option key="2">Não</Select.Option>
                      <Select.Option key="9">Ignorado</Select.Option>
                    </Select>
                  )}
                </ItemLabel>
              </Col>
              <Col span={6}>
                <ItemLabel>
                  {getFieldDecorator('data_inicio_exatema', {
                    initialValue: notificacao.dados_complementares
                      .data_inicio_exatema
                      ? moment(
                        notificacao.dados_complementares.data_inicio_exatema
                      )
                      : undefined,
                  })(
                    <DatePicker
                      placeholder="Data do início do exatema"
                      size="large"
                      style={{ width: '100%' }}
                    />
                  )}
                </ItemLabel>
              </Col>
              <Col span={10}>
                <ItemLabel label="Presença de petéquias ou sufusões hemorrágicas ?">
                  {getFieldDecorator('presenca_petequias_sufoes', {
                    initialValue:
                      notificacao.dados_complementares
                        .presenca_petequias_sufoes,
                  })(
                    <Select size="large">
                      <Select.Option key="1">Sim</Select.Option>
                      <Select.Option key="2">Não</Select.Option>
                      <Select.Option key="9">Ignorado</Select.Option>
                    </Select>
                  )}
                </ItemLabel>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={8}>
                <ItemLabel label="Foi realizado líquor ?">
                  {getFieldDecorator('realizado_liquor', {
                    initialValue:
                      notificacao.dados_complementares.realizado_liquor,
                  })(
                    <Select size="large">
                      <Select.Option key="1">Sim</Select.Option>
                      <Select.Option key="2">Não</Select.Option>
                      <Select.Option key="9">Ignorado</Select.Option>
                    </Select>
                  )}
                </ItemLabel>
              </Col>
              <Col span={16}>
                <ItemLabel label="Resultado da bacterioscopia">
                  {getFieldDecorator('resultado_bacterioscopia', {
                    initialValue:
                      notificacao.dados_complementares.resultado_bacterioscopia,
                  })(<Input size="large" />)}
                </ItemLabel>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={16}>
                <ItemLabel label="O paciente tomou vacina contra agravo notificado neste impresso?">
                  {getFieldDecorator('tomou_vacina_contra_agravo', {
                    initialValue:
                      notificacao.dados_complementares
                        .tomou_vacina_contra_agravo,
                  })(
                    <Select size="large">
                      <Select.Option key="1">Sim</Select.Option>
                      <Select.Option key="2">Não</Select.Option>
                      <Select.Option key="9">Ignorado</Select.Option>
                    </Select>
                  )}
                </ItemLabel>
              </Col>
              <Col span={8}>
                <ItemLabel>
                  {getFieldDecorator('data_ultima_dose_tomada', {
                    initialValue: notificacao.dados_complementares
                      .data_ultima_dose_tomada
                      ? moment(
                        notificacao.dados_complementares
                          .data_ultima_dose_tomada
                      )
                      : undefined,
                  })(
                    <DatePicker
                      placeholder="Data da última dose tomada"
                      size="large"
                      style={{ width: '100%' }}
                    />
                  )}
                </ItemLabel>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={10}>
                <ItemLabel label="Ocorreu hospitalização ?">
                  {getFieldDecorator('ocorreu_hospitalizacao', {
                    initialValue:
                      notificacao.dados_complementares.ocorreu_hospitalizacao,
                  })(
                    <Select size="large">
                      <Select.Option key="1">Sim</Select.Option>
                      <Select.Option key="2">Não</Select.Option>
                      <Select.Option key="9">Ignorado</Select.Option>
                    </Select>
                  )}
                </ItemLabel>
              </Col>
              <Col span={14}>
                <ItemLabel>
                  {getFieldDecorator('data_hospitalizacao', {
                    initialValue: notificacao.dados_complementares
                      .data_hospitalizacao
                      ? moment(
                        notificacao.dados_complementares.data_hospitalizacao
                      )
                      : undefined,
                  })(
                    <DatePicker
                      placeholder="Data da hospitalização"
                      size="large"
                      style={{ width: '100%' }}
                    />
                  )}
                </ItemLabel>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={4}>
                <ItemLabel label="UF">
                  {getFieldDecorator('hospital_uf', {
                    initialValue: notificacao.dados_complementares.hospital_uf,
                  })(<Input size="large" />)}
                </ItemLabel>
              </Col>
              <Col span={14}>
                <ItemLabel label="Munícipio do hospital">
                  {getFieldDecorator('hospital_municipio', {
                    initialValue:
                      notificacao.dados_complementares.hospital_municipio,
                  })(<Input size="large" />)}
                </ItemLabel>
              </Col>
              <Col span={6}>
                <ItemLabel label="Código (IBGE)">
                  {getFieldDecorator('hospital_municipio_codigo', {
                    initialValue:
                      notificacao.dados_complementares
                        .hospital_municipio_codigo,
                  })(<Input size="large" />)}
                </ItemLabel>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={18}>
                <ItemLabel label="Nome do hospital">
                  {getFieldDecorator('hospital_nome', {
                    initialValue:
                      notificacao.dados_complementares.hospital_nome,
                  })(<Input size="large" />)}
                </ItemLabel>
              </Col>
              <Col span={6}>
                <ItemLabel label="Código">
                  {getFieldDecorator('hospital_codigo', {
                    initialValue:
                      notificacao.dados_complementares.hospital_codigo,
                  })(<Input size="large" />)}
                </ItemLabel>
              </Col>
            </Row>
          </Panel>
          {/* FIM - Notificação Individual (dados complementares) */}

          {/* INICIO - Local provável de infecção (classificação provisória) */}
          <Panel
            header="Local provável de infecção (classificação provisória)"
            key="8">
            <Row gutter={8}>
              <Col span={6}>
                <ItemLabel label="País">
                  {getFieldDecorator('infeccao_pais', {
                    initialValue: notificacao.local_provavel.infeccao_pais,
                  })(<Input size="large" />)}
                </ItemLabel>
              </Col>
              <Col span={4}>
                <ItemLabel label="UF">
                  {getFieldDecorator('infeccao_uf', {
                    initialValue: notificacao.local_provavel.infeccao_uf,
                  })(<Input size="large" />)}
                </ItemLabel>
              </Col>
              <Col span={14}>
                <ItemLabel label="Município">
                  {getFieldDecorator('infeccao_municipio', {
                    initialValue: notificacao.local_provavel.infeccao_municipio,
                  })(<Input size="large" />)}
                </ItemLabel>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={8}>
                <ItemLabel label="Distrito">
                  {getFieldDecorator('infeccao_distrito', {
                    initialValue: notificacao.local_provavel.infeccao_distrito,
                  })(<Input size="large" />)}
                </ItemLabel>
              </Col>
              <Col span={16}>
                <ItemLabel label="Bairro">
                  {getFieldDecorator('infeccao_bairro', {
                    initialValue: notificacao.local_provavel.infeccao_bairro,
                  })(<Input size="large" />)}
                </ItemLabel>
              </Col>
            </Row>
          </Panel>
        </GreyCollapse>
        <Row type="flex" justify="end" gutter={16}>
          <Col>
            <Button size="large" htmlType="button">
              <Icon type="cloud-download" />
              <strong>Baixar notificação</strong>
            </Button>
          </Col>
          {usuario.id_perfil === CONSTANTES.PERFIL.AGENTE ? (
            <Col>
              <Button size="large" type="primary" htmlType="submit">
                <Icon component={IconSent} />
                <strong>Enviar</strong>
              </Button>
            </Col>
          ) : (
              <Col>
                <Button
                  size="large"
                  type="primary"
                  htmlType="button"
                  onClick={handleOpenModal}>
                  <Icon type="edit" />
                  <strong>Assinar notificação</strong>
                </Button>
              </Col>
            )}
        </Row>
      </Form>
    </StyledEtapaDetalhe>
  );
}

export default Form.create({ name: 'notificacao_tratamento' })(
  EtapaNotificacao
);
