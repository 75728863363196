/* eslint-disable no-param-reassign */
import axios from 'axios';
import { getToken, logout } from 'storage/auth';
import { navigate } from '@reach/router';

const api = axios.create({
  baseURL: '/api',
});

api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers['x-access-token'] = token;
  }
  return config;
});

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      logout();
      navigate('/login');
    }

    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
);

export default api;

export function popularBanco() {
  api.get('/leite');
}
