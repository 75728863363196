import { Button } from 'antd';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { update } from 'services/etapaService';
import { updateStatusEtapa } from 'store/ducks/etapa';
import { fetchEtapas } from 'store/ducks/paciente';

export function ButtonAplicacao({ etapa, descricao, status, ...rest }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleClick = useCallback(() => {
    setLoading(true);
    update(etapa.id, { status })
      .then(({ data }) => {
        dispatch(updateStatusEtapa(data.status));
      })
      .finally(() => {
        dispatch(fetchEtapas(etapa.id_tratamento));
        setLoading(false);
      });
  }, [dispatch, etapa.id, etapa.id_tratamento, status]);
  return (
    <Button onClick={handleClick} loading={loading} size="large" {...rest}>
      {descricao}
    </Button>
  );
}
