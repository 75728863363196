import React, { useCallback, useEffect, useState } from 'react';
import { BorderedBox } from 'components/Layout';
import { Typography, notification } from 'antd';
import { update, save, getById } from 'services/usuarioService';
import FormCadastro from './FormCadastro';

const { Title } = Typography;

function CadastroUsuario({ navigate, usuarioId, meuPerfil }) {
  const [loading, setLoading] = useState(false);
  const [usuario, setUsuario] = useState([]);

  useEffect(() => {
    if (usuarioId) {
      setLoading(true);
      getById(usuarioId)
        .then(({ data }) => setUsuario(data))
        .finally(() => setLoading(false));
    }
  }, [usuarioId]);

  const handleSave = useCallback(
    (idUsuario, formData) => {
      setLoading(true);
      const service = idUsuario ? update(idUsuario, formData) : save(formData);

      service
        .then(({ data }) => {
          notification.success({
            message: 'Usuário salvo com sucesso!',
          });
          !usuarioId && navigate(`/usuarios/${data.id}/editar`);
        })
        .catch(() =>
          notification.error({
            message: 'Ocorreu um erro ao salvar o cadastro',
          })
        )
        .finally(() => setLoading(false));
    },
    [navigate, usuarioId]
  );

  return (
    <>
      <Title level={3}>{usuarioId ? 'Edição' : 'Cadastro'} de Usuário</Title>
      <BorderedBox>
        <FormCadastro
          meuPerfil={meuPerfil}
          usuario={usuario}
          loading={loading}
          onSave={handleSave}
        />
      </BorderedBox>
    </>
  );
}

export default CadastroUsuario;
