import {
  Spin,
  Avatar,
  Button,
  Col,
  Form,
  Input,
  notification,
  Row,
  Select,
  Upload,
} from 'antd';
import { ItemLabel, ProfilePicture } from 'components/Form';
import MaskInput from 'react-maskinput';
import React, { useCallback, useMemo, useReducer, useEffect } from 'react';

import { getBase64 } from 'utils/files';
import { filterSelectOption, validarCPF } from 'utils/Functions';

import estados from 'utils/municipios.json';

import { ScrolledContent } from 'components/Layout/content';
import { CircleDot } from 'components/CircleDot';
import { StatusUsuarioEnum, StatusColorsEnum } from 'enums/StatusUsuarioEnum';
import { findAll } from 'services/unidadeBasicaService';
import { getToken } from 'storage/auth';
import { useMatch } from '@reach/router';

const initialState = {
  endereco: {},
  ubs: [],
  confirmDirty: false,
  imageUrl: undefined,
};

function validatorCPF(rule, value, callback) {
  if (validarCPF(value) || value === '') {
    callback();
  }
  callback('CPF inválido!');
}

function normalize(value) {
  return value === null || value === '' ? undefined : value;
}

function reducer(state, action) {
  switch (action.type) {
    case 'SET_IMAGE_USUARIO':
      return { ...state, imageUrl: action.payload.image };
    case 'SET_ENDERECO':
      return { ...state, endereco: action.payload.endereco };
    case 'SET_DATA':
      return {
        ...state,
        endereco: {
          ...state.endereco,
          ...action.payload.endereco,
        },
        ubs: action.payload.ubs,
      };
    case 'SET_DIRTY':
      return { ...state, confirmDirty: action.payload.confirmDirty };
    default:
      throw new Error();
  }
}

/**
 * Form cadastro de usuario
 *
 * @param {Object} { form, usuarioId, height, onSave = () => {} }
 * @returns <React.Component>
 */
function FormCadastro({
  form,
  usuario,
  height,
  onSave = () => {},
  loading,
  meuPerfil,
}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const isNewUser = useMatch('/usuarios/novo');
  const { ubs, endereco, imageUrl, confirmDirty } = state;

  const { getFieldDecorator, validateFields, getFieldValue } = form;

  useEffect(() => {
    if (usuario?.id) {
      const { id_uf, id_municipio, id_unidade_basica } = usuario;
      findAll({ idMunicipio: id_municipio }).then(({ data }) => {
        dispatch({
          type: 'SET_DATA',
          payload: {
            endereco: {
              uf: id_uf,
              municipio: id_municipio,
              ubs: id_unidade_basica,
            },
            ubs: data,
          },
        });
      });
    }
  }, [usuario]);

  const profilePicture = useMemo(
    () => (usuario?.foto ? `${usuario.foto}?token=${getToken()}` : imageUrl),
    [imageUrl, usuario]
  );

  const handleConfirmBlur = useCallback(
    e => {
      const { value } = e.target;
      dispatch({
        type: 'SET_DIRTY',
        payload: { confirmDirty: confirmDirty || !!value },
      });
    },
    [confirmDirty]
  );

  const compareToFirstPassword = useCallback(
    (rule, value, callback) => {
      if (value !== getFieldValue('senha')) {
        callback('As senhas informadas devem ser iguais!');
      } else {
        callback();
      }
    },
    [getFieldValue]
  );

  const validateToNextPassword = useCallback(
    (rule, value, callback) => {
      if (value && confirmDirty) {
        validateFields(['confirma_senha'], { force: true });
      }
      callback();
    },
    [confirmDirty, validateFields]
  );

  const setImageUrl = useCallback(image => {
    dispatch({
      type: 'SET_IMAGE_USUARIO',
      payload: { image },
    });
  }, []);

  const beforeUpload = useCallback(
    file => {
      const isJpgOrPng =
        file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        notification.warn({
          message: 'Atenção',
          description: 'Você só pode enviar arquivos JPG ou PNG!',
        });
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        notification.warn({
          message: 'Atenção',
          description: 'Tamanho maximo do arquivo é de 2MB!',
        });
      }

      if (isJpgOrPng && isLt2M) {
        getBase64(file, setImageUrl);
      }

      return false;
    },
    [setImageUrl]
  );

  const handleSubmit = event => {
    event.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        const formData = new FormData();
        const { foto, cpf, ...fields } = values;

        if (foto) {
          formData.append('foto', foto?.file);
        }

        formData.append('cpf', cpf.replace(/[^\d]+/g, ''));

        Object.keys(fields).forEach(key => {
          if (
            fields[key] !== undefined &&
            fields[key] !== null &&
            fields[key] !== '' &&
            key !== 'confirma_senha'
          ) {
            formData.append(key, fields[key]);
          }
        });

        onSave(usuario.id, formData);
      }
    });
  };

  const optionsUF = useMemo(
    () =>
      estados.map(i => (
        <Select.Option key={i.id} value={i.id} title={i.nome}>
          {i.nome}
        </Select.Option>
      )),
    []
  );

  const optionsMunicipio = useMemo(() => {
    const estado = estados.find(uf => uf.id === endereco.uf);
    if (!estado) return [];
    return estado.municipios?.map(i => (
      <Select.Option key={i.id} value={i.id} title={i.nome}>
        {i.nome}
      </Select.Option>
    ));
  }, [endereco.uf]);

  const optionsUBS = useMemo(() => {
    if (ubs.length <= 0) return [];
    return ubs.map(i => (
      <Select.Option key={i.id} value={i.id} title={i.nome}>
        {i.nome}
      </Select.Option>
    ));
  }, [ubs]);

  const handleSelectUF = useCallback(
    uf => {
      dispatch({
        type: 'SET_ENDERECO',
        payload: {
          endereco: { ...endereco, uf },
        },
      });
    },
    [endereco]
  );

  const handleSelectMunicipio = useCallback(
    idMunicipio => {
      findAll({ idMunicipio }).then(({ data }) => {
        dispatch({
          type: 'SET_DATA',
          payload: {
            endereco: { ...endereco, municipio: idMunicipio },
            ubs: data,
          },
        });
      });
    },
    [endereco]
  );

  if (!usuario?.id && !isNewUser) return <Spin />;

  return (
    <Row type="flex">
      <Col span={14}>
        <Form onSubmit={handleSubmit} style={{ position: 'relative' }}>
          <ScrolledContent height={height}>
            <Row gutter={16} />
            <Row gutter={16}>
              {/** CPF */}
              <Col span={12}>
                <ItemLabel label="CPF" forInput="edit_cpf" required>
                  {getFieldDecorator('cpf', {
                    initialValue: usuario.cpf || '',
                    rules: [{ required: true, validator: validatorCPF }],
                  })(
                    <MaskInput
                      mask="000.000.000-00"
                      className="ant-input ant-input-lg"
                      disabled={meuPerfil}
                    />
                  )}
                </ItemLabel>
              </Col>
              {/* NOME */}
              <Col span={12}>
                <ItemLabel label="Nome" forInput="edit_nome" required>
                  {getFieldDecorator('nome', {
                    initialValue: usuario.nome,
                    rules: [{ required: true, message: 'Campo obrigatório' }],
                    normalize,
                  })(<Input size="large" />)}
                </ItemLabel>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={8}>
                {/* UF */}
                <ItemLabel label="UF">
                  {getFieldDecorator('id_uf', {
                    initialValue: usuario.id_uf,
                  })(
                    <Select
                      size="large"
                      onSelect={handleSelectUF}
                      showSearch
                      filterOption={filterSelectOption}
                      disabled={meuPerfil}>
                      {optionsUF}
                    </Select>
                  )}
                </ItemLabel>
              </Col>
              <Col span={16}>
                {/* MUNICIPIO */}
                <ItemLabel label="Município">
                  {getFieldDecorator('id_municipio', {
                    initialValue: usuario.id_municipio,
                  })(
                    <Select
                      size="large"
                      showSearch
                      onSelect={handleSelectMunicipio}
                      filterOption={filterSelectOption}
                      disabled={meuPerfil}>
                      {optionsMunicipio}
                    </Select>
                  )}
                </ItemLabel>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={6}>
                {/* UBS */}
                <ItemLabel label="Perfil" required>
                  {getFieldDecorator('id_perfil', {
                    initialValue: usuario.id_perfil,
                    rules: [{ required: true, message: 'Campo obrigatório' }],
                  })(
                    <Select size="large" showSearch disabled={meuPerfil}>
                      <Select.Option value={2}>Unidade de saúde</Select.Option>
                      <Select.Option value={1}>Municipal</Select.Option>
                      <Select.Option value={3}>Estadual</Select.Option>
                      <Select.Option value={4}>Nacional</Select.Option>
                    </Select>
                  )}
                </ItemLabel>
              </Col>
              <Col span={18}>
                {/* UBS */}
                <ItemLabel label="Unidade Básica de Saúde">
                  {getFieldDecorator('id_unidade_basica', {
                    initialValue: usuario.id_unidade_basica,
                  })(
                    <Select
                      size="large"
                      showSearch
                      filterOption={filterSelectOption}
                      disabled={meuPerfil}>
                      {optionsUBS}
                    </Select>
                  )}
                </ItemLabel>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                {/* EMAIL */}
                <ItemLabel label="Email">
                  {getFieldDecorator('email', {
                    initialValue: usuario.email,
                    rules: [
                      {
                        type: 'email',
                        message: 'Digite um e-mail válido!',
                      },
                      {
                        required: true,
                        message: 'Campo e-mail é obrigatório!',
                      },
                    ],
                    normalize,
                  })(
                    <Input size="large" autoComplete="off" autoCorrect="off" />
                  )}
                </ItemLabel>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                {/* SENHA */}
                <ItemLabel label="Senha">
                  {getFieldDecorator('senha', {
                    initialValue: '',
                    rules: [
                      {
                        validator: validateToNextPassword,
                      },
                    ],
                  })(<Input.Password size="large" />)}
                </ItemLabel>
              </Col>
              <Col span={12}>
                {/* CONFIRMA SENHA */}
                <ItemLabel label="Confirma Senha">
                  {getFieldDecorator('confirma_senha', {
                    initialValue: '',
                    rules: [
                      {
                        validator: compareToFirstPassword,
                      },
                    ],
                  })(
                    <Input.Password onBlur={handleConfirmBlur} size="large" />
                  )}
                </ItemLabel>
              </Col>
            </Row>
            <Row gutter={8} type="flex" justify="end" style={{ marginTop: 30 }}>
              <Col>
                <Button
                  loading={loading}
                  htmlType="submit"
                  type="primary"
                  size="large">
                  {usuario.id ? 'Atualizar' : 'Salvar'} e continuar
                </Button>
              </Col>
            </Row>
          </ScrolledContent>
          <ProfilePicture>
            <div className="upload-picture">
              <Avatar
                style={{ marginBottom: 12 }}
                src={profilePicture}
                shape="square"
                size={177}
                icon="user"
              />
              {getFieldDecorator('foto')(
                <Upload
                  customRequest={() => false}
                  beforeUpload={beforeUpload}
                  showUploadList={false}>
                  <Button type="primary" icon="edit" size="small" />
                </Upload>
              )}
            </div>
            <span className="nome">{`${getFieldValue('nome') || ''}`}</span>
            <br />
            <CircleDot color={StatusColorsEnum[usuario.status || 1]} />{' '}
            {StatusUsuarioEnum[usuario.status]}
          </ProfilePicture>
        </Form>
      </Col>
      <Col span={10} style={{ background: '#e8e8e8' }} />
    </Row>
  );
}

export default Form.create({ name: 'usuario' })(FormCadastro);
