export const StatusUsuarioEnum = {
  1: 'Ativo',
  2: 'Inativo',
  3: 'Bloqueado',
};

export const StatusColorsEnum = {
  1: '#A7E521',
  2: '#FF3E1D',
  3: '#72809D',
};
