import React from 'react';
import StyledFormItem from './styled';

export default function ItemLabel({
  label = '',
  forInput = '',
  required = false,
  children,
  ...props
}) {
  const hasValue = !!children?.props?.value || children?.props?.value === 0;
  return (
    <StyledFormItem
      className={hasValue && 'has-value'}
      required={required}
      {...props}>
      {children}
      {label !== '' && (
        <label htmlFor={forInput} className="inline-label">
          {label}
          {required && '*'}
        </label>
      )}
    </StyledFormItem>
  );
}
