import styled from 'styled-components';

export const StyledPaciente = styled.div`
  position: relative;
  display: flex;

  .ant-btn-localizar {
    position: absolute;
    right: 0;
    bottom: -8px;
  }
`;
