import React, { useCallback, useState } from 'react';
import { Link, navigate } from '@reach/router';
import { Form, Input, Typography, Button, Icon, notification } from 'antd';
import { recoverPassword } from 'services/usuarioService';
import { validarCPF } from 'utils/Functions';

const { Title } = Typography;

function RecuperarSenhaForm({ form: { getFieldDecorator, validateFields } }) {
  const [loadingButton, setLoadingButton] = useState(false);

  const validatorCPF = useCallback((rule, value, callback) => {
    if (validarCPF(value)) {
      callback();
    } else callback('CPF Inválido');
  }, []);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      validateFields((err, values) => {
        if (!err) {
          setLoadingButton(true);
          recoverPassword(values.username)
            .then(resp => {
              const { data } = resp;
              const { status, message } = data;
              if (status !== 'error') {
                notification.success({
                  description: message,
                  message: status.toUpperCase(),
                });
              }
              navigate('/login');
            })
            .catch(({ response }) => {
              const { message, status } = response.data;
              notification.error({
                description: message,
                message: status.toUpperCase(),
              });
              navigate('/login');
            });
        }
      });
    },
    [validateFields]
  );

  return (
    <Form className="login-form" onSubmit={handleSubmit}>
      <Title level={4}>Acesso Restrito</Title>
      Recuperar Senha
      <Form.Item>
        {getFieldDecorator('username', {
          rules: [
            {
              required: true,
              message: 'Por favor, digite seu CPF!',
              validator: validatorCPF,
            },
          ],
        })(<Input size="large" placeholder="CPF" />)}
      </Form.Item>
      <Form.Item>
        <Button
          loading={loadingButton}
          size="large"
          type="primary"
          htmlType="submit"
          className="login-form-button">
          Recuperar Senha
        </Button>
        <Link to="/login" className="link-form">
          <Icon type="left" />
          Voltar ao login
        </Link>
      </Form.Item>
    </Form>
  );
}

export default Form.create({ name: 'recuperarSenha' })(RecuperarSenhaForm);
