import React, { useCallback } from 'react';
import { Icon, Dropdown, Menu } from 'antd';
import { useDispatch } from 'react-redux';
import { openModal } from 'store/ducks/modal';
import { remove, save } from 'services/etapaService';
import { fetchEtapas } from 'store/ducks/paciente';

import Modals from 'modals';
import { StyledMenuStep, StyledDropdownMenu } from './styled';

export const EVENT = {
  EDIT: 'edit',
  COPY: 'copy',
  DELETE: 'delete',
};

function menu(showDelete, handleMenuClick) {
  return (
    <StyledDropdownMenu onClick={handleMenuClick}>
      <Menu.Item key={EVENT.EDIT}>
        <Icon type="edit" />
        Editar
      </Menu.Item>
      <Menu.Item key={EVENT.COPY}>
        <Icon type="copy" />
        Duplicar
      </Menu.Item>
      {showDelete && (
        <Menu.Item key={EVENT.DELETE}>
          <Icon type="delete" />
          Excluir
        </Menu.Item>
      )}
    </StyledDropdownMenu>
  );
}

export default function MenuStep({ item, showDelete = true }) {
  const dispatch = useDispatch();

  const onDelete = useCallback(() => {
    remove(item.id).then(() => {
      dispatch(fetchEtapas(item.id_tratamento));
    });
  }, [dispatch, item]);

  const onClone = useCallback(() => {
    const { data_realizacao, id_etapa_modelo, id_tratamento } = item;
    save({ data_realizacao, id_etapa_modelo, id_tratamento, status: 0 }).then(
      () => {
        dispatch(fetchEtapas(item.id_tratamento));
      }
    );
  }, [dispatch, item]);

  const handleClickMenuStep = useCallback(
    value => {
      value.domEvent.stopPropagation();

      if (value.key === EVENT.EDIT) {
        dispatch(
          openModal(Modals.ModalEtapa, {
            idTratamento: item.id_tratamento,
            etapa: item,
          })
        );
      } else if (value.key === EVENT.DELETE) {
        onDelete();
      } else if (value.key === EVENT.COPY) {
        onClone();
      }
    },
    [dispatch, item, onClone, onDelete]
  );

  return (
    <StyledMenuStep>
      <Dropdown
        overlay={menu(showDelete, handleClickMenuStep)}
        placement="topRight"
        trigger={['click']}>
        <Icon type="more" />
      </Dropdown>
    </StyledMenuStep>
  );
}
