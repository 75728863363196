import api from './api';

export function getMunicipioGeometriaById(idMunicipio) {
  return api.get(`/municipios/${idMunicipio}/geometria`);
}

export function getMunicipioGeometria(idMunicipio) {
  return api.get(`/municipios/${idMunicipio}/geometria`);
}

export function getMunicipioBbox(idMunicipio) {
  return api.get(`/municipios/${idMunicipio}/bbox`);
}

export function getStats() {
  return api.get(`/municipios/stats`);
}

export function listPacientes(idMunicipio) {
  return api.get(`/municipios/${idMunicipio}/pacientes`);
}
