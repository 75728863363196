import { createTypes, createReducer } from '../duckCreator';

export const Types = createTypes(['OPEN', 'CLOSE'], 'modals');

const initialState = { id: undefined, properties: {} };

const actionReducers = {
  [Types.OPEN]: (_, payload) => {
    return { id: payload.id, properties: payload.properties };
  },
  [Types.CLOSE]: () => {
    return initialState;
  },
};

// Action Creators
export function openModal(id, properties) {
  return { type: Types.OPEN, payload: { id, properties } };
}

export function closeModal() {
  return { type: Types.CLOSE };
}

export default createReducer(initialState, actionReducers);
