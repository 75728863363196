import styled from 'styled-components';

export const StyledContentList = styled.div`
  h4.ant-typography {
    font-size: 16px;
    color: #696974;
  }

  .search-header {
    .ant-input {
      &:not(:first-child) {
        padding-left: 40px;
      }
    }
  }

  .bordered-box {
    padding: 16px 15px;
    margin-top: 23px;
    border-radius: 23px;
    border: 1px solid #e2e2ea;

    .table-pacientes {
      .ant-table-tbody {
        tr {
          td {
            font-size: 13px;
            font-weight: 400;
            color: rgb(105, 105, 116);
            cursor: pointer;

            > div > * {
              display: block;
            }
            strong {
              color: rgb(23, 23, 37);
              margin-bottom: 8px;
            }
          }

          &.row-warning {
            td {
              background: rgb(255, 237, 237);
            }

            &:hover {
              > td {
                background: rgb(253, 231, 231);
              }
            }
          }

          &.row-pending {
            td {
              background: rgb(251, 244, 227);
            }

            &:hover {
              > td {
                background: rgb(249, 238, 221);
              }
            }
          }
        }
      }
    }
  }
`;
