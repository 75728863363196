import React, { useCallback, useState } from 'react';
import { Link, navigate } from '@reach/router';
import { Form, Input, Typography, Button, Icon, notification } from 'antd';
import { changePassword } from 'services/usuarioService';

const { Title } = Typography;

function MudarSenhaForm({
  form: { getFieldDecorator, validateFields, getFieldValue },
  tokenSenha,
}) {
  const [confirmDirty, setConfirmDirty] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const validateToNextPassword = useCallback(
    (rule, value, callback) => {
      if (value && confirmDirty) {
        validateFields(['passwordConfirm'], { force: true });
      }
      callback();
    },
    [confirmDirty, validateFields]
  );

  const validateToFirstPassword = useCallback(
    (rule, value, callback) => {
      if (value && value !== getFieldValue('password')) {
        callback('As senhas digitadas não são iguais!');
      } else {
        callback();
      }
    },
    [getFieldValue]
  );

  const handleConfirmBlur = useCallback(
    e => {
      const { value } = e.target;
      setConfirmDirty(confirmDirty || !!value);
    },
    [confirmDirty]
  );

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      validateFields((err, values) => {
        if (!err) {
          setLoadingButton(true);
          changePassword(values.password, tokenSenha)
            .then(({ data }) => {
              const { status, message } = data;
              if (status !== 'erro') {
                notification.success({
                  description: message,
                  message: status.toUpperCase(),
                });
                navigate('/login');
              }
            })
            .catch(({ response }) => {
              const { message, status } = response.data;
              notification.error({
                description: message,
                message: status.toUpperCase(),
              });
              navigate('/login');
            });
        }
      });
    },
    [tokenSenha, validateFields]
  );

  return (
    <Form className="login-form" onSubmit={handleSubmit}>
      <Title level={4}>Acesso Restrito</Title>
      Nova senha
      <Form.Item>
        {getFieldDecorator('password', {
          rules: [
            { required: true, message: 'Por favor, digite uma nova senha!' },
            { validator: validateToNextPassword },
          ],
        })(<Input.Password size="large" placeholder="Nova Senha" />)}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator('passwordConfirm', {
          rules: [
            { required: true, message: 'Por favor, confirme sua senha!' },
            { validator: validateToFirstPassword },
          ],
        })(
          <Input.Password
            size="large"
            placeholder="Confirme a Senha"
            onBlur={handleConfirmBlur}
          />
        )}
      </Form.Item>
      <Form.Item>
        <Button
          loading={loadingButton}
          size="large"
          type="primary"
          htmlType="submit"
          className="login-form-button">
          Salvar nova senha
        </Button>
        <Link to="/login" className="link-form">
          <Icon type="left" />
          Voltar ao login
        </Link>
      </Form.Item>
    </Form>
  );
}

export default Form.create({ name: 'mudarSenha' })(MudarSenhaForm);
