import { Button, Col, Row, Typography } from 'antd';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CONSTANTES from 'utils/constantes';
import { openModal } from 'store/ducks/modal';
import Modals from 'modals';

const { Title } = Typography;

export function Header() {
  const dispatch = useDispatch();
  const id_perfil = useSelector(state => state.usuario.id_perfil);

  const handleOpenModalPessoa = useCallback(() => {
    dispatch(openModal(Modals.ModalAddPeople, { tipo: 'paciente' }));
  }, [dispatch]);

  return (
    <Row type="flex" justify="space-between">
      <Col span={12}>
        <Title level={3}>Pacientes</Title>
      </Col>
      {id_perfil !== CONSTANTES.PERFIL.GESTOR && (
        <Col span={6} style={{ textAlign: 'right' }}>
          <Button
            type="primary"
            icon="plus"
            size="large"
            onClick={handleOpenModalPessoa}>
            Novo Paciente
          </Button>
        </Col>
      )}
    </Row>
  );
}
