import { findEstagioDoencaPorEstado } from 'services/estadosService';
import { createTypes, createReducer } from '../duckCreator';

export const Types = createTypes(['CLEAR', 'SET_ESTAGIOS'], 'estagios');

const initialState = {
  estagios: [],
  municipiosConfirmados: 0,
};

const actionReducers = {
  [Types.CLEAR]: () => {
    return initialState;
  },
  [Types.SET_ESTAGIOS]: (state, data) => {
    const estagios = data.estagios || [];
    return {
      ...state,
      estagios,
      municipiosConfirmados: data.municipios,
    };
  },
};

// Action Creators
function setEstagios(data) {
  return { type: Types.SET_ESTAGIOS, payload: data };
}

export function clear() {
  return { type: Types.CLEAR };
}

export function fetchEstagiosEstado(idEstado, idDoenca) {
  return async dispatch => {
    const { data } = await findEstagioDoencaPorEstado(idEstado, idDoenca);
    dispatch(setEstagios(data));
  };
}

export default createReducer(initialState, actionReducers);
