import React, { useState, useMemo } from 'react';
import { Form, Input, Button, Select, Row, Col, Modal } from 'antd';
import { ButtonTransparent } from 'components/Button';
import AlergiaSelect from 'components/AlergiaSelect';
import { TipoEtapaEnum } from 'enums';

const { TextArea } = Input;
const { Option } = Select;

function ModalEditForm({
  dataSource = {},
  handleSave,
  handleUpdate,
  handleCancel,
  form: { getFieldDecorator, validateFields },
}) {
  const context = dataSource.id ? 'Atualizar' : 'Salvar';
  const [tipoEtapa, setTipoEtapa] = useState(dataSource.id_tipo_etapa || '');
  const [alergias, setAlergias] = useState(dataSource.alergias || []);
  const [loading, setLoading] = useState(false);

  function handleSubmit(event) {
    event.preventDefault();
    validateFields((err, values) => {
      const etapa = {
        ...values,
        ativa: dataSource.ativa === undefined ? true : dataSource.ativa,
      };

      if (+tipoEtapa === 1) {
        etapa.alergias = alergias.map(a => a.id);
      }

      if (!err) {
        setLoading(true);
        if (context === 'Salvar') {
          handleSave(etapa);
        } else if (context === 'Atualizar') {
          handleUpdate(dataSource.id, etapa, setLoading);
        }
      }
    });
  }

  const tiposEtapa = useMemo(
    () =>
      Object.keys(TipoEtapaEnum).map(tipo => {
        if (+tipo !== 3) {
          return <Option key={tipo}>{TipoEtapaEnum[tipo]}</Option>;
        }
        return null;
      }),
    []
  );

  return (
    <Modal
      visible
      title={dataSource ? 'Editar etapa' : 'Nova etapa'}
      onOk={handleSubmit}
      onCancel={handleCancel}
      footer={[
        <ButtonTransparent key="back" onClick={handleCancel} size="small">
          Cancelar
        </ButtonTransparent>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleSubmit}
          size="large">
          <strong>{context}</strong>
        </Button>,
      ]}>
      <Form>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item>
              {getFieldDecorator('id_tipo_etapa', {
                initialValue: dataSource.id_tipo_etapa
                  ? String(dataSource.id_tipo_etapa)
                  : undefined,
                valuePropName: 'defaultValue',
                rules: [
                  {
                    required: true,
                    message: 'Por favor, selecione o tipo de etapa!',
                  },
                ],
              })(
                <Select
                  size="large"
                  placeholder="Selecione o tipo de etapa"
                  onSelect={value => setTipoEtapa(value)}>
                  {tiposEtapa}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              {getFieldDecorator('nome', {
                initialValue: dataSource.nome,
                rules: [
                  {
                    required: true,
                    message: 'Por favor, insira um nome da etapa!',
                  },
                ],
              })(<Input size="large" placeholder="Nome da etapa" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item>
              {getFieldDecorator('descricao', {
                initialValue: dataSource.descricao,
                rules: [
                  {
                    required: true,
                    message: 'Por favor, insira uma descrição!',
                  },
                ],
              })(<TextArea size="large" rows={4} placeholder="Descrição" />)}
            </Form.Item>
          </Col>
        </Row>
        {+tipoEtapa === 1 && (
          <Row>
            <Col span={24}>
              <strong>Contraindicado para quem tem alergia a:</strong>
              <AlergiaSelect
                selectedTags={alergias}
                onChange={e => setAlergias(e)}
              />
              {/* <TagSelect sourceData={initialTags} selectedTagKeys={tagKeys} textItemExists="Alergia já adicionada" /> */}
            </Col>
          </Row>
        )}
      </Form>
    </Modal>
  );
}

export default Form.create({ name: 'edit' })(ModalEditForm);
