import React, { useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import { isAuthenticated } from 'storage/auth';
import { ReactComponent as LogoRadarSaude } from 'assets/logo/radarsaude-branco.svg';
import LoginForm from './login';
import RecuperarSenhaForm from './recuperarSenha';
import MudarSenhaForm from './mudarSenha';
import StyledLogin from './styled';

const LoadComponent = ({ location, tokenSenha }) => {
  let component = null;
  switch (location) {
    case 'recuperar-senha':
      component = <RecuperarSenhaForm />;
      break;
    case 'mudar-senha':
      component = <MudarSenhaForm tokenSenha={tokenSenha} />;
      break;
    default:
      component = <LoginForm />;
      break;
  }
  return component;
};

function Login({ path, tokenSenha }) {
  const [location, setLocation] = useState('');

  useEffect(() => {
    const token = isAuthenticated();
    if (token) navigate('/');
  });

  useEffect(() => {
    const currentLocation = path.split('/')[2];
    setLocation(currentLocation);
  }, [path]);

  return (
    <StyledLogin>
      <div className="container">
        <div className="logo">
          <LogoRadarSaude />
        </div>
        <LoadComponent location={location} tokenSenha={tokenSenha} />
      </div>
    </StyledLogin>
  );
}

export default Login;
