import {
  Button,
  Col,
  Dropdown,
  Icon,
  Menu,
  notification,
  Row,
  Select,
  Typography,
} from 'antd';
import {
  TableActions,
  TableContainer,
  TableHeader,
  TableList,
} from 'components/Table';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { findAll, remove, update } from 'services/usuarioService';
import CONSTANTES from 'utils/constantes';
import { maskCpf } from 'utils/Functions';
import estados from 'utils/municipios.json';

const { Title } = Typography;
const { Column } = TableList;

const STATUS = CONSTANTES.STATUS;

const initSortedState = {
  columnKey: 'nome',
  order: 'ascend',
  menuItem: 'Nome A-Z',
};

const initFilterState = {
  filterKey: 'Todos',
  menuItem: 'Todos os usuários',
};

export default function ListaUsuarios({ navigate }) {
  const [loadingTable, setLoadingTable] = useState(true);
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [sortedInfo, setSortedInfo] = useState(initSortedState);
  const [filteredInfo, setFilteredInfo] = useState(initFilterState);

  const rowSelection = useMemo(
    () => ({
      onChange: keys => setSelectedRowKeys(keys),
    }),
    []
  );

  const listUsers = useCallback(() => {
    setLoadingTable(true);
    findAll()
      .then(response => {
        const res = response.data.map(d => {
          const uf = estados.find(m => +m.id === +d.id_uf);
          const municipio = uf?.municipios.find(m => +m.id === +d.id_municipio);
          return { ...d, estado: uf?.sigla, municipio: municipio?.nome };
        });
        setData(res);
      })
      .catch(setData([]))
      .finally(setLoadingTable(false));
  }, []);

  const handleUpdate = useCallback((id, values, setLoadingButton) => {
    setLoadingTable(true);
    update(id, values)
      .then(() => {
        notification.success({
          message: 'Sucesso!',
          description: 'Usuário atualizado.',
        });
      })
      .catch(({ response }) => {
        notification.error({
          message: 'Não foi posível alterar o status do usuário!',
          description: response.data.message,
        });
        setLoadingButton && setLoadingButton(false);
        setLoadingTable(false);
      });
  }, []);

  const handleDelete = useCallback(
    id => {
      setLoadingTable(true);
      remove(id)
        .then(resp => {
          notification.success({
            message: 'Sucesso!',
            description: 'Usuário removido.',
          });
          listUsers(resp);
        })
        .catch(({ response }) => {
          notification.error({
            message: 'Erro ao remover!',
            description: response.data.message,
          });
          setLoadingTable(false);
        });
    },
    [listUsers]
  );

  const menuRowDropdown = useCallback(
    record => {
      const menu = (
        <Menu
          onClick={({ key }) => {
            if (key === 'editar') {
              navigate(`${record.id}/editar`);
            } else if (key === 'excluir') {
              handleDelete(record.id);
            }
          }}>
          <Menu.Item key="editar">Editar</Menu.Item>
          <Menu.Item key="excluir">Excluir</Menu.Item>
        </Menu>
      );
      return menu;
    },
    [handleDelete, navigate]
  );

  const menuStatusDropdown = useCallback(
    record => {
      const menu = (
        <Menu
          onClick={({ key }) => {
            const body = {
              ...record,
              status: key,
            };
            delete body.id;
            delete body.created_at;
            delete body.updated_at;
            handleUpdate(record.id, body);
          }}>
          {record.status !== STATUS.ATIVO && (
            <Menu.Item key={STATUS.ATIVO}>Ativar</Menu.Item>
          )}
          {record.status !== STATUS.INATIVO && (
            <Menu.Item key={STATUS.INATIVO}>Inativar</Menu.Item>
          )}
          {record.status !== STATUS.BLOQUEADO && (
            <Menu.Item key={STATUS.BLOQUEADO}>Bloquear</Menu.Item>
          )}
        </Menu>
      );
      return menu;
    },
    [handleUpdate]
  );

  useEffect(() => {
    listUsers();
  }, [listUsers]);

  const menuFilters = useMemo(
    () => (
      <Menu
        onClick={menu =>
          setFilteredInfo({
            filterKey: menu.key,
            menuItem: menu.item.props.children,
          })
        }>
        <Menu.Item key="Todos">Todos os usuários</Menu.Item>
        <Menu.Item key={STATUS.ATIVO}>Ativos</Menu.Item>
        <Menu.Item key={STATUS.INATIVO}>Inativos</Menu.Item>
        <Menu.Item key={STATUS.BLOQUEADO}>Bloqueados</Menu.Item>
      </Menu>
    ),
    []
  );

  const menuSorters = useMemo(
    () => (
      <Menu
        onClick={menu => {
          const keys = menu.key.split(',');
          setSortedInfo({
            columnKey: keys[0],
            order: keys[1],
            menuItem: menu.item.props.children,
          });
        }}>
        <Menu.Item key="nome,ascend">Nome A-Z</Menu.Item>
        <Menu.Item key="nome,descend">Nome Z-A</Menu.Item>
        <Menu.Item key="prefeitura,ascend">Prefeitura A-Z</Menu.Item>
        <Menu.Item key="prefeitura,descend">Prefeitura Z-A</Menu.Item>
        <Menu.Item key="status,ascend">Status A-Z</Menu.Item>
        <Menu.Item key="status,descend">Status Z-A</Menu.Item>
      </Menu>
    ),
    []
  );

  return (
    <>
      <Row type="flex" justify="space-between">
        <Col span={12}>
          <Title level={3}>Lista de Usuários</Title>
        </Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          <Button
            type="primary"
            icon="plus"
            size="large"
            onClick={() => navigate('novo')}>
            Novo Usuário
          </Button>
        </Col>
      </Row>

      <TableActions>
        <Dropdown
          className="box-filters"
          overlay={menuFilters}
          placement="bottomRight"
          trigger={['click']}
          ta>
          <div>
            <span>Filtrar Por: </span>
            <strong>{filteredInfo.menuItem}</strong>
            <Icon type="caret-down" />
          </div>
        </Dropdown>
        <Dropdown
          className="box-sorters"
          overlay={menuSorters}
          placement="bottomRight"
          trigger={['click']}>
          <div>
            <strong>Ordenar Por: </strong>
            <span>
              <Icon type="menu" />
              {sortedInfo.menuItem}
            </span>
          </div>
        </Dropdown>
      </TableActions>
      <TableContainer>
        <TableHeader>
          <Row type="flex" justify="space-between">
            <Col>
              <Title level={4}>Usuários cadastrados</Title>
            </Col>
            <Col>
              <Row type="flex" align="middle" gutter={8}>
                <Col>
                  <Select
                    allowClear
                    size="large"
                    placeholder="Ações em massa"
                    style={{ width: 186 }}>
                    <Select.Option value={1}>Ativar</Select.Option>
                    <Select.Option value={2}>Inativar</Select.Option>
                    <Select.Option value={3}>Bloquear</Select.Option>
                    <Select.Option value="excluir">Excluir</Select.Option>
                  </Select>
                </Col>
                <Col>
                  <Button size="large" type="primary" ghost>
                    <strong>Aplicar</strong>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </TableHeader>
        <TableList
          loading={loadingTable}
          rowKey="id"
          rowSelection={rowSelection}
          dataSource={data}>
          <Column
            sorter={(a, b) => a.nome.localeCompare(b.nome)}
            sortOrder={sortedInfo.columnKey === 'nome' && sortedInfo.order}
            title="Nome"
            dataIndex="nome"
          />
          <Column title="CPF" dataIndex="cpf" render={cpf => maskCpf(cpf)} />
          <Column title="E-mail" dataIndex="email" />
          <Column title="Estado" dataIndex="estado" />
          <Column
            sorter={(a, b) => a.prefeitura.localeCompare(b.prefeitura)}
            sortOrder={
              sortedInfo.columnKey === 'prefeitura' && sortedInfo.order
            }
            title="Prefeitura"
            dataIndex="municipio"
          />
          {/* <Column
            filteredValue={[filteredInfo.filterKey]}
            onFilter={(value, record) =>
              value.toLowerCase() === 'todos' || record.status === +value
            }
            sorter={(a, b) =>
              StatusUsuarioEnum[a.status].localeCompare(
                StatusUsuarioEnum[b.status]
              )
            }
            sortOrder={sortedInfo.columnKey === 'status' && sortedInfo.order}
            title="Status"
            dataIndex="status"
            render={(status, record) => {
              const statusLabel = StatusUsuarioEnum[status];
              return (
                <>
                  <span>
                    <Dropdown
                      overlay={menuStatusDropdown(record)}
                      placement="bottomRight"
                      trigger={['click']}>
                      <ButtonTransparent>
                        <Icon type="caret-down" />
                        <CircleDot color={StatusColorsEnum[status || 1]}{} />
                      </ButtonTransparent>
                    </Dropdown>
                  </span>
                  {statusLabel}
                </>
              );
            }}
          /> */}
          <Column
            title="Ações"
            width={80}
            align="center"
            render={(_, record) => (
              <span>
                <Dropdown
                  overlay={menuRowDropdown(record)}
                  placement="bottomRight"
                  trigger={['click']}>
                  <Icon type="more" style={{ fontSize: 20 }} />
                </Dropdown>
              </span>
            )}
          />
        </TableList>
      </TableContainer>
    </>
  );
}
