import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import etapa from './etapa';
import paciente from './paciente';
import usuario from './usuario';
import comments from './comments';
import estado from './estado';
import map from './map';
import localizar from './localizar';
import modal from './modal';

export default combineReducers({
  form,
  etapa,
  paciente,
  usuario,
  comments,
  estado,
  map,
  localizar,
  modal,
});
