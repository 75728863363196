import React, { useMemo, useCallback } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { countTotalCasos } from 'utils/Functions';
import { DropdownFilter } from 'components/Table';
import { Legenda } from './Legenda';
import { SearchInput, StyledHeaderSider } from './styled';

const TitleResume = styled.span`
  display: inline-block;
  font-size: 12px;
  text-transform: uppercase;
  color: #696974;
  line-height: 16px;
`;

const NumbersResume = styled.span`
  display: block;
  font-size: 21px;
  font-weight: 500;
  color: ${({ color }) => color || '#696974'};
`;

export function HeaderSider({
  filterEstagios = {},
  onSearchMunicipio = () => {},
  onChangeFilter = () => {},
}) {
  const estagios = useSelector(state => state.estado.estagios || []);
  const municipiosConfirmados = useSelector(
    state => state.estado.municipiosConfirmados
  );

  const handleChangeFilter = useCallback(
    (key, label) =>
      onChangeFilter({
        key,
        label,
      }),
    [onChangeFilter]
  );

  const menuFilters = useMemo(() => {
    const defaultOptions = [
      {
        key: 'Todos',
        label: 'Todos',
      },
    ];
    const menuOptions = estagios.map(i => ({ key: i.id, label: i.nome }));

    return [...defaultOptions, ...menuOptions];
  }, [estagios]);

  return (
    <StyledHeaderSider>
      <Row gutter={[0, 16]}>
        <Col>
          <Row type="flex" justify="space-between">
            {estagios.length > 0 && (
              <Col>
                <TitleResume>
                  Casos
                  <br /> confirmados
                </TitleResume>
                <NumbersResume color="#ED6F00">
                  {countTotalCasos(estagios)}
                </NumbersResume>
              </Col>
            )}
            {/* TODO: Mudar para total de municipios com casos */}
            {estagios.length > 0 && (
              <Col>
                <TitleResume>
                  Municípios com
                  <br /> casos confirmados
                </TitleResume>
                <NumbersResume>{municipiosConfirmados}</NumbersResume>
              </Col>
            )}
          </Row>
        </Col>
        <Col>
          <Legenda estagios={estagios} />
        </Col>
        <Col>
          <DropdownFilter
            currentItem={filterEstagios.label}
            options={menuFilters}
            onSelect={handleChangeFilter}
          />
        </Col>
        <Col>
          <SearchInput
            allowClear
            size="large"
            placeholder="Pesquise pelo município"
            onChange={onSearchMunicipio}
          />
        </Col>
      </Row>
    </StyledHeaderSider>
  );
}
