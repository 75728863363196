import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formValueSelector, Field, change } from 'redux-form';
import { Input, Checkbox } from 'components/Form';
import { FORM } from './FORM';

const selector = formValueSelector(FORM);

export default function NomePai() {
  const dispatch = useDispatch();

  const nome_pai = useSelector(state => selector(state, 'nome_pai'));

  useEffect(() => {
    if (nome_pai?.trim()) {
      dispatch(change(FORM, 'pai_desconhecido', false));
    } else {
      dispatch(change(FORM, 'pai_desconhecido', true));
    }
  }, [dispatch, nome_pai]);

  return (
    <>
      <Field name="nome_pai" label="Nome do Pai" component={Input} />
      <Field
        disabled
        name="pai_desconhecido"
        className="normal-lineHeight"
        component={Checkbox}>
        Desconhecido
      </Field>
    </>
  );
}
