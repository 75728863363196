import { Col, Icon, Row, Select } from 'antd';
import { ItemLabel } from 'components/Form';
import React, { useEffect, useMemo, useState } from 'react';
import { SectionContent } from '../SectionContent';

export default function SectionUnidade({ hospitais = [], etapa, onChange }) {
  const [unidade, setUnidade] = useState(etapa?.propriedades?.unidade);

  useEffect(() => {
    if (onChange) {
      onChange({ unidade });
    }
  }, [onChange, unidade]);

  const optionsHospitais = useMemo(() => {
    return hospitais.map(u => {
      return (
        <Select.Option value={u.id} key={u.id}>
          {u.nome}
        </Select.Option>
      );
    });
  }, [hospitais]);

  const selectHospitais = useMemo(() => {
    return (
      <Col span={24}>
        <ItemLabel label="" forInput="edit">
          <Select
            value={unidade}
            size="large"
            style={{ width: '100%' }}
            onSelect={setUnidade}
            disabled={!!etapa?.propriedades?.unidade}>
            {optionsHospitais}
          </Select>
        </ItemLabel>
      </Col>
    );
  }, [etapa, optionsHospitais, unidade]);

  return (
    <SectionContent
      title="Unidade de Internação"
      icon={<Icon type="medicine-box" />}>
      <Row gutter={[16, 16]}>{selectHospitais}</Row>
    </SectionContent>
  );
}
