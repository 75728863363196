import queryString from 'query-string';
import { getToken } from 'storage/auth';
import api from './api';

export function findById(id) {
  return api.get(`/pacientes/${id}`);
}

export function findAll(query, config) {
  return api.get(`/pacientes?${queryString.stringify(query)}`, config);
}

export function save(values) {
  return api.post('/pacientes', values);
}

export function update(id, values) {
  return api.put(`/pacientes/${id}`, values);
}

export function listDoencas(idPaciente) {
  return api.get(`/pacientes/${idPaciente}/doencas`);
}

export function listTratamentos(idPaciente, idDoenca) {
  return api.get(`/pacientes/${idPaciente}/doencas/${idDoenca}/tratamentos`);
}

export function getImage(idPaciente) {
  return api.get(`/pacientes/${idPaciente}/foto`, { responseType: 'blob' });
}

export function getImageURL(idPaciente) {
  return `/api/pacientes/${idPaciente}/foto?token=${getToken()}`;
}

export function getMae(idPaciente) {
  return api.get(`/pacientes/${idPaciente}/relacionamentos/mae`);
}

export function getPai(idPaciente) {
  return api.get(`/pacientes/${idPaciente}/relacionamentos/pai`);
}

export function getFilhos(idPaciente) {
  return api.get(`/pacientes/${idPaciente}/relacionamentos/filhos`);
}

export function getParceiros(idPaciente) {
  return api.get(`/pacientes/${idPaciente}/relacionamentos/parceiros`);
}

export function adicionarRelacionamento(idPaciente, body) {
  return api.post(`/pacientes/${idPaciente}/relacionamentos`, body);
}

export function findPacientesByMunicipioDoenca(idMunicipio, idDoenca) {
  return api.get(`/municipios/${idMunicipio}/doencas/${idDoenca}/pacientes`);
}

export function findPacientesByMunicipioDoencaGeometria(idMunicipio, idDoenca) {
  return api.get(
    `/municipios/${idMunicipio}/doencas/${idDoenca}/pacientes/geometria`
  );
}

export function getPacienteBbox(idPaciente) {
  return api.get(`/pacientes/${idPaciente}/bbox`);
}

export function internar(idPaciente, idUnidade) {
  return api.post(`/pacientes/${idPaciente}/internar`, { idUnidade });
}
