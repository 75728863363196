import styled from 'styled-components';
import constantes from 'utils/constantes';

function getIconColor({ status = undefined }) {
  const colors = {
    selected: 'rgb(0,98,255)',
    warning: 'rgb(252,90,90)',
    [constantes.ETAPA.STATUS.NAO_EXECUTADO]: 'rgb(146,146,157)',
    [constantes.ETAPA.STATUS.EXECUTADO]: '#3DD598',
    [constantes.ETAPA.STATUS.NAO_COMPARECEU]: 'rgb(255,151,74)',
  };

  return colors[status] || colors.default;
}

export const TratamentoItem = styled.div`
  display: flex;
  align-items: center;
`;

export const IconEtapa = styled.div`
  margin-right: 11px;

  .anticon {
    color: ${props =>
      props.selected
        ? getIconColor({ status: 'selected' })
        : getIconColor(props)};
    font-size: 22px;
  }
`;
