import styled from 'styled-components';

export const StyledEtapaDetalhe = styled.div`
  /* border-radius: 20px;
  box-shadow: 0 2px 23px 0 rgba(2, 43, 108, 0.12);
  background-color: #ffffff;
  padding: 18px 48px; */
  margin-top: 23px;

  .header-tratamento {
    > div:not(.ant-divider) {
      display: flex;
      justify-content: space-between;
    }

    h3 {
      margin-bottom: 0;
    }
  }

  .data-info__cadastro {
    color: rgba(105, 105, 116, 1);
    line-height: 24px;
  }

  .data-info__consulta {
    text-align: right;
    color: rgba(68, 68, 79, 1);

    strong,
    span {
      display: block;
    }

    strong {
      color: rgba(146, 146, 157, 1);
      margin-bottom: 10px;
    }

    span {
      font-weight: 500;
    }
  }

  .descricao-tratamento {
    margin-top: 52px;
  }

  .descricao-tratamento__footer {
    text-align: right;

    .ant-btn {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
`;
