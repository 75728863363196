import { Avatar, Button, notification, Upload } from 'antd';
import { ProfilePicture } from 'components/Form';
import StyledTag from 'components/Tag';
import React, { useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { formValueSelector, Field } from 'redux-form';
import { getBase64 } from 'utils/files';
import { useParams } from '@reach/router';
import { getImageURL } from 'services/pacienteService';
import { FORM } from './FORM';

const selector = formValueSelector(FORM);

function UploadImage() {
  const [imageUrl, setImageUrl] = useState();
  const { pacienteId = undefined } = useParams();

  useEffect(() => {
    if (pacienteId) {
      const url = getImageURL(pacienteId);
      setImageUrl(url);
    }
  }, [pacienteId]);

  const beforeUpload = useCallback(file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      notification.warn({
        message: 'Atenção',
        description: 'Você só pode enviar arquivos JPG ou PNG!',
      });
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      notification.warn({
        message: 'Atenção',
        description: 'Tamanho maximo do arquivo é de 2MB!',
      });
    }

    if (isJpgOrPng && isLt2M) {
      getBase64(file, setImageUrl);
    }

    return false;
  }, []);

  return (
    <div className="upload-picture">
      <Avatar
        style={{ marginBottom: 12 }}
        src={imageUrl !== undefined && imageUrl}
        shape="square"
        size={177}
        icon="user"
      />
      <Field
        name="foto"
        type="file"
        component={({ input }) => {
          return (
            <Upload
              {...input}
              customRequest={() => false}
              beforeUpload={beforeUpload}
              showUploadList={false}>
              <Button type="primary" icon="edit" size="small" />
            </Upload>
          );
        }}
      />
    </div>
  );
}

function Nome() {
  const nome = useSelector(state => selector(state, 'nome'));
  return <span className="nome">{nome || ''}</span>;

  /* {paciente.id && (
        <a href={`/pacientes/${paciente.id}/tratamentos`}>
          <Button type="link" icon="medicine-box" size="small" />
        </a>
      )} */
}

function Alergias() {
  const alergias = useSelector(state => selector(state, 'alergias'));

  const a = alergias?.map(alergia => (
    <StyledTag
      type="medicamento"
      icon="exclamation-circle"
      text={alergia.nome}
    />
  ));

  if (a && a.length > 0) {
    return a;
  }

  return null;
}

function Gestante() {
  const gestante = useSelector(state => selector(state, 'gestante'));
  if (gestante) {
    return <StyledTag type="gestante" text="Gestante" />;
  }
  return null;
}

export default function Profile() {
  return (
    <ProfilePicture>
      <UploadImage />
      <Nome />
      <br />
      {/* <span className="idade">{idade}</span> */}
      <div className="tags">
        <Gestante />
        <Alergias />
      </div>
    </ProfilePicture>
  );
}
