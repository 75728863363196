import React from 'react';
import { Avatar, Icon } from 'antd';
import { getImageURL } from 'services/pacienteService';
import StyledCard from './styled';

const { Meta } = StyledCard;

export default function CardPaciente({ paciente, onClick = () => {} }) {
  return (
    <StyledCard onClick={onClick} hoverable bordered={false}>
      <Meta
        avatar={<Avatar src={getImageURL(paciente.id)} />}
        title={`${paciente.nome} ${paciente.sobrenome}`}
        description={`Cartão do Sus: ${paciente.n_cartao_sus} - CPF ${paciente.cpf}`}
      />
      <div className="extra-content">
        <Icon type="right" style={{ color: '#0062FF' }} />
      </div>
    </StyledCard>
  );
}
