import React, { useMemo, useState, useCallback } from 'react';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Modals from 'modals';
import { openModal } from 'store/ducks/modal';
import { ButtonPessoa } from './ButtonPessoa';

export default function ButtonGroupPessoas({
  label = 'Adicionar',
  type,
  onClick = () => {},
}) {
  const parceiros = useSelector(state => state.paciente[`${type}s`]);
  const [current, setCurrent] = useState(0);
  const dispatch = useDispatch();

  const handleOnClick = useCallback(
    (id, pessoa) => {
      setCurrent(id);
      onClick(id, pessoa);
    },
    [onClick]
  );

  const buttons = useMemo(
    () =>
      parceiros.map(pessoa => {
        const currentActive = current === pessoa.id;
        return (
          <ButtonPessoa
            key={pessoa.id}
            pessoa={pessoa}
            onClick={() => handleOnClick(pessoa.id, pessoa)}
            className={currentActive ? 'active' : ''}
          />
        );
      }),
    [current, handleOnClick, parceiros]
  );

  const handleOpenModalPessoa = useCallback(() => {
    dispatch(openModal(Modals.ModalAddPeople, { tipo: type }));
  }, [dispatch, type]);

  return (
    <>
      <Button
        type="primary"
        icon="plus"
        size="large"
        onClick={handleOpenModalPessoa}
        ghost
        style={{ marginBottom: 20 }}>
        <strong>{label}</strong>
      </Button>
      {buttons}
    </>
  );
}
