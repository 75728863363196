import React from 'react';
import { StyledResultSearchPeople } from './styled';

export function ResultSearchPeople({ length = 0 }) {
  let txtResult = length > 1 ? 'resultados' : 'resultado';
  txtResult = <span>{`${length} ${txtResult}`}</span>;
  txtResult = length === 0 ? 'Nenhum resultado' : txtResult;

  return <StyledResultSearchPeople>{txtResult}</StyledResultSearchPeople>;
}
