import React from 'react';
import styled from 'styled-components';
import { Collapse } from 'antd';

const StyledGreyCollapse = styled(Collapse)`
  &.ant-collapse {
    border: 0;
    background-color: transparent;

    > .ant-collapse-item {
      border: 0;
      overflow: hidden;

      > .ant-collapse-header {
        font-size: 11px;
        color: rgba(68, 68, 79, 1);
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.8px;
        background-color: rgba(242, 242, 242, 1);
        border-radius: 10px;
        margin-bottom: 22px;
      }

      &:last-child {
        border: inherit;
        border-radius: unset;

        > .ant-collapse-header {
          border-radius: 10px;
        }
      }
    }

    .ant-collapse-content {
      border-top: unset;

      > .ant-collapse-content-box {
        padding: 0;
      }
    }
  }
`;

export const Panel = Collapse.Panel;

export function GreyCollapse({ children, ...props }) {
  return (
    <StyledGreyCollapse expandIconPosition="right" {...props}>
      {children}
    </StyledGreyCollapse>
  );
}
