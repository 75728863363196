import api from './api';

export function findAll() {
  return api.get('/estados');
}

export function getEstadoGeometria(idEstado) {
  return api.get(`/estados/${idEstado}/geometria`);
}

export function getMunicipiosByEstado(idEstado) {
  return api.get(`/estados/${idEstado}/municipios`);
}

export function findEstagioDoencaPorEstado(idEstado, idDoenca) {
  return api.get(`/estados/${idEstado}/doencas/${idDoenca}/estagios`);
}

export function findMunicipiosComEstagiosPorEstado(idEstado, idDoenca) {
  return api.get(
    `/estados/${idEstado}/doencas/${idDoenca}/municipios/estagios`
  );
}

export function getEstadoBbox(idEstado) {
  return api.get(`/estados/${idEstado}/bbox`);
}

export function getMapaEstagioDoenca(idEstado) {
  return api.get(`/estados/${idEstado}/doencas/2/mapa`);
}

export function listMesorregiaoGeojson(idEstado) {
  return api.get(`/estados/${idEstado}/mesorregioes/geometria`);
}

export function listMunicipiosGeojson(idEstado) {
  return api.get(`/estados/${idEstado}/municipios/geometria`);
}
