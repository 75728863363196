/* eslint-disable no-param-reassign */
import U from 'mapbox-gl-utils';
import React, { useEffect, useRef, useCallback } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import { getGeojson } from 'services/mapboxService';
import { getGeomUnidades } from 'services/unidadeBasicaService';
import Marker from './Marker';

export { Marker };

const styles = {
  height: '100%',
};

mapboxgl.accessToken =
  'pk.eyJ1IjoiZWR1YXJkb2tsYXViZXJnIiwiYSI6ImNqd2V5MXljazA3OXA0NG52cjQ4MncyZzUifQ.tzTS3egLATKrsHgy8A192w';

// const style = {
//   version: 8,
//   sources: {
//     'simple-tiles': {
//       type: 'raster',
//       tiles: [
//         'http://a.tile.openstreetmap.org/{z}/{x}/{y}.png',
//         'http://b.tile.openstreetmap.org/{z}/{x}/{y}.png',
//         'http://c.tile.openstreetmap.org/{z}/{x}/{y}.png',
//       ],
//       tileSize: 256,
//     },
//   },
//   sprite:
//     'mapbox://sprites/eduardoklauberg/ck84foh4z377m1io7tgk3u2bh/2e8acugh1va4z0nnoa38cvp05',
//   glyphs: 'mapbox://fonts/eduardoklauberg/{fontstack}/{range}.pbf',
//   layers: [
//     {
//       id: 'simple-tiles',
//       type: 'raster',
//       source: 'simple-tiles',
//       minzoom: 0,
//       maxzoom: 22,
//     },
//   ],
// };

async function createLayers(map) {
  const [unidadeBasica, microArea] = await Promise.all([
    getGeomUnidades(),
    getGeojson('micro_area'),
  ]);
  map.U.removeSource('unidade_basica-source');
  map.U.addGeoJSON('unidade_basica-source', unidadeBasica.data, {
    promoteId: 'id',
  });
  map.U.removeSource('micro_area-source');
  map.U.addGeoJSON('micro_area-source', microArea.data, {
    promoteId: 'id',
  });
}

export function updateLayers(map) {
  getGeojson('unidade_basica').then(resp => {
    map.removeFeatureState({ source: 'unidade_basica-source' });
    map.getSource('unidade_basica-source').setData(resp.data);
  });

  getGeojson('micro_area').then(resp => {
    map.removeFeatureState({ source: 'micro_area-source' });
    map.getSource('micro_area-source').setData(resp.data);
  });
}

export const marker = new mapboxgl.Marker();

const Map = ({ onClick, setMapRef }) => {
  const mapContainer = useRef(null);

  const handleClick = useCallback(
    e => {
      if (onClick) {
        onClick(e);
      }
    },
    [onClick]
  );

  useEffect(() => {
    console.log('Map -> só deve passar aqui uma vez');
    const mapboxMap = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/eduardoklauberg/ck8c6jhzc2ppe1jnuokcokbt0',
      center: [0, 0],
      zoom: 5,
    });

    mapboxMap.addControl(new mapboxgl.NavigationControl());

    mapboxMap.fitBounds([
      -53.83635864440077,
      -29.35144104628034,
      -48.35878613955834,
      -25.955958884585375,
    ]);
    U.init(mapboxMap, mapboxgl);

    mapboxMap.on('load', () => {
      mapboxMap.resize();
      createLayers(mapboxMap).then(() => {
        setMapRef(mapboxMap);
      });
    });

    mapboxMap.on('click', handleClick);

    return () => {
      mapboxMap.off('click', handleClick);
    };
  }, [handleClick, setMapRef]);

  return (
    <div
      ref={el => {
        mapContainer.current = el;
      }}
      style={styles}>
      {/* <div
        id="dialog-cluter"
        style={{
          position: 'absolute',
          zIndex: 99,
          margin: '5px 10px',
          padding: '10px 13px 11px 20px',
          backgroundColor: 'rgba(0,0,0,0.45)',
        }}
      /> */}
    </div>
  );
};

export default Map;
