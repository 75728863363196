import React, { useEffect, useState } from 'react';
import TagSelect from 'components/TagSelect';
import { findAll, save } from 'services/alergiaService';

export default function AlergiaSelect({
  disabled = false,
  onChange,
  selectedTags,
}) {
  const [alergias, setAlergias] = useState([]);
  const [alergiasSelecionadas, setAlergiasSelecionadas] = useState(
    selectedTags
  );
  const [loading, setLoading] = useState(false);

  const tags = alergias.map(a => a.nome);
  const tagsSelecionadas = alergiasSelecionadas.map(a => a.nome);

  async function list() {
    findAll().then(({ data }) => {
      setAlergias(data);
    });
  }

  useEffect(() => {
    list();
  }, []);

  useEffect(() => {
    if (selectedTags !== alergiasSelecionadas) {
      setAlergiasSelecionadas(selectedTags);
    }
  }, [selectedTags, alergiasSelecionadas]);

  async function handleSave(nome) {
    let alergia = alergias.find(a => a.nome === nome);

    if (!alergia) {
      setLoading(true);
      const resposne = await save({ nome });
      alergia = resposne.data;
      setAlergias(old => [...old, alergia]);
      setLoading(false);
    }

    const newAlergiasSelecionadas = [...alergiasSelecionadas, alergia];
    setAlergiasSelecionadas(newAlergiasSelecionadas);
    onChange(newAlergiasSelecionadas);
  }

  function onRemove(tag) {
    const newAlergiasSelecionadas = alergiasSelecionadas.filter(
      a => a.nome !== tag
    );
    setAlergiasSelecionadas(old => old);
    onChange(newAlergiasSelecionadas);
  }

  return (
    <TagSelect
      disabled={disabled}
      sourceData={tags}
      selectedTags={tagsSelecionadas}
      onSelect={handleSave}
      onRemove={onRemove}
      loading={loading}
    />
  );
}
