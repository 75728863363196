import { Col, Form, Row, Typography, Input, Button, notification } from 'antd';
import { ItemLabel } from 'components/Form';
import { BorderedBox } from 'components/Layout';
import Map, { updateLayers } from 'components/Map';
import React, { useCallback, useState, useEffect } from 'react';
import useDraw from 'hooks/useDraw';
import { salvarGeom } from 'services/mapboxService';
import { StyledContentList } from '../Pacientes/styled';

const { Title } = Typography;

function normalize(value) {
  return value === null || value === '' ? undefined : value;
}

const CadastroUnidadeBasica = ({
  form: { getFieldDecorator, validateFields, resetFields },
}) => {
  const [map, setMap] = useState();
  const [features, changeMode] = useDraw(map, true, 'simple_select');
  const [feature, setFeature] = useState();
  const [unidadeBasica, setUnidadeBasica] = useState();

  useEffect(() => {
    if (map) {
      map.U.addFill('micro_area', 'micro_area-source', {
        fillColor: [
          'case',
          [
            'any',
            ['boolean', ['feature-state', 'hover'], false],
            ['boolean', ['feature-state', 'selecionado'], false],
          ],
          '#ffFF00',
          '#ff0000',
        ],
        fillOpacity: 0.5,
      });

      map.U.addCircle('unidade_basica', 'unidade_basica-source', {
        circleColor: [
          'case',
          [
            'any',
            ['boolean', ['feature-state', 'hover'], false],
            ['boolean', ['feature-state', 'selecionado'], false],
          ],
          '#ffFF00',
          ['case', ['==', ['get', 'tipo'], 1], 'green', 'blue'],
        ],
      });

      return () => {
        map.U.removeLayer(['micro_area', 'unidade_basica']);
      };
    }
    return () => { };
  }, [map]);

  useEffect(() => {
    if (changeMode && map) {
      map.getCanvas().style.cursor = 'pointer';
      changeMode('snap_polygon', {
        snapConfig: { layers: ['micro_area'] },
      });
    }
  }, [changeMode, map]);

  useEffect(() => {
    if (map && features.length > 0) map.getCanvas().style.cursor = '';
    setFeature(features[0]);
  }, [features, map]);

  const changeGeom = useCallback(() => {
    if (changeMode) {
      map.getCanvas().style.cursor = 'pointer';
      changeMode('snap_polygon', {
        snapConfig: { layers: ['micro_area'] },
      });
    }
  }, [changeMode, map]);

  useEffect(() => {
    let unhover = () => { };
    let unstate = () => { };
    let unpopup = () => { };
    let unclick = () => { };
    if (map && feature) {
      unhover = map.U.hoverPointer('unidade_basica');
      unpopup = map.U.hoverPopup(
        'unidade_basica',
        f =>
          `<div>
          <p><strong>Nome: </strong>${f.properties.nome}</p>
          <p><strong>Codigo: </strong>${f.properties.codigo}</p>
          </div>`
      );
      unstate = map.U.hoverFeatureState('unidade_basica');
      unclick = map.U.clickLayer('unidade_basica', e => {
        if (unidadeBasica) {
          map.setFeatureState(unidadeBasica, {
            selecionado: false,
          });
        }
        map.setFeatureState(e.features[0], {
          selecionado: true,
        });
        setUnidadeBasica(e.features[0]);
      });
    }
    return () => {
      unhover();
      unstate();
      unpopup();
      unclick();
    };
  }, [feature, map, unidadeBasica]);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      validateFields((err, values) => {
        if (!err && feature && unidadeBasica) {
          salvarGeom('micro_area', {
            ...values,
            feature: feature.geometry,
            id_unidade_basica: unidadeBasica.id,
          })
            .then(resp => {
              if (resp?.data?.id) {
                notification.success({
                  message: 'Sucesso',
                  description: 'Unidade Básica cadastrada!',
                });
              } else {
                notification.error({
                  message: 'Erro',
                  description:
                    resp.data.message ||
                    'Não foi possível salvar este cadastro',
                });
              }
              updateLayers(map);
              setFeature(undefined);
              setUnidadeBasica(undefined);
              resetFields();
              changeGeom();
            })
            .catch(() => {
              notification.error({
                message: 'Erro',
                description: 'Não foi possível salvar este cadastro',
              });
            });
        }
      });
    },
    [changeGeom, feature, map, resetFields, unidadeBasica, validateFields]
  );

  return (
    <>
      <Title level={3}>Cadastro de Microárea</Title>
      <BorderedBox>
        <Row type="flex" style={{ height: 'calc(100vh - 180px)' }} gutter={16}>
          <Col span={10}>
            <Form
              onSubmit={handleSubmit}
              style={{ position: 'relative', padding: 16 }}>
              <StyledContentList>
                <Row gutter={16}>
                  <Col span={12}>
                    <ItemLabel label="Nome" forInput="edit_nome" required>
                      {getFieldDecorator('nome', {
                        rules: [
                          { required: true, message: 'Campo obrigatório' },
                        ],
                        normalize,
                      })(<Input size="large" />)}
                    </ItemLabel>
                  </Col>
                  <Col span={12}>
                    <ItemLabel label="Código" forInput="edit_codigo" required>
                      {getFieldDecorator('codigo', {
                        rules: [
                          { required: true, message: 'Campo obrigatório' },
                        ],
                        normalize,
                      })(<Input size="large" />)}
                    </ItemLabel>
                  </Col>
                </Row>
                <Row>
                  <Button htmlType="submit" type="primary">
                    Salvar
                  </Button>
                  <div style={{ float: 'right' }}>
                    {!feature && (
                      <span
                        style={{ color: 'red', marginRight: 5, fontSize: 12 }}>
                        Selecione o polígono no mapa
                      </span>
                    )}
                    {feature && !unidadeBasica && (
                      <span
                        style={{
                          color: 'red',
                          marginRight: 5,
                          fontSize: 12,
                        }}>
                        Selecione a unidade básica associada no mapa
                      </span>
                    )}
                    <Button
                      onClick={changeGeom}
                      disabled={!feature}
                      size="large">
                      Alterar polígono
                    </Button>
                  </div>
                </Row>
              </StyledContentList>
            </Form>
          </Col>
          <Col span={14}>
            <Map setMapRef={setMap} />
          </Col>
        </Row>
      </BorderedBox>
    </>
  );
};
export default Form.create()(CadastroUnidadeBasica);
