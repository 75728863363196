import React from 'react';
import { Router } from '@reach/router';
import { useSelector } from 'react-redux';
import ListaUsuarios from './ListaUsuarios';
import CadastroUsuario from './CadastroUsuario';

export default function Usuarios() {
  const usuarioID = useSelector(state => state.usuario.id);
  return (
    <Router>
      <ListaUsuarios path="/" />
      <CadastroUsuario path="novo" />
      <CadastroUsuario path="meu-perfil" usuarioId={usuarioID} meuPerfil />
      <CadastroUsuario path=":usuarioId/editar" />
    </Router>
  );
}
