/* eslint-disable func-names */
/* eslint-disable no-extend-native */
import moment from 'moment';

Date.prototype.addHoras = function (horas) {
  this.setHours(this.getHours() + horas);
  return this;
};
Date.prototype.addMinutos = function (minutos) {
  this.setMinutes(this.getMinutes() + minutos);
  return this;
};
Date.prototype.addSegundos = function (segundos) {
  this.setSeconds(this.getSeconds() + segundos);
  return this;
};
Date.prototype.addDias = function (dias) {
  this.setDate(this.getDate() + dias);
  return this;
};
Date.prototype.addMeses = function (meses) {
  this.setMonth(this.getMonth() + meses);
  return this;
};
Date.prototype.addAnos = function (anos) {
  this.setYear(this.getFullYear() + anos);
  return this;
};

export function validarCPF(rawCPF) {
  if (!rawCPF) {
    return false;
  }
  const strCPF = rawCPF.replace(/[^\d]+/g, '');
  let Soma;
  let Resto;
  Soma = 0;

  // Elimina CPFs invalidos conhecidos
  if (
    strCPF.length > 11 ||
    strCPF === '00000000000' ||
    strCPF === '11111111111' ||
    strCPF === '22222222222' ||
    strCPF === '33333333333' ||
    strCPF === '44444444444' ||
    strCPF === '55555555555' ||
    strCPF === '66666666666' ||
    strCPF === '77777777777' ||
    strCPF === '88888888888' ||
    strCPF === '99999999999' ||
    strCPF === '12345678909'
  ) {
    return false;
  }

  for (let i = 1; i <= 9; i++)
    Soma += parseInt(strCPF.substring(i - 1, i), 10) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(9, 10), 10)) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i++)
    Soma += parseInt(strCPF.substring(i - 1, i), 10) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(10, 11), 10)) return false;
  return true;
}

export function maskCpf(rawCPF) {
  return rawCPF.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
}

function addZero(i) {
  if (i < 10) {
    i = `0${i}`;
  }
  return i;
}

export function formatDate(dateMoment) {
  const date = moment(dateMoment).toDate();
  const monthNames = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  const h = addZero(date.getHours());
  const m = addZero(date.getMinutes());

  return `${day} de ${monthNames[monthIndex]} de ${year} às ${h}:${m}`;
}

/**
 * Gets the age of a person
 *
 * @param birthDate  The date when the person was born
 */
export function age(birthDate) {
  if (birthDate) {
    const anos = moment().diff(birthDate, 'years', false);
    if (anos > 0) {
      return `${anos} anos`;
    }
    const meses = moment().diff(birthDate, 'months', false);
    if (meses > 0) {
      return `${meses} meses`;
    }
    const dias = moment().diff(birthDate, 'days', false);
    return `${dias} dias`;
  }
  return '';
}

/**
 * Converte para minusculas e remove caracteres especiais de uma string
 *
 * @param {String} value string a ser limpo os caracteres especiais
 *
 * @return {string} retorna a string normalizada
 */
export function removeSpecialChars(value) {
  let fixedValue = value.toLowerCase();
  fixedValue = fixedValue.replace(/[àáâãäå]/g, 'a');
  fixedValue = fixedValue.replace(/[éèêë]/g, 'e');
  fixedValue = fixedValue.replace(/[íìî]/g, 'i');
  fixedValue = fixedValue.replace(/[óòôõ]/g, 'o');
  fixedValue = fixedValue.replace(/[úùû]/g, 'u');
  fixedValue = fixedValue.replace(/[ -]/g, '_');
  fixedValue = fixedValue.replace(/[ç]/g, 'c');
  return fixedValue;
}

export function filterWords(filter, value) {
  return removeSpecialChars(value).indexOf(removeSpecialChars(filter)) >= 0;
}

export function filterSelectOption(filter, option) {
  return filterWords(filter, option.props.children);
}

export function countTotalCasos(estagios = []) {
  let soma = 0;
  estagios &&
    estagios.forEach(item => {
      // TODO: Incluir casos contabiliados dinamicamente
      if (
        item?.total &&
        [5, 6, 8].includes(item.id ?? item.id_estagio_clinico)
      ) {
        soma += +item.total;
      }
    });
  return soma;
}

export function getEstagiosMunicipio(estagios = [], estagiosDescricao = []) {
  if (!Array.isArray(estagios)) return [];
  return estagios?.map(item => {
    const { total, ...current } =
      estagiosDescricao.find(i => +i.id === +item.id_estagio_clinico) ?? {};
    return current && { ...current, ...item };
  });
}
