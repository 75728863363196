import { createTypes, createReducer } from '../duckCreator';

export const Types = createTypes(
  [
    'SET_DATE',
    'SET_LAYER',
    'SET_ESTAGIO',
    'SET_BUSCA_POR_ENDERECO',
    'SHOW_TIME_LINE',
  ],
  'map'
);

const initialState = {
  date: Date.now(),
  estagio: 'total',
  layer: 'cluster',
  buscaPorEndereco: 1,
  showTimeline: false,
};

const actionReducers = {
  [Types.SET_DATE]: (state, payload) => ({
    ...state,
    date: payload,
  }),
  [Types.SET_LAYER]: (state, payload) => ({
    ...state,
    layer: payload,
  }),
  [Types.SET_ESTAGIO]: (state, payload) => ({
    ...state,
    estagio: payload,
  }),
  [Types.SET_BUSCA_POR_ENDERECO]: (state, payload) => ({
    ...state,
    buscaPorEndereco: payload,
  }),
  [Types.SHOW_TIME_LINE]: (state, payload) => ({
    ...state,
    showTimeline: payload,
  }),
};

export function setDate(date) {
  return { type: Types.SET_DATE, payload: date };
}

export function setLayer(layer) {
  return { type: Types.SET_LAYER, payload: layer };
}

export function setEstagio(estagio) {
  return { type: Types.SET_ESTAGIO, payload: estagio };
}

export function setBuscaPorEndereco(buscaPorEndereco) {
  return { type: Types.SET_BUSCA_POR_ENDERECO, payload: buscaPorEndereco };
}

export function setShowTimeline(bool) {
  return { type: Types.SHOW_TIME_LINE, payload: bool };
}

export function showTimeline() {
  return { type: Types.SHOW_TIME_LINE, payload: true };
}

export function hideTimeline() {
  return { type: Types.SHOW_TIME_LINE, payload: false };
}

export default createReducer(initialState, actionReducers);
