import { Select } from 'antd';
import List from 'rc-virtual-list';
import React, { useMemo, useState } from 'react';
import { filterWords } from 'utils/Functions';

const { Option } = Select;

const Item = props => {
  const { onClick, ...item } = props;
  const { id, value } = item;
  return (
    <div
      role="button"
      tabIndex="-1"
      onMouseDown={() => onClick(id, item)}
      className="ant-select-dropdown-menu-item">
      {value}
    </div>
  );
};

function optionsList(options, onChange = () => {}) {
  return (
    <List data={options} height={250} itemHeight={32} itemKey="id">
      {item => <Item onClick={onChange} {...item} />}
    </List>
  );
}

function SelectVirtualized({ options, onChange = () => {}, ...rest }) {
  const [filter, setFilter] = useState('');

  const memoOptionsFiltered = useMemo(
    () => options.filter(({ value: v }) => filterWords(filter, v)),
    [filter, options]
  );

  return (
    <Select
      showSearch
      onSearch={setFilter}
      style={{ width: '100%' }}
      dropdownRender={() => optionsList(memoOptionsFiltered, onChange)}
      {...rest}>
      {memoOptionsFiltered.map(i => (
        <Option key={i.id} value={i.id}>
          {i.value}
        </Option>
      ))}
    </Select>
  );
}

export default SelectVirtualized;
