import React from 'react';
import { List, Spin } from 'antd';
import CardHoverable from 'components/CardHoverable';

import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import { Item } from 'components/Dashboard/List';
import { StyledHeaderSider, StyledListItem } from '../styled';

export default function ListaMunicipio({
  loading,
  selectedKey,
  onSelectMunicipio,
  municipios,
}) {
  if (loading)
    return (
      <StyledHeaderSider>
        <Spin />
      </StyledHeaderSider>
    );

  return (
    <AutoSizer>
      {({ height, width }) => (
        <FixedSizeList
          innerElementType={List}
          height={height}
          itemCount={municipios.length}
          itemSize={50}
          width={width}>
          {({ index, style }) => (
            <StyledListItem style={style}>
              <CardHoverable
                height={40}
                onClick={() => onSelectMunicipio(municipios[index])}
                active={selectedKey === municipios[index].id}>
                <Item item={municipios[index]} showProgress={false} />
              </CardHoverable>
            </StyledListItem>
          )}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
}
