import React from 'react';
import { Router } from '@reach/router';
import ListaTratamentos from './ListaTratamentos';
import FormTratamento from './FormTratamento';

function Tratamentos() {
  return (
    <Router>
      <ListaTratamentos path="/" />
      <FormTratamento path="novo" />
      <FormTratamento path="editar/:tratamentoId" />
    </Router>
  );
}

export default Tratamentos;
