import React from 'react';
import { Col, Icon, Progress, Row } from 'antd';
import CONSTANTES from 'utils/constantes';

function TratamentoProgresso({
  etapas = [],
  fracao = true,
  porcentagem = true,
  width = '100%',
}) {
  let total = 0;
  let executadas = 0;
  let percent = 0;

  if (etapas) {
    total = etapas.length;
    executadas = etapas.reduce(
      (acc, curr) =>
        acc + (curr.status !== CONSTANTES.ETAPA.STATUS.NAO_EXECUTADO ? 1 : 0),
      0
    );
    percent = (executadas / total) * 100;
  }
  return (
    <Row>
      {fracao && (
        <Col lg={6} xl={6} xxl={5}>
          <Icon type="check-circle" />
          {` ${executadas}/${total}`}
        </Col>
      )}
      {porcentagem && (
        <Col xl={18} xxl={19}>
          <Progress style={{ width }} percent={Number(percent.toFixed(1))} />
        </Col>
      )}
    </Row>
  );
}

export default TratamentoProgresso;
