import { AutoComplete, Icon, Empty } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { search } from 'services/enderecoService';
import DelayAbortController from 'utils/DelayAbortController';

const { Option } = AutoComplete;

const abortController = new DelayAbortController(500);

const fetch = abortController.execute(
  (value, setData, setLoading, bbox = [], cancelToken) => {
    setLoading(true);
    search(value, bbox, { cancelToken })
      .then(({ data = [] }) => {
        setLoading(false);
        setData(data);
      })
      .catch(e => console.error(e));
  }
);

export default ({ onSelect, placeholder = 'Endereço', bbox }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  // console.log('data', data);
  const handleSearch = useCallback(
    value => {
      if (value) {
        fetch(value, setData, setLoading, bbox);
      } else {
        setData([]);
      }
    },
    [bbox]
  );

  const handleSelect = useCallback(
    value => {
      if (onSelect) {
        const address = data.find(d => d.name === value);
        onSelect(address);
      }
    },
    [data, onSelect]
  );

  const options = useMemo(
    () => data.map(d => <Option key={d.name}>{d.name}</Option>),
    [data]
  );

  return (
    <AutoComplete
      size="large"
      dataSource={options}
      onSearch={handleSearch}
      onSelect={handleSelect}
      placeholder={placeholder}
      notFoundContent={
        loading ? (
          <Icon type="loading" />
        ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )
      }
    />
  );
};
