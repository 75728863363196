import styled from 'styled-components';
import { Layout } from 'antd';

const StyledContent = styled(Layout.Content)`
  height: calc(100vh - 70px);
  padding: 15px 40px 0;
`;

const BorderedBox = styled.div`
  background: #ffffff;
  border-radius: 20px;
  padding: ${({ padding = 0 }) => padding}px;

  .mapboxgl-map {
    border-radius: 0 20px 20px 0;
  }
`;

const ScrolledContent = styled.div`
  padding: 5px 30px 42px;
  min-height: ${({ height = 83 }) => height}vh;
  max-height: ${({ height = 83 }) => height}vh;
  height: ${({ height = 83 }) => height}vh;
  overflow: hidden auto;
`;

export { StyledContent, BorderedBox, ScrolledContent };
