import React, { useMemo } from 'react';
import { Row, Col, Typography } from 'antd';
import { EstagioClinicoProgresso } from 'components/ProgressBar';
import { countTotalCasos, getEstagiosMunicipio } from 'utils/Functions';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Legenda } from '../Legenda';
import { StyledHeaderSider, SearchInput } from '../styled';

const { Title } = Typography;

const SubTitle = styled.span`
  font-size: 13px;
  color: #696974;
`;

export default function Header({ municipio = {}, onFilter }) {
  const { estagios = [] } = municipio;
  const estagiosDescricao = useSelector(state => state.estado.estagios || []);

  const estagiosFilled = useMemo(
    () => getEstagiosMunicipio(estagios, estagiosDescricao),
    [estagios, estagiosDescricao]
  );

  const total = useMemo(() => countTotalCasos(estagios), [estagios]);

  return (
    <StyledHeaderSider>
      <Row type="flex" justify="space-between">
        <Col>
          <SubTitle>Total de casos confirmados em</SubTitle>
          <Title level={4}>{municipio.nome}</Title>
        </Col>
        <Col>
          <Title level={4} style={{ color: '#ED6F00' }}>
            {total}
          </Title>
        </Col>
      </Row>
      <EstagioClinicoProgresso
        estagios={estagios}
        total={municipio.total_estagio}
      />
      <div style={{ marginTop: 15, marginBottom: 20 }}>
        <Legenda estagios={estagiosFilled} />
      </div>
      <SearchInput
        size="large"
        placeholder="Pesquise pelo paciente"
        onChange={e => onFilter(e.target.value)}
      />
    </StyledHeaderSider>
  );
}
