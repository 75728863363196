import { MainTabs } from 'components/Tabs';
import Modals from 'modals';
import React, { useCallback, useEffect, useMemo } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { clearEtapa } from 'store/ducks/etapa';
import { openModal } from 'store/ducks/modal';
import {
  clear,
  fetchDoencas,
  fetchPaciente,
  setActiveDoenca,
  setActiveDoencaKey,
} from 'store/ducks/paciente';
import Doenca from './Doenca';
import Header from './Header';
import { ContentTratamentos } from './styled';

const { TabPane } = MainTabs;

function Tratamentos({ pacienteId }) {
  const doencas = useSelector(state => state.paciente.doencas);
  const defaultActiveKey = useSelector(state => state.paciente.activeDoencaKey);
  const dispatch = useDispatch();

  useEffect(() => {
    const firstDoencaKey = '2'; // TODO: Set default active doenca key from db
    dispatch(setActiveDoencaKey(firstDoencaKey));
  }, [dispatch, doencas]);

  useEffect(() => {
    batch(() => {
      dispatch(fetchPaciente(pacienteId));
      dispatch(fetchDoencas(pacienteId));
    });

    return () => {
      batch(() => {
        dispatch(clearEtapa());
        dispatch(clear());
      });
    };
  }, [dispatch, pacienteId]);

  const tabs = useMemo(() => {
    return Object.values(doencas).map(doenca => {
      return (
        <TabPane tab={doenca.nome} key={doenca.id}>
          <Doenca doencaId={doenca.id} pacienteId={pacienteId} />
        </TabPane>
      );
    });
  }, [doencas, pacienteId]);

  const handleChangeTab = useCallback(
    currentKey => {
      if (currentKey !== 'novo') {
        dispatch(setActiveDoenca(currentKey));
      } else {
        dispatch(openModal(Modals.ModalNovoTratamento, { pacienteId }));
      }
    },
    [dispatch, pacienteId]
  );

  return (
    <>
      <Header />
      <ContentTratamentos>
        <MainTabs
          type="card"
          onChange={handleChangeTab}
          activeKey={defaultActiveKey}>
          {tabs}
          <TabPane tab="Novo Tratamento" key="novo" />
        </MainTabs>
      </ContentTratamentos>
    </>
  );
}

export default Tratamentos;
