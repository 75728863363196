import styled from 'styled-components';
import { Modal } from 'antd';

const POSITIONS_FLEX = {
  start: 'flex-start',
  middle: 'center',
  end: 'flex-end',
};

export const BigModal = styled(Modal)`
  h4.ant-typography {
    font-size: 17px;
    text-align: center;
    margin-bottom: 25px;
  }

  .ant-modal {
    &-body {
      padding: 60px 70px;
    }

    &-content {
      border-radius: 20px;
    }
  }

  .ant-calendar-picker,
  .ant-input {
    width: 100%;
  }
`;

export const ModalTextDivisor = styled.div`
  margin: 0 0 25px;
  text-align: center;
  font-size: 13px;
  color: #92929d;
`;

export const Subtitle = styled.h5`
  font-size: 14px;
  color: #171725;
  margin-bottom: 15px;
`;

export const ModalActions = styled.div`
  display: flex;
  justify-content: ${({ justify = 'end' }) =>
    POSITIONS_FLEX[justify] || 'flex-end'};
  align-items: center;

  .ant-btn {
    margin-left: 10px;
  }
`;
