import { getToken } from 'storage/auth';
import api from './api';

export function authenticate(cpf, senha) {
  return api.post('/usuarios/login', { cpf, senha });
}

export function changePassword(senha, token) {
  return api.post('/usuarios/changepassword', { senha, token });
}

export function recoverPassword(cpf) {
  return api.post('/usuarios/recovery', { cpf });
}

export function userLoggedDetail() {
  return api.get('/usuarios/detalhe');
}

export function findAll() {
  return api.get('/usuarios');
}

export function getById(id) {
  return api.get(`/usuarios/${id}`);
}

export function save(body) {
  return api.post('/usuarios', body);
}

export function update(id, body) {
  return api.put(`/usuarios/${id}`, body);
}

export function remove(id) {
  return api.delete(`/usuarios/${id}`);
}

export function getProfileImage(id) {
  return `/api/usuarios/${id}/foto?token=${getToken()}`;
}
