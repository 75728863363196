import React, { useCallback } from 'react';
import { ReactComponent as TargetIcon } from 'assets/icons/target.svg';
import { Icon } from 'antd';
import { ButtonTinyIcon } from 'components/Button';

export default function ButtonLocalizar({ onClick, ...rest }) {
  const handleClick = useCallback(
    e => {
      e.stopPropagation();
      onClick();
    },
    [onClick]
  );
  return (
    <ButtonTinyIcon
      className="ant-btn-localizar"
      onClick={handleClick}
      {...rest}>
      <Icon component={TargetIcon} />
    </ButtonTinyIcon>
  );
}
