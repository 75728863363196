import styled from 'styled-components';
import { Collapse } from 'antd';

export const StyledCollapse = styled(Collapse).attrs(() => ({
  bordered: false,
}))`
  &.ant-collapse {
    font-size: 12px;
    background: none;
    border: none;
    border-radius: 0;

    > .ant-collapse-item {
      border: none;
      border-radius: 0;
      transition: all 200ms ease-in-out;

      .ant-collapse-header {
        border-radius: 10px;
        background-color: #fff;

        &:hover {
          background-color: #0062ff;
          color: #fff;

          .ant-btn-localizar {
            path {
              stroke: #fff;
            }
          }
        }

        /* Botao de Localizar dentro do card quando usa collapse */
        .ant-btn-localizar {
          background-color: rgba(255, 255, 255, 0.4);
          path {
            stroke: #0062ff;
            transition: all 200ms;
          }
        }

        .extra {
          display: none;
          margin-top: 10px;
          transition: all 200ms ease-in-out;
        }
      }

      &.ant-collapse-item-active {
        .ant-collapse-header {
          border-radius: 10px 10px 0 0;
          background-color: #0062ff;
          color: #fff;

          .extra {
            display: block;
          }

          .ant-btn-localizar {
            path {
              stroke: #fff;
            }
          }
        }
      }

      .ant-collapse-content {
        padding-top: 12px;
        background-color: #a5bce0;
        border-radius: 0 0 10px 10px;
      }
    }
  }
`;
