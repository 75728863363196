import api from './api';

export function findAll(query = {}) {
  const filter = Object.keys(query)
    .map(k => `${k}=${query[k]}`)
    .join('&');

  return api.get(`/etapas/modelo${filter ? `?${filter}` : ''}`);
}

export function save(values) {
  return api.post('/etapas/modelo', values);
}

export function update(id, values) {
  return api.put(`/etapas/modelo/${id}`, values);
}

export function remove(id) {
  return api.delete(`/etapas/modelo/${id}`);
}
