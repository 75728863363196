import styled from 'styled-components';
import { Layout, Tabs } from 'antd';

const { Sider } = Layout;

export const StyledSider = styled(Sider)`
  &.ant-layout-sider {
    z-index: 1;
    background-color: #f1f3f8;
    box-shadow: 30px 20px 60px 0 rgba(0, 0, 0, 0.1);
  }

  .ant-drawer-content,
  .ant-drawer-header {
    background-color: transparent;
  }
  .ant-drawer-header {
    padding: 24px 24px 0;
    border-bottom: unset;
  }

  .ant-drawer-wrapper-body {
    display: flex;
  }
  .ant-layout-sider-children {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 30px 0 0;
  }
`;

export const StyledTabs = styled(Tabs)`
  .ant-tabs-nav-wrap {
    display: flex;
    justify-content: center;
  }
`;

export const StyledTrigger = styled.div`
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 1;
`;
