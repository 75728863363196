import {
  listDoencas,
  listTratamentos,
  findById,
  getMae,
  getPai,
  getFilhos,
  getParceiros,
  internar,
} from 'services/pacienteService';
import {
  listEtapas,
  findEtapaNotificacao,
  listEstagios,
} from 'services/tratamentoService';
import { batch } from 'react-redux';
import { createTypes, createReducer } from '../duckCreator';
import { setEtapaTratamento } from './etapa';

export const Types = createTypes(
  [
    'SET_DOENCAS',
    'SET_TRATAMENTOS',
    'SET_ETAPAS',
    'CLEAR',
    'SET_PACIENTE',
    'SET_NEW_PACIENTE',
    'SET_PACIENTE_MAE',
    'SET_PACIENTE_PAI',
    'SET_PACIENTE_FILHOS',
    'SET_PACIENTE_PARCEIROS',
    'SET_ACTIVE_DOENCA_KEY',
    'SET_ETAPA_NOTIFICACAO',
    'ADD_PACIENTE_FILHO',
    'ADD_PACIENTE_PARCEIRO',
    'INTERNAR',
  ],
  'paciente'
);

const initialState = {
  doencas: {},
  tratamentos: {},
  etapas: {},
  estagios: {},
  data: {},
  mae: {},
  pai: {},
  filhos: [],
  parceiros: [],
  activeDoencaKey: null,
  etapaNotificacao: null,
};

const actionReducers = {
  [Types.SET_DOENCAS]: (state, payload) => {
    return {
      ...state,
      doencas: payload.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {}),
    };
  },
  [Types.SET_TRATAMENTOS]: (state, payload) => {
    const { idDoenca, tratamentos } = payload;
    return {
      ...state,
      tratamentos: { ...state.tratamentos, [idDoenca]: tratamentos },
    };
  },
  [Types.SET_ETAPAS]: (state, payload) => {
    const { idTratamento, etapas, estagios } = payload;
    return {
      ...state,
      etapas: {
        ...state.etapas,
        [idTratamento]: etapas.map(e => ({
          ...e,
          id_tratamento: idTratamento,
          tipo: 'etapa',
        })),
      },
      estagios: {
        ...state.estagios,
        [idTratamento]: estagios.map(e => ({
          ...e,
          id_tratamento: idTratamento,
          tipo: 'estagio',
        })),
      },
    };
  },
  [Types.SET_PACIENTE]: (state, payload) => {
    return { ...state, data: payload };
  },
  [Types.SET_NEW_PACIENTE]: (state, payload) => {
    return { ...state, [payload]: { naoAssociado: true } };
  },
  [Types.SET_PACIENTE_MAE]: (state, payload) => {
    return { ...state, mae: payload };
  },
  [Types.SET_PACIENTE_PAI]: (state, payload) => {
    return { ...state, pai: payload };
  },
  [Types.SET_PACIENTE_FILHOS]: (state, payload) => {
    return { ...state, filhos: payload };
  },
  [Types.SET_PACIENTE_PARCEIROS]: (state, payload) => {
    return { ...state, parceiros: payload };
  },
  [Types.SET_ACTIVE_DOENCA_KEY]: (state, payload) => {
    return { ...state, activeDoencaKey: payload };
  },
  [Types.CLEAR]: () => {
    return initialState;
  },
  [Types.SET_ETAPA_NOTIFICACAO]: (state, payload) => {
    return { ...state, etapaNotificacao: payload };
  },
  [Types.ADD_PACIENTE_FILHO]: (state, payload) => {
    return { ...state, filhos: [...state.filhos, payload] };
  },
  [Types.ADD_PACIENTE_PARCEIRO]: (state, payload) => {
    return { ...state, parceiros: [...state.parceiros, payload] };
  },
};

// Action Creators

export function setDoencas(doencas) {
  return { type: Types.SET_DOENCAS, payload: doencas };
}

export function setTratamentos(idDoenca, tratamentos) {
  return { type: Types.SET_TRATAMENTOS, payload: { idDoenca, tratamentos } };
}

export function setEtapas(idTratamento, etapas, estagios) {
  return {
    type: Types.SET_ETAPAS,
    payload: { idTratamento, etapas, estagios },
  };
}

export function setPaciente(paciente) {
  return { type: Types.SET_PACIENTE, payload: paciente };
}

export function setNewPacienteTipo(tipo) {
  return { type: Types.SET_NEW_PACIENTE, payload: tipo };
}

export function setPacienteMae(paciente) {
  return { type: Types.SET_PACIENTE_MAE, payload: paciente };
}

export function setPacientePai(paciente) {
  return { type: Types.SET_PACIENTE_PAI, payload: paciente };
}

export function setPacienteFilhos(filhos) {
  return { type: Types.SET_PACIENTE_FILHOS, payload: filhos };
}

export function addPacienteFilho(paciente) {
  return { type: Types.ADD_PACIENTE_FILHO, payload: paciente };
}

export function setPacienteParceiros(parceiros) {
  return { type: Types.SET_PACIENTE_PARCEIROS, payload: parceiros };
}

export function addPacienteParceiro(parceiro) {
  return { type: Types.ADD_PACIENTE_PARCEIRO, payload: parceiro };
}

export function setActiveDoencaKey(activeKey) {
  return { type: Types.SET_ACTIVE_DOENCA_KEY, payload: activeKey };
}

export function setEtapaNotificacao(etapaNotificacao) {
  return { type: Types.SET_ETAPA_NOTIFICACAO, payload: etapaNotificacao };
}

export function clear() {
  return { type: Types.CLEAR };
}

export function fetchDoencas(idPaciente) {
  return async dispatch => {
    const { data } = await listDoencas(idPaciente);
    dispatch(setDoencas(data));
  };
}

export function setActiveDoenca(activeKey) {
  return async (dispatch, getState) => {
    const tratamentos = getState().paciente?.tratamentos;
    const etapas = getState().paciente?.etapas;
    // pega a info completa sobre o tratamento atual da doenca
    const tratamentoAtual = tratamentos[activeKey]?.find(
      trat => trat.finalizado === false
    );
    // pega primeira etapa do tratamento atual
    const primeiraEtapa = etapas[tratamentoAtual?.id]?.[0];
    batch(() => {
      dispatch(setActiveDoencaKey(activeKey));
      primeiraEtapa &&
        dispatch(setEtapaTratamento(primeiraEtapa, tratamentoAtual));
    });
  };
}

export function fetchTratamentos(idPaciente, idDoenca) {
  return async dispatch => {
    const { data } = await listTratamentos(idPaciente, idDoenca);
    dispatch(setTratamentos(idDoenca, data));
  };
}

export function fetchEtapas(idTratamento) {
  return async (dispatch, getState) => {
    const tratamentos = getState().paciente?.tratamentos;
    const activeDoencaKey = getState().paciente?.activeDoencaKey;
    const { data } = await listEtapas(idTratamento);
    const { data: esta } = await listEstagios(idTratamento);
    // pega primeira etapa do tratamento atual
    const primeiraEtapa = Array.isArray(data) && data[0];
    // pega a info completa sobre o tratamento atual da doenca
    const tratamentoAtual = tratamentos[activeDoencaKey]?.find(
      trat => trat.id === idTratamento
    );
    batch(() => {
      dispatch(setEtapas(idTratamento, data, esta));
      dispatch(setEtapaTratamento(primeiraEtapa, tratamentoAtual));
    });
  };
}

export function fetchPaciente(idPaciente) {
  return async dispatch => {
    const { data } = await findById(idPaciente);
    dispatch(setPaciente(data));
  };
}

export function fetchPacientePorTipo(idPaciente, tipo, naoAssociado) {
  const setPacientePorTipo = {
    paciente: setPaciente,
    mae: setPacienteMae,
    pai: setPacientePai,
    filho: addPacienteFilho,
    parceiro: addPacienteParceiro,
  };

  return async dispatch => {
    const { data } = await findById(idPaciente);
    dispatch(setPacientePorTipo[tipo]({ ...data, naoAssociado }));
  };
}

export function fetchPacienteMae(idPaciente) {
  return async dispatch => {
    const { data } = await getMae(idPaciente);
    dispatch(setPacienteMae(data));
  };
}

export function fetchPacientePai(idPaciente) {
  return async dispatch => {
    const { data } = await getPai(idPaciente);
    dispatch(setPacientePai(data));
  };
}

export function fetchPacienteFilhos(idPaciente) {
  return async dispatch => {
    const { data } = await getFilhos(idPaciente);
    dispatch(setPacienteFilhos(data));
  };
}

export function fetchPacienteParceiros(idPaciente) {
  return async dispatch => {
    const { data } = await getParceiros(idPaciente);
    dispatch(setPacienteParceiros(data));
  };
}

export function fetchEtapaNotificacao(idTratamento) {
  return async dispatch => {
    const { data } = await findEtapaNotificacao(idTratamento);
    dispatch(setEtapaNotificacao(data));
  };
}

export function internarPaciente(idUnidade) {
  return (_, getState) => {
    const idPaciente = getState().paciente.data.id;
    return internar(idPaciente, idUnidade);
  };
}

export default createReducer(initialState, actionReducers);
