import styled from 'styled-components';

export const StyledComments = styled.div`
  margin-top: 35px;
  margin-left: 35px;

  .ant-list-header {
    border: none;

    .anticon {
      margin-left: -35px;
    }
  }
`;

export const StyledCommentItem = styled.li`
  > .ant-comment {
    margin: 15px 0;

    .ant-comment-inner {
      padding: 17px 15px;
      border-radius: 15px;
      box-shadow: 0 5px 10px 0 rgba(9, 9, 9, 0.06);
    }

    .ant-comment-content-detail {
      color: #8f8fa2;
      font-size: 13px;
    }

    .ant-comment-content-author {
      justify-content: space-between;
    }

    .ant-comment-content-author-name {
    }

    .ant-comment-content-author-time {
      color: #696974;
    }
  }
`;
