import React, { useMemo, useState } from 'react';
import constantes from 'utils/constantes';
import Etapa from '../TemplateEtapa';
import SectionDescricao from '../Sections/SectionDescricao';
import SectionResultado from '../Sections/SectionResultado';
import ButtonProsseguir from './ButtonProsseguir';

// TODO melhorar a ideia do footer para nao renderizar tudo
export default function EtapaTesteCovid({ etapa }) {
  const [resultado, setResultado] = useState(etapa?.propriedades?.resultado);

  const sectionDescricao = useMemo(() => {
    return <SectionDescricao etapa={etapa} />;
  }, [etapa]);

  const sectionResultado = useMemo(() => {
    return (
      <SectionResultado
        value={resultado}
        onChange={setResultado}
        disabled={!!etapa?.propriedades?.resultado}
      />
    );
  }, [etapa, resultado]);

  const footer = useMemo(() => {
    if (etapa.status !== constantes.ETAPA.STATUS.EXECUTADO) {
      return <ButtonProsseguir resultado={resultado} etapa={etapa} />;
    }
    return null;
  }, [etapa, resultado]);

  return (
    <Etapa
      etapa={etapa}
      sections={[sectionDescricao, sectionResultado]}
      footer={[footer]}
    />
  );
}
