import styled from 'styled-components';
import { Tabs } from 'antd';

export const MainTabs = styled(Tabs)`
  &.ant-tabs.ant-tabs-card {
    .ant-tabs-bar {
      margin: 0;
      border-bottom: none;

      .ant-tabs-nav-container {
        height: 50px;
      }

      .ant-tabs-tab {
        border: none;
        padding: 0 25px;
        height: 50px;
        line-height: 48px;
      }

      .ant-tabs-ink-bar {
        visibility: unset;
        height: 4px;
        width: 60px !important;
        left: 13px;
        border-radius: 5px 5px 0 0;
      }
    }
    .ant-tabs-top-content {
      background-color: #fff;
      border-radius: 0 20px 20px 20px;
    }

    .tratamento-atual-title {
      display: flex;
      align-items: center;
      margin-bottom: 25px;
    }
  }
`;
