import { Col, Icon, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import {
  RadioGroupEncaminhadoUTI,
  RadioGroupNecessarioRespirador,
  RadioGroupQuadroClinico,
} from '../RadioGroup';
import { SectionContent } from '../SectionContent';

export default function SectionQuadroClinico({ etapa = {}, onChange }) {
  const { propriedades } = etapa;
  const [quadroClinico, setQuadroClinico] = useState(
    propriedades?.quadroClinico
  );
  const [respirador, setRespirador] = useState(propriedades?.respirador);
  const [uti, setUti] = useState(propriedades?.uti);

  useEffect(() => {
    if (onChange) {
      onChange({
        respirador,
        quadroClinico,
        uti,
      });
    }
  }, [onChange, quadroClinico, respirador, uti]);

  const estadoQuadroClinico = useMemo(() => {
    return (
      <Col span={24}>
        <span>Quadro Clínico</span>
        <RadioGroupQuadroClinico
          value={quadroClinico}
          onChange={setQuadroClinico}
          disabled={!!propriedades?.quadroClinico}
        />
      </Col>
    );
  }, [propriedades, quadroClinico]);

  const necessarioRespirador = useMemo(() => {
    return (
      <Col span={12}>
        <span>Necessário uso de respirador?</span>
        <RadioGroupNecessarioRespirador
          value={respirador}
          onChange={setRespirador}
          disabled={!!propriedades?.respirador}
        />
      </Col>
    );
  }, [propriedades, respirador]);

  const necessarioUTI = useMemo(() => {
    return (
      <Col span={12}>
        <span>Paciente encaminhado para UTI?</span>
        <RadioGroupEncaminhadoUTI
          value={uti}
          onChange={setUti}
          disabled={!!propriedades?.uti}
        />
      </Col>
    );
  }, [propriedades, uti]);

  return (
    <SectionContent
      title="Detalhes da Internação"
      icon={<Icon type="snippets" />}>
      <Row gutter={[16, 16]}>
        {estadoQuadroClinico}
        {necessarioRespirador}
        {necessarioUTI}
      </Row>
    </SectionContent>
  );
}
