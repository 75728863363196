import styled from 'styled-components';

export const ContentTratamentos = styled.div`
  .ant-collapse-header,
  .title-grey-upper {
    color: #44444f;
    font-size: 11px;
    font-weight: 600;
    line-height: 17px;
    text-transform: uppercase;
  }

  .tratamento-steps {
    display: flex;

    > div:first-child {
      flex: 0;
    }
    > div:last-child {
      flex: 1;
    }
  }

  .tratamento-tipo-header {
    display: flex;
    justify-content: space-between;

    .tratamento-actions {
      .ant-btn {
        margin-right: 20px;
      }
      .ant-btn:last-child {
        margin-right: 0;
      }
    }
  }
  .ant-collapse {
    .current {
      padding-bottom: 20px;
      border-bottom: 1px solid #b1b1b7;
    }
  }
`;
