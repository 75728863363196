import React from 'react';
import { Dropdown, Icon } from 'antd';
import styled from 'styled-components';

const StyledDropdownSorter = styled(Dropdown)`
  display: inline-block;
  font-size: 13px;
  color: #44444f;
  cursor: pointer;

  text-align: right;

  > strong {
    font-size: 11px;
    text-transform: uppercase;
  }

  > span {
    display: inline-block;
    width: 167px;
    font-size: 14px;

    .anticon {
      font-size: 20px;
      margin-right: 8px;
      vertical-align: -0.235em;
    }
  }

  .bts-group-sorters {
    display: inline-block;

    .ant-btn {
      margin: 0 5px;

      &:first-child {
        margin-left: 15px;
      }
    }
  }
`;

export default function DropdownSorter({
  label = 'Ordenar Por',
  currentItem = '',
  options,
  placement = 'bottomRight',
}) {
  return (
    <StyledDropdownSorter
      overlay={options}
      placement={placement}
      trigger={['click']}>
      <div>
        <strong>{label}: </strong>
        <span>
          <Icon type="menu" />
          {currentItem}
        </span>
      </div>
    </StyledDropdownSorter>
  );
}
