import React from 'react';
import { RadioGroup } from 'components/RadioGroup';

const opts = [
  { label: 'Sim, o resultado deu positivo', value: 1 },
  { label: 'Sim, o resultado deu negativo', value: 2 },
  { label: 'Não, nenhum teste foi efetuado', value: 3 },
];

export default function RadioGroupEfetuadoNovoTeste({ ...props }) {
  return (
    <RadioGroup
      options={opts.filter(o => o.value !== props.removeOpt)}
      {...props}
    />
  );
}
