import styled from 'styled-components';
import { Card } from 'antd';

export default styled(Card)`
  &.ant-card-hoverable {
    border-radius: 15px;
  }

  .ant-card-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 3px 3px 10px 0 rgba(0, 98, 255, 0.09);
    padding: 12px 15px;
    border-radius: 15px;
    margin-bottom: 15px;

    .ant-card-meta {
      flex: 2;
      margin: unset;

      .ant-card-meta-detail {
        .ant-card-meta-title {
          font-size: 13px;
          color: #8f8fa2;
          font-weight: 400;
          margin-bottom: 4px;
        }

        .ant-card-meta-description {
          color: #44444f;
          font-size: 12px;
          font-weight: 600;
        }
      }
    }

    .extra-content {
      text-align: right;

      > span {
        font-size: 11px;
        color: #696974;
        text-align: right;
      }
    }
  }
`;
