import styled from 'styled-components';
import { Input } from 'antd';

export const BorderedBox = styled.div`
  display: flex;
  height: calc(100vh - 72px);
  margin: -15px -40px 0;
  background: #ffffff;
  overflow: hidden;
`;

export const Column = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  width: ${({ width }) => `${width}px` || 'auto'};
  flex: ${({ flex, width }) => (!width ? flex || 1 : 'unset')};
  flex-direction: column;
  z-index: ${({ sider }) => (sider ? 2 : 0)};
  box-shadow: ${({ sider }) =>
    sider ? '30px 20px 60px 0 rgba(0, 0, 0, 0.1)' : 'unset'};
`;

export const Row = styled.div`
  display: ${({ type = 'block' }) => type};
  position: relative;
  flex: ${({ flex = 1 }) => flex};
  overflow: hidden;
  overflow-y: auto;
`;

export const StyledHeaderSider = styled.div`
  padding: 20px 28px 10px;
`;

export const RelativeContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

export const StyledGraphs = styled.div.attrs(({ visible = true }) => ({
  className: !visible ? 'hide' : 'teste',
}))`
  position: absolute;
  padding: 10px 20px 0;
  background-color: rgba(255, 255, 255, 0.65);
  backdrop-filter: blur(10px);
  transform: translateY(0);
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9;
  transition: transform 300ms ease-in-out;

  &.hide {
    transform: translateY(310px);
  }

  > .ant-btn-icon-only {
    position: absolute;
    top: 10px;
    right: 15px;
    z-index: 1;
  }
`;

export const SearchInput = styled(Input.Search)`
  &.ant-input-search {
    .ant-input {
      font-size: 13px;
    }
  }
`;

export const StyledListItem = styled.div`
  padding: 6px 28px;

  .ant-list-item-meta-title {
    display: flex;

    > span {
      font-weight: 300;
      font-size: 13px;
      color: #8f8fa2;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 160px;
    }

    > strong {
      font-size: 12px;
      color: #44444f;
      margin-left: 10px;
    }
  }

  .ant-list-item-meta-description {
    font-size: 12px;
    color: #44444f;
  }
`;
