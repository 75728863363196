/* eslint-disable consistent-return */
import React, { useEffect, useState, useRef } from 'react';
import {
  getEstadoBbox,
  listMesorregiaoGeojson,
  listMunicipiosGeojson,
} from 'services/estadosService';
import { getMunicipioBbox } from 'services/municipiosService';
import { getUnidadeBbox } from 'services/unidadeBasicaService';
import { getPacienteBbox } from 'services/pacienteService';
import Map, { marker } from 'components/Map';
import { useSelector, useDispatch } from 'react-redux';
import { setLayer } from 'store/ducks/map';
import { CONSTANTES } from './CONSTANTES';
import ClusterLayer from './ClusterLayer';
import HeatLayer from './HeatMap';
import ExtrusionLayer from './ExtrusionLayer';
import { Actions } from './Actions';

const cores = {
  total: ['#fff4eb', '#ff7d13'],
  confirmados: ['#fffefa', '#ffd00e'],
  recuperados: ['#eefbf6', '#3DD598'],
  obitos: ['#f5f5f5', '#858585'],
  suspeitos: ['#fef6f6', '#f77474'],
};

function pointAlert(map, size = 200) {
  const pulsingDot = {
    width: size,
    height: size,
    data: new Uint8Array(size * size * 4),

    // get rendering context for the map canvas when layer is added to the map
    onAdd() {
      const canvas = document.createElement('canvas');
      canvas.width = this.width;
      canvas.height = this.height;
      this.context = canvas.getContext('2d');
    },

    // called once before every frame where the icon will be used
    render() {
      const duration = 1000;
      // eslint-disable-next-line no-undef
      const t = (performance.now() % duration) / duration;

      const radius = (size / 2) * 0.3;
      const outerRadius = (size / 2) * 0.9 * t + radius;
      const context = this.context;

      // draw outer circle
      context.clearRect(0, 0, this.width, this.height);
      context.beginPath();
      context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2);
      context.fillStyle = `rgba(255, 200, 200,${1 - t})`;
      context.fill();

      // draw inner circle
      context.beginPath();
      context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
      context.fillStyle = 'rgba(255, 100, 100, 1)';
      context.strokeStyle = 'white';
      context.lineWidth = 2 + 4 * (1 - t);
      context.fill();
      context.stroke();

      // update this image's data with data from the canvas
      this.data = context.getImageData(0, 0, this.width, this.height).data;

      // continuously repaint the map, resulting in the smooth animation of the dot
      map.triggerRepaint();

      // return `true` to let the map know that the image was updated
      return true;
    },
  };
  return pulsingDot;
}

export default function Mapa() {
  const [map, setMap] = useState();
  const [delimitacao, setDelimitacao] = useState(CONSTANTES.DELITACAO.REGIONAL);
  const layer = useSelector(state => state.map.layer);

  const { idEstado, idMunicipio, idUnidade, idPaciente } = useSelector(
    state => state.localizar
  );
  const dispatch = useDispatch();
  const [municipios, setMunicipios] = useState();
  const estagio = useSelector(state => state.map.estagio);
  const showTimeline = useSelector(state => state.map.showTimeline);
  const showTimelineRef = useRef(showTimeline);

  /**
   * TODO 'ajustar, ainda nao consegui fazer funcionar direito'
   */
  // useEffect(() => {
  //   showTimelineRef.current = showTimeline;
  //   const bottom = showTimeline ? 340 : -340;
  //   if (map) {
  //     setTimeout(() => {
  //       const bounds = map.getBounds().toArray();

  //       map.fitBounds(bounds, {
  //         padding: { top: 0, bottom, left: 0, right: 0 },
  //       });
  //     }, 100);
  //   }
  // }, [map, showTimeline]);

  useEffect(() => {
    if (map) {
      listMesorregiaoGeojson(idEstado).then(response => {
        map.U.addGeoJSON('mesorregiao-source', response.data);
        map.U.addLine(
          'mesorregiao',
          'mesorregiao-source',
          {
            lineWidth: 2,
            lineColor: '#aaa',
            'line-blur': 1,
          },
          'path-pedestrian-label'
        );
      });

      listMunicipiosGeojson(idEstado).then(response => {
        map.U.addGeoJSON('municipio-source', response.data);

        map.U.addLine(
          'municipio-line',
          'municipio-source',
          {
            layout: {
              visibility: map.getPitch() >= 2 ? 'none' : 'visible',
            },
            'line-color': '#000',
            'line-blur': 1,
            'line-width': ['interpolate', ['linear'], ['zoom'], 8, 0.5, 22, 8],
          },
          'mesorregiao'
        );

        setMunicipios(response.data);
      });

      const pulsingDot = pointAlert(map, 120);
      map.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 });

      map.U.addSymbol('unidade-basica-symbol', 'unidade_basica-source', {
        'icon-image': 'pin-hospital-32',
        'icon-ignore-placement': true,
        'icon-offset': [0, -16],
        'icon-size': 0.8,
        filter: ['!', ['get', 'alerta']],
      });

      map.addLayer({
        id: 'unidade-basica-alert',
        type: 'symbol',
        source: 'unidade_basica-source',
        layout: {
          'icon-ignore-placement': true,
          'icon-image': 'pulsing-dot',
        },
        filter: ['get', 'alerta'],
      });

      // 'text-field': ['upcase', ['get', 'nome']],

      map.U.addSymbol('unidade-basica-text', 'unidade_basica-source', {
        'icon-image': [
          'case',
          ['get', 'alerta'],
          'pulsing-dot',
          'pin-hospital-32',
        ],
        'icon-offset': [0, -16],
        'text-field': [
          'format',
          ['upcase', ['get', 'nome']],
          { 'font-scale': 1 },
          '\n',
          {},
          [
            'concat',
            ['get', 'leitos_utilizados'],
            '/',
            ['get', 'leitos'],
            ' Leitos',
          ],
          {
            'font-scale': 0.9,
          },
        ],
        'text-font': ['DIN Pro Medium', 'Arial Unicode MS Regular'],
        'text-offset': [0, 0.5],
        'text-anchor': 'top',
        'text-size': 11,
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 0.5,
        'text-halo-blur': 0.5,
        'text-color': '#95415D',
        minzoom: 14,
      });
    }
  }, [idEstado, map]);

  useEffect(() => {
    if (municipios && map) {
      let estagio2 = estagio;

      if (estagio === 'mortes') {
        estagio2 = 'obitos';
      }

      const intervals = municipios.features.map(f => f.properties[estagio2]);
      const m = Math.max(...intervals);

      const fillStyle = [
        'interpolate',
        ['linear'],
        ['get', estagio2],
        0,
        '#fff',
        1,
        cores[estagio2][0],
        m,
        cores[estagio2][1],
      ];

      map.U.addFill(
        'municipio',
        'municipio-source',
        {
          layout: {
            visibility: map.getPitch() >= 2 ? 'none' : 'visible',
          },
          'fill-color': fillStyle,
          'fill-opacity': 0.4,
        },
        'mesorregiao'
      );

      map.U.addFillExtrusion('municipio-3d', 'municipio-source', {
        layout: {
          visibility: map.getPitch() >= 2 ? 'visible' : 'none',
        },
        'fill-extrusion-color': fillStyle,
        'fill-extrusion-height': ['*', ['get', estagio2], 500],
        'fill-extrusion-base': 0,
      });

      return () => {
        map.U.removeLayer(['municipio', 'municipio-3d']);
      };
    }
  }, [estagio, map, municipios]);

  useEffect(() => {
    function handlePitch() {
      const pitch = map.getPitch();
      if (pitch >= 2 && layer !== 'extrusion') {
        dispatch(setLayer('extrusion'));
      } else if (pitch < 2 && layer === 'extrusion') {
        dispatch(setLayer('cluster'));
      }
    }

    if (map) {
      map.on('pitch', handlePitch);

      return () => {
        map.off('pitch', handlePitch);
      };
    }
  }, [dispatch, layer, map]);

  useEffect(() => {
    function changeDelimitacao() {
      const currentZoom = map.getZoom();

      if (
        currentZoom >= CONSTANTES.MINZOOM &&
        delimitacao === CONSTANTES.DELITACAO.REGIONAL
      ) {
        setDelimitacao(CONSTANTES.DELITACAO.MUNICIPAL);
      } else if (
        currentZoom < CONSTANTES.MINZOOM &&
        delimitacao === CONSTANTES.DELITACAO.MUNICIPAL
      ) {
        setDelimitacao(CONSTANTES.DELITACAO.REGIONAL);
      }
    }

    if (map) {
      map.on('zoom', changeDelimitacao);

      return () => {
        map.off('zoom', changeDelimitacao);
      };
    }
  }, [delimitacao, map]);

  useEffect(() => {
    if (map) {
      const bottom = showTimelineRef.current ? 385 : 65;
      const padding = { top: 5, bottom, left: 5, right: 5 };
      marker.remove();
      if (idPaciente) {
        getPacienteBbox(idPaciente).then(resp => {
          marker.setLngLat([resp.data[0], resp.data[1]]).addTo(map);
          map.fitBounds(resp.data, {
            padding,
            maxZoom: 16,
          });
        });
      } else if (idUnidade) {
        getUnidadeBbox(idUnidade).then(resp => {
          map.fitBounds(resp.data, {
            padding,
            maxZoom: 16,
          });
        });
      } else if (idMunicipio) {
        getMunicipioBbox(idMunicipio).then(resp => {
          map.fitBounds(resp.data, {
            padding,
          });
        });
      } else {
        getEstadoBbox(idEstado).then(resp => {
          map.fitBounds(resp.data, {
            padding,
          });
        });
      }
    }
  }, [idEstado, idMunicipio, idPaciente, idUnidade, map]);

  useEffect(() => {
    return () => {
      if (map) {
        map.remove();
      }
    };
  }, [map]);

  useEffect(() => {
    if (!map) return;
    if (layer === 'extrusion') {
      map.setPitch(50);
    } else {
      map.setPitch(0);
    }
  }, [layer, map]);

  return (
    <>
      {
        {
          extrusion: <ExtrusionLayer map={map} />,
          heat: <HeatLayer map={map} />,
          cluster: <ClusterLayer map={map} delimitacao={delimitacao} />,
        }[layer]
      }
      <Actions />
      <Map
        extent={[
          -53.83635864440077,
          -29.35144104628034,
          -48.35878613955834,
          -25.955958884585375,
        ]}
        setMapRef={setMap}
      />
    </>
  );
}
