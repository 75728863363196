import CONSTANTES from 'utils/constantes';

export function rowNotificacao(status, perfil) {
  switch (status) {
    case CONSTANTES.ETAPA.STATUS.NAO_EXECUTADO:
      if (perfil === CONSTANTES.PERFIL.AGENTE) return 'row-warning';
      break;
    case CONSTANTES.ETAPA.STATUS.NAO_ENVIADA:
      if (perfil === CONSTANTES.PERFIL.AGENTE) return 'row-warning';
      break;
    case CONSTANTES.ETAPA.STATUS.ENVIADA: // NAO_ASSINADA
      if (perfil === CONSTANTES.PERFIL.AGENTE) return 'row-pending';
      if (perfil === CONSTANTES.PERFIL.GESTOR) return 'row-warning';
      break;
    default:
  }
  return '';
}

export function tagNotificacao(status, perfil) {
  switch (status) {
    case CONSTANTES.ETAPA.STATUS.NAO_EXECUTADO:
      if (perfil === CONSTANTES.PERFIL.AGENTE)
        return ['Não preenchida', 'pendente'];
      break;
    case CONSTANTES.ETAPA.STATUS.NAO_ENVIADA:
      if (perfil === CONSTANTES.PERFIL.AGENTE)
        return ['Nâo enviada', 'pendente'];
      break;
    case CONSTANTES.ETAPA.STATUS.ENVIADA: // NAO_ASSINADA
      if (perfil === CONSTANTES.PERFIL.AGENTE)
        return ['Aguardando retorno', 'aguardando'];
      if (perfil === CONSTANTES.PERFIL.GESTOR)
        return ['Não assinada', 'pendente'];
      break;
    case CONSTANTES.ETAPA.STATUS.ASSINADA:
      if (
        perfil === CONSTANTES.PERFIL.GESTOR ||
        perfil === CONSTANTES.PERFIL.AGENTE
      )
        return ['Assinada', 'assinada'];
      break;
    default:
  }
  return [];
}
